//
//  views/DetailProductView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

import { formatCurrency } from "../localisation/locale";
import { Navigation } from "../components/Navigation";
import { addToCart } from "../state/reducers/cartSlice";

import FsLightbox from "fslightbox-react";

import Announcement from "../components/Announcement";
// import Marquee from "../components/Marquee";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import ScrollToTop from "../components/ScrollToTop";
import SEO from "../components/SEO";

import { AsyncImage } from "loadable-image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDynamicData } from "../hooks/UseDynamicData";
import Exchange from "../components/Exchange";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

// TODO:
// - [x] use the consts for substitution
// - [ ] fetch database on landing
// - [ ] fix bug where dims are not null but also are... i dont get it.

// const DetailProductView = ({ isPreOrder, currentProduct, nameData, categoryData, thumbnailUrl, slugData, priceData, dispatch }) => {
const DetailProductView = () => {
  // localStorage.removeItem("dynamicData");

  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const {
    loading: dynamicDataLoading,
    error: dynamicDataError,
  } = useDynamicData();

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const Products = useSelector(
    (state) => state.dynamicData.data?.Products || []
  );

  const { id } = useParams(); // Get the product ID from the URL
  //  console.log(id);
  const currentProduct =
    Products.find((product) => product.slug?.data.toLowerCase() === id) ?? [];
  // const currentProduct =
  //   Products.find((product) => product.slug?.data.toLowerCase() === id) || null;

  const relatedProducts = Products.filter(
    (product) => product.category?.data === currentProduct.category?.data
  );

  const categoryToLineMap = {
    hooks: "Misnar",
    lights: "Batu-Suvaie",
    paintings: "Arali",
    furniture: "Batu-Suvaie",
  };

  // product consts
  const detailsData = currentProduct.details?.data ?? "N/A";
  const categoryData = currentProduct.category?.data ?? "N/A";
  const lineData =
    currentProduct.line?.data?.trim() !== ""
      ? currentProduct.line?.data?.trim()
      : categoryToLineMap[categoryData.trim().toLowerCase()];
  const description = currentProduct.description?.data ?? "N/A";
  const lengthData =
    currentProduct.length?.data?.trim() !== ""
      ? currentProduct.length?.data
      : "N/A";
  const widthData =
    currentProduct.width?.data?.trim() !== ""
      ? currentProduct.width?.data
      : "N/A";
  const heightData =
    currentProduct.height?.data?.trim() !== ""
      ? currentProduct.height?.data
      : "N/A";
  const nameData = currentProduct.name?.data ?? "N/A";
  const slugData = currentProduct.slug?.data?.toLowerCase() ?? "/404";
  const thumbnailUrl =
    currentProduct.thumbnail?.data ?? "/images/placeholder.jpg";
  const priceData = currentProduct.price?.data ?? 0;
  // const shippingPrice = currentProduct.shipping?.data ?? 0;
  const shippingPrice = Number.isNaN(Number(currentProduct.shipping?.data))
    ? 0
    : Number(currentProduct.shipping?.data);
  const weightData =
    currentProduct.weight?.data?.trim() !== ""
      ? currentProduct.weight?.data
      : "N/A";
  const packageWeightData = currentProduct.package_weight?.data || weightData;
  const sizeData =
    currentProduct.size?.data || `${lengthData} x ${heightData} x ${widthData}`;
  const localShippingTimeData = currentProduct.local_shipping_time?.data ?? "2"; // weeks
  const intlShippingTimeData = currentProduct.intl_shipping_time?.data ?? "4"; // weeks
  const inventoryData = currentProduct.inventory?.data ?? "0";

  // Initialize quantity state
  const [quantity, setQuantity] = useState(1);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    });
  }

  const dispatch = useDispatch();

  const [usdPrice, setUsdPrice] = useState(null);
  const [basketCounts, setBasketCounts] = useState({});

  useEffect(() => {
    // Fetch basket counts from backend
    fetch("https://us-central1-lsae-86346.cloudfunctions.net/cart/counts")
      .then((res) => res.json())
      .then((data) => setBasketCounts(data));

    // Check if dynamic data loading has completed
    if (!dynamicDataLoading) {
      const currentProduct = Products.find(
        (product) => product.slug?.data.toLowerCase() === id
      );

      // Check if currentProduct is not defined
      if (!currentProduct) {
        // If the product still doesn't exist, redirect to /404
        navigate("/404"); // Replace history.push with navigate
      }
    }
    // Retrieve USD price from session storage
    const storedUsdPrice = localStorage.getItem("usdPrice");
    console.log("Stored USD Price:", storedUsdPrice);
    console.log("shippingPrice:", shippingPrice);

    // Update state with the stored USD price
    setUsdPrice(storedUsdPrice ? JSON.parse(storedUsdPrice) : null);
    // setUsdPrice(storedUsdPrice ? <Exchange ask={1} from="TRY" to="USD"/> : null);

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    // loadScriptsSequentially(scripts)
    // .then(() => {
    //   console.log("All scripts loaded successfully");
    // })
    // .catch((error) => {
    //   console.error("Error loading scripts:", error);
    // });

    loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, [dynamicDataLoading, id, navigate, Products]);

  return (
    <span>
      <SEO
        title={nameData}
        description={description}
        name="L'SAE Studio"
        type="product"
        imageUrl={`/images/seo/${slugData}.jpg`}
      />

      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        {!dynamicDataLoading ? (
          <>
            {/* {console.log("Current Product:", currentProduct)} */}
            <div>
              <ScrollToTop />
              <Announcement />
              <Navigation />
              <div className="af-class-section">
                <div className="af-class-main-container">
                  <div className="w-layout-grid af-class-product-grid">
                    {/* <img alt="" src={thumbnailUrl.url} loading="lazy" className="af-class-main-product-image" /> */}
                    <div className="af-class-product-gallery">
                      <div style={{ paddingBottom: "24px" }}>
                        <AsyncImage
                          src={thumbnailUrl.url}
                          style={{
                            width: "100%",
                            height: "auto",
                            aspectRatio: 0.73 / 1,
                          }}
                          loader={<div style={{ background: "#EBEBEB" }} />}
                          error={<div style={{ background: "#eee" }} />}
                        />
                      </div>

                      <div>
                        {currentProduct.gallery.data.length === 1 ? (
                          <div style={{ paddingBottom: "24px" }}>
                            {currentProduct.gallery.data.map(
                              (imageUrl, index) => (
                                <AsyncImage
                                  key={index}
                                  src={imageUrl.url}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    aspectRatio: 0.73 / 1,
                                  }}
                                  loader={
                                    <div style={{ background: "#EBEBEB" }} />
                                  }
                                  error={<div style={{ background: "#eee" }} />}
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <div className="w-dyn-list w-dyn-items-repeater-ref">
                            <div
                              role="list"
                              className="af-class-more-images-grid w-dyn-items"
                            >
                              {currentProduct.gallery.data.map(
                                (imageUrl, index) => (
                                  <div
                                    key={index}
                                    role="listitem"
                                    className="w-dyn-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <a
                                      onClick={() => openLightboxOnSlide(index)}
                                      className="af-class-grid-project-lightbox w-inline-block w-lightbox"
                                    >
                                      <LazyLoadImage
                                        src={
                                          imageUrl.url ||
                                          "/images/placeholder.jpg"
                                        }
                                        effect="opacity"
                                        alt={`/Gallery Image ${index}`}
                                        className="af-class-product-thumbnail"
                                      />
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="af-class-product-gallery">
                        <div style={{ paddingBottom: "24px" }}>
                          {slugData ===
                            "tronie_of_a_man_with_a_feathered_beret" && (
                            <div style={{ paddingBottom: "24px" }}>
                              <video
                                width="100%"
                                maxWidth="600"
                                height="auto"
                                playsInline
                                autoPlay
                                muted
                                loop
                              >
                                <source
                                  src="/videos/tronie.mp4"
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag. :(
                              </video>
                            </div>
                          )}
                        </div>
                      </div>
                      <FsLightbox
                        toggler={lightboxController.toggler}
                        sources={currentProduct.gallery.data.map(
                          (item) => item.url
                        )}
                        slide={lightboxController.slide}
                      />
                    </div>

                    <div
                      id="w-node-c7e51d0f-2d88-d3ea-4d4e-74172b8dc048-734c78be"
                      className="af-class-vertical-border"
                    />
                    <div className="af-class-product-description">
                      <div className="af-class-breadcrumbs">
                        <a href="/shop">Shop</a>
                        <img
                          alt=""
                          src="/images/icon-caret-right.svg"
                          loading="lazy"
                          className="af-class-breadcrumb-arrow"
                        />
                        <div className="w-dyn-list">
                          <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                              <Link
                                onClick={handleScroll}
                                to={`/category/${currentProduct.category?.data.toLowerCase()}`}
                              >
                                {currentProduct.category?.data
                                  .charAt(0)
                                  .toUpperCase() +
                                  currentProduct.category?.data.slice(1)}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        href={`/category/${currentProduct.category?.data.toLowerCase()}`}
                        className="w-inline-block"
                      >
                        <h5
                          className="w-inline-block"
                          style={{
                            textTransform: "uppercase",
                            color: "#676767",
                          }}
                        >
                          {lineData}
                        </h5>
                      </a>
                      <h1 className="af-class-medium-heading">{nameData}</h1>
                      <div className="af-class-price-container af-class-large-text">
                        <div>
                          {currentProduct.oldPrice?.data ? (
                            <div className="af-class-old-price">
                              {" "}
                              {formatCurrency(currentProduct.oldPrice.data)}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        <div>
                          {formatCurrency(priceData, "en")}
                          {/* { ` / ${formatCurrency(usdPrice * priceData, "tr")}`} */}
                          {usdPrice == null
                            ? null
                            : ` / ${formatCurrency(
                                Math.round((usdPrice * priceData) / 100) * 100,
                                "tr"
                              )}`}
                        </div>
                      </div>
                      <div className="w-layout-grid af-class-product-details-grid">
                        <div>
                          <h6 className="af-class-uppercase-text af-class-product-details-heading">
                            Description
                          </h6>
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </div>
                        <div>
                          <h6 className="af-class-uppercase-text af-class-product-details-heading">
                            Details
                          </h6>
                          <div
                            className="af-class-product-details-list w-richtext"
                            style={{ textTransform: "capitalize" }}
                          >
                            <ul>
                              {detailsData.split(",").map((item, index) => (
                                <li key={index}>
                                  {item
                                    .trim()
                                    .replace(/(?:^|\.\s+|,\s+)(.)/g, (match) =>
                                      match.toUpperCase()
                                    )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div>
                          <h6 className="af-class-uppercase-text af-class-product-details-heading">
                            Information
                          </h6>
                          {/* <div className="af-class-product-dimensions" style={{ gridTemplateColumns: currentProduct.weight?.data ? "1fr 1fr 1fr 1fr " : "" }}> */}
                          <div className="af-class-product-dimensions">
                            <div>
                              <div className="af-class-dimensions-heading">
                                Length (cm)
                              </div>
                              <div>{lengthData}</div>
                            </div>
                            <div>
                              <div className="af-class-dimensions-heading">
                                Width (cm)
                              </div>
                              <div>{widthData}</div>
                            </div>
                            <div>
                              <div className="af-class-dimensions-heading">
                                Height (cm)
                              </div>
                              <div>{heightData}</div>
                            </div>
                            {/* {currentProduct.weight?.data ? <div>
                              <div className="af-class-dimensions-heading">
                                Weight (gr)
                              </div>
                              <div>{currentProduct.weight?.data}</div>
                            </div> : <div style={{ display: "none" }} />} */}
                          </div>
                        </div>
                        <div>
                          <h6 className="af-class-uppercase-text af-class-product-details-heading">
                            Shipping information
                          </h6>
                          <div>
                            Expect expedited shipping within 2 weeks
                            domestically and 2-3 weeks for international
                            destinations. Questions?{" "}
                            <a href="/contact">Contact</a> our support team for
                            assistance. Your satisfaction is our priority.
                          </div>
                        </div>
                        <div>
                          {inventoryData === "0" ? (
                            <div
                              className="w-commerce-commerceaddtocartoutofstock af-class-empty-state"
                              tabIndex={0}
                            >
                              <div>This piece is sold.</div>
                            </div>
                          ) : (
                            <div>
                              {/* Conditionally render the pre-order a */}
                              {currentProduct.isPreOrder?.data &&
                              currentProduct.isPreOrder === "true" ? (
                                <div>
                                  {/* Conditionally render the quantity selector */}
                                  {parseInt(inventoryData) > 1 && (
                                    <div className="quantity-wrapper">
                                      <label
                                        htmlFor="quantity-6194caf15498d8612861f033e8278855"
                                        className="quantity-label"
                                      >
                                        Quantity:
                                      </label>
                                      <input
                                        type="number"
                                        pattern="^[0-9]+$"
                                        inputMode="numeric"
                                        name="commerce-add-to-cart-quantity-input"
                                        min="1"
                                        max={inventoryData}
                                        onChange={(e) =>
                                          setQuantity(parseInt(e.target.value))
                                        }
                                        className="w-commerce-commerceaddtocartquantityinput quantity-input"
                                        defaultValue="1"
                                      />
                                    </div>
                                  )}
                                  <form
                                    data-node-type="commerce-add-to-cart-form"
                                    className="w-commerce-commerceaddtocartform af-class-cart-default-state"
                                  >
                                    {/* Existing code for Add to Basket button */}
                                    <a
                                      onClick={() =>
                                        dispatch(
                                          addToCart({
                                            id: currentProduct.id,
                                            title: nameData,
                                            category: categoryData,
                                            img: thumbnailUrl.url,
                                            slug: slugData.toLowerCase(),
                                            price: priceData,
                                            quantity: quantity,
                                            shipping: Number.isNaN(
                                              Number(
                                                currentProduct.shipping?.data
                                              )
                                            )
                                              ? 0
                                              : Number(
                                                  currentProduct.shipping?.data
                                                ),
                                          })
                                        )
                                      }
                                      data-node-type="commerce-buy-now-button"
                                      data-default-text="Add to Basket"
                                      data-subscription-text="Subscribe now"
                                      aria-busy="false"
                                      aria-haspopup="false"
                                      className="w-commerce-commercebuynowbutton af-class-button af-class-no-top-margin af-class-bg-primary"
                                    >
                                      Pre-Order
                                    </a>
                                  </form>
                                </div>
                              ) : (
                                <div>
                                  {/* Conditionally render the quantity selector */}
                                  {parseInt(inventoryData) > 1 && (
                                    <div className="quantity-wrapper">
                                      <label
                                        htmlFor="quantity-6194caf15498d8612861f033e8278855"
                                        className="quantity-label"
                                      >
                                        Quantity:
                                      </label>
                                      <input
                                        type="number"
                                        pattern="^[0-9]+$"
                                        inputMode="numeric"
                                        name="commerce-add-to-cart-quantity-input"
                                        min="1"
                                        onChange={(e) =>
                                          setQuantity(parseInt(e.target.value))
                                        }
                                        max={inventoryData}
                                        className="w-commerce-commerceaddtocartquantityinput quantity-input"
                                        defaultValue="1"
                                      />
                                    </div>
                                  )}
                                  <form
                                    data-node-type="commerce-add-to-cart-form"
                                    className="w-commerce-commerceaddtocartform af-class-cart-default-state"
                                  >
                                    {/* Existing code for Add to Basket button */}
                                    <a
                                      onClick={() =>
                                        dispatch(
                                          addToCart({
                                            id: currentProduct.id,
                                            title: nameData,
                                            category: categoryData,
                                            img: thumbnailUrl.url,
                                            slug: slugData.toLowerCase(),
                                            price: priceData,
                                            quantity: quantity,
                                            shipping: shippingPrice,
                                          })
                                        )
                                      }
                                      data-node-type="commerce-buy-now-button"
                                      data-default-text="Add to Basket"
                                      data-subscription-text="Subscribe now"
                                      aria-busy="false"
                                      aria-haspopup="false"
                                      className="w-commerce-commercebuynowbutton af-class-button af-class-no-top-margin af-class-bg-primary"
                                    >
                                      Add to Basket
                                    </a>
                                  </form>
                                </div>
                              )}
                            </div>
                            // <form
                            //   data-node-type="commerce-add-to-cart-form"
                            //   className="w-commerce-commerceaddtocartform af-class-cart-default-state"
                            // >
                            //   <a
                            //     onClick={() =>
                            //       dispatch(
                            //         addToCart({
                            //           id: currentProduct.id,
                            //           title: nameData,
                            //           category: categoryData,
                            //           img: thumbnailUrl.url,
                            //           slug: slugData.toLowerCase(),
                            //           price: priceData,
                            //           quantity: 1,
                            //         })
                            //       )
                            //     }
                            //     data-node-type="commerce-buy-now-button"
                            //     data-default-text="Add to Basket"
                            //     data-subscription-text="Subscribe now"
                            //     aria-busy="false"
                            //     aria-haspopup="false"
                            //     className="w-commerce-commercebuynowbutton af-class-button af-class-no-top-margin af-class-bg-primary"
                            //   >
                            //     Add to Basket
                            //   </a>
                            // </form>
                          )}

                          <div
                            aria-live
                            data-node-type="commerce-add-to-cart-error"
                            style={{ display: "none" }}
                            className="w-commerce-commerceaddtocarterror af-class-form-error"
                          >
                            <div
                              data-node-type="commerce-add-to-cart-error"
                              data-w-add-to-cart-quantity-error="Product is not available in this quantity."
                              data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart."
                              data-w-add-to-cart-mixed-cart-error="You can't purchase another product with a subscription."
                              data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item."
                              data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site."
                              data-w-add-to-cart-select-all-options-error="Please select an option in each set."
                            >
                              Product is not available in this quantity.
                            </div>
                          </div>
                        </div>
                        <div className="af-class-fine-print-text af-class-muted">
                          <p>
                            Copyright Notice: All works utilised in this product
                            have been licensed for commercial use. Unauthorized
                            reproduction or distribution is strictly prohibited
                            and may result in legal action. © L'SAE STUDIO 2023.
                          </p>
                          {/* <a href="/policies">Long Distance Agreement</a>
                          <br />
                          <a href="/policies">Refund Policy</a> */}
                          <a href="/policies">Policies & Agreements</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Marquee /> */}
              {/* <div className="af-class-section af-class-bg-primary-dark af-class-about-brand">
                <div className="af-class-main-container">
                  <div className="w-layout-grid af-class-grid-halves">
                    <div className="af-class-container">
                      <h6 className="af-class-uppercase-text af-class-product-details-heading">
                        about the brand
                      </h6>
                      <h3 className="af-class-medium-heading" />
                      <div className="af-class-large-text" />
                      <div className="af-class-button-row">
                        <a
                          href="#"
                          className="af-class-button af-class-bg-white-faded w-inline-block"
                        >
                          <div>View Range</div>
                        </a>
                      </div>
                    </div>
                    <div className="af-class-container">
                      <img alt="" src="" loading="lazy" />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="af-class-section af-class-bg-primary-light af-class-more-from-brand">
                <div className="af-class-main-container">
                  <div className="af-class-section-title af-class-more-from-brand-title">
                    <h3 className="af-class-no-bottom-margin">
                      More from&nbsp;
                    </h3>
                    <h3 className="af-class-no-bottom-margin" />
                  </div>
                  <div className="w-dyn-list">
                    <div
                      role="list"
                      className="af-class-product-quarters-grid w-dyn-items"
                    >
                      <div role="listitem" className="w-dyn-item">
                        <div>
                          <a
                            href="#"
                            className="af-class-product-preview-link w-inline-block"
                          >
                            <img alt=""
                              src=""
                              loading="lazy"
                              className="af-class-product-thumbnail"
                            />
                            <div className="af-class-uppercase-text af-class-brand" />
                            <h5 className="af-class-no-bottom-margin" />
                            <div className="af-class-price-container">
                              <div className="af-class-old-price" />
                              <div />
                              <div />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-empty-state af-class-bg-primary w-dyn-empty">
                      <div>There are no more products from this brand yet.</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="af-class-section">
                <div className="af-class-main-container">
                  <div className="af-class-section-title af-class-more-from-brand-title">
                    <h3 className="af-class-no-bottom-margin">
                      More from&nbsp;
                      {currentProduct.category?.data.charAt(0).toUpperCase() +
                        currentProduct.category?.data.slice(1)}
                      {/* More from&nbsp;{currentProduct.category?.data.replace(/^\w/, (c) => c.toUpperCase())} */}
                    </h3>
                    <div className="w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        <div role="listitem" className="w-dyn-item">
                          <h3 className="af-class-no-bottom-margin" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-dyn-list">
                    <div
                      role="list"
                      className="af-class-product-quarters-grid w-dyn-items"
                    >
                      {relatedProducts &&
                        relatedProducts?.map((data, idx) => (
                          <div key={idx} role="listitem" className="w-dyn-item">
                            <div>
                              <Link
                                onClick={handleScroll}
                                to={`/product/${data.slug?.data.toLowerCase()}`}
                                className="af-class-product-preview-link w-inline-block"
                              >
                                <AsyncImage
                                  src={data.thumbnail?.data.url}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    aspectRatio: 0.73 / 1,
                                  }}
                                  loader={
                                    <div style={{ background: "#EBEBEB" }} />
                                  }
                                  error={<div style={{ background: "#eee" }} />}
                                  className="af-class-product-thumbnail"
                                />
                                <div className="af-class-uppercase-text af-class-brand">
                                  {lineData}
                                </div>
                                <h5 className="af-class-no-bottom-margin">
                                  {data.name?.data}
                                </h5>
                                <div className="af-class-price-container">
                                  <div>
                                    {basketCounts[data.id] > 1 && (
                                      <div
                                        className="basket-count"
                                        style={{ color: "#d00739" }}
                                      >
                                        <span className="breathing-dot"></span>
                                        {basketCounts[data.id] > 5
                                          ? "5+ people"
                                          : basketCounts[data.id] +
                                            " people"}{" "}
                                        have this in their cart
                                      </div>
                                    )}
                                    {data.inventory?.data <= 9 &&
                                      data.inventory?.data >= 2 && (
                                        <div className="basket-count">
                                          {/* <span className="breathing-dot"></span> */}
                                          Only {data.inventory?.data} left —
                                          order soon
                                        </div>
                                      )}
                                  </div>
                                  <div>
                                    {formatCurrency(data.price?.data)}
                                    &nbsp;&nbsp;
                                  </div>
                                  {data.oldPrice?.data ? (
                                    <div className="af-class-old-price">
                                      {" "}
                                      {formatCurrency(data.oldPrice.data)}
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section-followus af-class-bg-primary-dark">
                <div className="af-class-main-container">
                  <div className="w-layout-grid af-class-grid-halves">
                    <div className="w-layout-grid af-class-social-images">
                      {/* <img alt="" src="/images/social-06.jpg" loading="lazy" />
                      <img alt="" src="/images/social-01.jpg" loading="lazy" />
                      <img alt="" src="/images/social-03.jpg" loading="lazy" /> */}
                    </div>
                    <div className="af-class-text-center">
                      <h4 className="af-class-medium-heading">
                        Follow us for inspiration
                      </h4>
                      <div className="af-class-button-row af-class-justify-content-center">
                        <a
                          href="#"
                          target="_blank"
                          rel="noreferrer"
                          className="af-class-button af-class-bg-white-faded w-inline-block"
                        >
                          <div>Follow Now</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </span>
    </span>
  );
};

export default DetailProductView;
