//
//  components/Loading.js
//  LSAE
//
//  Created by Marcel on 2023-09-14.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React from "react";

// https://uiball.com/ldrs/
import { grid, dotWave, bouncy, leapfrog } from "ldrs";

// dotWave.register();
// grid.register();
// bouncy.register();
leapfrog.register();

const Loading = () => {
  return (
    <span className="af-view">
      <div>
        <div className="af-class-utility-page-wrap">
          <div
            className="af-class-utility-page-content w-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <l-leapfrog size="40" speed="2.5" color="#333333"></l-leapfrog>
            {/* <l-grid size="60" speed="1.5" color="#333333"></l-grid> */}
            {/* <l-bouncy size="45" speed="1.75" color="#333333"></l-bouncy> */}
            {/* <l-dot-wave size="47" speed="1" color="#333333"></l-dot-wave> */}

            {/* <h2>Loading...</h2> */}
            {/* <div>Please wait</div> */}
          </div>
        </div>
      </div>
    </span>
  );
};
export default Loading;
