//
//  views/AboutView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect } from "react";

import { Navigation } from "../components/Navigation";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import { AsyncImage } from "loadable-image";

// TODO:
// - [ ] picture; us with animal heads
// - [ ] text; replace.

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const AboutView = () => {
  useEffect(() => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []); // Empty dependency array to mimic componentDidMount

  return (
    <span>
      <SEO
        title="L'SAE Studio | About"
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="about"
        imageUrl="https://lsaestudio.com/images/L_SAE-Logo-033x-100-p-1600.jpg"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <Announcement />
          <Navigation />
          <div className="af-class-section">
            <div className="af-class-main-container">
              <div className="w-layout-grid af-class-grid-halves">
                <AsyncImage
                  src="/images/L_SAE-Logo-033x-100-p-1600.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: 1 / 1,
                  }}
                  loader={<div style={{ background: "#EBEBEB" }} />}
                  error={<div style={{ background: "#eee" }} />}
                />
                <div className="af-class-container af-class-align-center">
                  <div>
                    A DESIGN STUDIO WHERE THOSE WHO VIEW THEIR HOME AS A
                    LIFESTYLE STATEMENT CAN DISCOVER VALUABLE CREATIONS.
                    <br />
                    <br />
                    We invite individuals with refined tastes to explore the
                    essence of art in home decor and transform the ambiance of
                    their living spaces.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default AboutView;
