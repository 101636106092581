//
//  views/ContactView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useRef } from "react";
import { Navigation } from "../components/Navigation";

import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../components/SEO";
import { AsyncImage } from "loadable-image";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const ContactView = () => {
  // Encode email and phone number
  const encodedEmail = btoa("info@lsaestudio.com");
  const encodedPhone = btoa("+90 (212) 871 7516");

  const subscribtionError = "";

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      message: e.target.message.value,
    };

    // TODO
    fetch("https://us-central1-lsae-86346.cloudfunctions.net/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage();
        } else {
          showErrorMessage();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorMessage();
      });
  };

  const showSuccessMessage = () => {
    const successMessage = document.querySelector(".af-class-form-success");
    const form = document.querySelector(".af-class-form-grid");
    successMessage.style.display = "block";
    form.style.display = "none";

    const errorMessage = document.querySelector(".af-class-form-error");
    errorMessage.style.display = "none";
  };

  const showErrorMessage = () => {
    const errorMessage = document.querySelector(".af-class-form-error");
    const subscribtionError =
      "Oops! Something went wrong while submitting the form.";
    errorMessage.style.display = "block";
  };

  useEffect(() => {
    // Decode email and phone numbers when component is loaded
    const emailLink = document.querySelector(
      ".af-class-contact-links a:first-child"
    );
    const phoneLink = document.querySelector(
      ".af-class-contact-links a:last-child"
    );

    if (emailLink && phoneLink) {
      emailLink.textContent = atob(encodedEmail); // Decode email from base64
      emailLink.href = "mailto:" + atob(encodedEmail);

      phoneLink.textContent = atob(encodedPhone); // Decode phone number from base64
      phoneLink.href = "tel:" + atob(encodedPhone);
    }

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []); // Empty dependency array to mimic componentDidMount

  return (
    <span>
      <SEO
        title="L'SAE Studio | Contact"
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="contact"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <Announcement />
          <Navigation />
          <div className="af-class-section">
            <div className="af-class-main-container">
              <div className="w-layout-grid af-class-grid-halves">
                <div className="af-class-container">
                  <AsyncImage
                    src="/images/1111.jpeg"
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: 0.73 / 1,
                    }}
                    loader={<div style={{ background: "#EBEBEB" }} />}
                    error={<div style={{ background: "#eee" }} />}
                    className="af-class-container"
                  />
                </div>
                <div className="af-class-container">
                  <div className="af-class-section-title">
                    <h1>
                      Visit the
                      <br />
                      showroom
                    </h1>
                    <div className="af-class-large-text">
                      Open Tuesday – Sunday 11am - 7pm
                    </div>
                  </div>
                  <div>
                    <div className="af-class-small-text">
                      Cihangir, Pürtelaş Sk. 3/A, <br />
                      34433 Beyoğlu/İstanbul
                    </div>
                    <a
                      href="https://goo.gl/maps/PqyejwrgqrYfVL3e8"
                      target="_blank"
                      className="af-class-small-text"
                    >
                      Get Directions
                    </a>
                  </div>
                  <div className="af-class-contact-links">
                    <a href="#" className="af-class-small-text">
                      {/* Placeholder content, replace with decoded email */}
                    </a>
                    <a href="#" className="af-class-small-text">
                      {/* Placeholder content, replace with decoded phone number */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-section">
            <div className="af-class-main-container">
              <div className="w-layout-grid af-class-grid-halves">
                <div className="af-class-container">
                  <div className="af-class-section-title">
                    <h3>Drop us a line</h3>
                    <div>
                      Fill out the form below and we'll be in touch ASAP.
                    </div>
                  </div>
                  <div className="af-class-form-block w-form">
                    <form
                      onSubmit={handleSubmit}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="POST"
                      className="af-class-form-grid"
                    >
                      <input
                        type="text"
                        className="af-class-form-input w-input"
                        maxLength={256}
                        name="name"
                        data-name="Name"
                        placeholder="Your Name*"
                        id="name"
                        required
                      />
                      <input
                        type="text"
                        className="af-class-form-input w-input"
                        maxLength={256}
                        name="Email"
                        data-name="Email"
                        placeholder="Email Address*"
                        id="email"
                        required
                      />
                      <input
                        type="tel"
                        className="af-class-form-input w-input"
                        maxLength={256}
                        name="Phone"
                        data-name="Phone"
                        placeholder="Contact Number"
                        id="phone"
                      />
                      <textarea
                        placeholder="Your Message*"
                        maxLength={5000}
                        id="message"
                        name="Message"
                        required
                        data-name="field"
                        className="af-class-form-input af-class-text-area w-input"
                        defaultValue={""}
                      />
                      {/* <label className="w-checkbox af-class-selectable-field">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-checkbox" />
                        <input
                          type="checkbox"
                          id="checkbox-2"
                          name="checkbox-2"
                          data-name="Checkbox 2"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            zIndex: -1,
                          }}
                        />
                        <span
                          className="af-class-selectable-label w-form-label"
                          htmlFor="checkbox-2"
                        >
                          I have read and agree to the{" "}
                          <a href="#">Terms &amp;&nbsp;Conditions</a>
                        </span>
                      </label> */}
                      <input
                        type="submit"
                        defaultValue="Send Message"
                        className="af-class-button af-class-bg-primary w-button"
                      />
                    </form>
                    <div className="af-class-form-success w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="af-class-form-error">
                      <div>{subscribtionError}</div>
                    </div>
                  </div>
                </div>
                <AsyncImage
                  src="/images/2314.jpeg"
                  style={{
                    width: "80%",
                    height: "auto",
                    aspectRatio: 0.73 / 1,
                  }}
                  loader={<div style={{ background: "#EBEBEB" }} />}
                  error={<div style={{ background: "#eee" }} />}
                  className="af-class-container"
                />
              </div>
            </div>
          </div>
          <div className="af-class-section-followus af-class-bg-primary-dark">
            <div className="af-class-main-container">
              <div className="w-layout-grid af-class-grid-halves">
                <div className="w-layout-grid af-class-social-images">
                  <img src="/images/social-06.jpg" loading="lazy" />
                  <img src="/images/social-01.jpg" loading="lazy" />
                  <img src="/images/social-03.jpg" loading="lazy" />
                </div>
                <div className="af-class-text-center">
                  <h4 className="af-class-medium-heading">
                    Follow us for inspiration
                  </h4>
                  <div className="af-class-button-row af-class-justify-content-center">
                    <a
                      href="#"
                      target="_blank"
                      className="af-class-button af-class-bg-white-faded w-inline-block"
                    >
                      <div>Follow Now</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default ContactView;

/* eslint-enable */
