//
//  state/reducers/dynamicDataSlice.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import { createSlice } from "@reduxjs/toolkit";

// const localData = JSON.parse(localStorage.getItem('dynamicData')) || {};

const dynamicDataSlice = createSlice({
  name: "dynamicData",
  initialState: {
    data: null,
  },
  reducers: {
    setDynamicData: (state, action) => {
      state.data = action.payload;
      console.log("Dynamic data set:", action.payload);
    },
  },
});


export const { setDynamicData } = dynamicDataSlice.actions;
export default dynamicDataSlice.reducer;
