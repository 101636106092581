//
//  components/SEO.js
//  LSAE
//
//  Created by Marcel on 2024-01-22.
//  Copyright © 2024 Marcel. All rights reserved.
//

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SEO({
  title,
  url,
  description,
  name,
  type,
  imageUrl,
  canonicalUrl,
}) {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          {/* Standard metadata tags */}
          <title>{title}</title>
          {description && <meta name="description" content={description} />}
          {/* End standard metadata tags */}

          {/* Facebook tags */}
          {type && <meta property="og:type" content={type} />}
          {title && <meta property="og:title" content={title} />}
          {description && (
            <meta property="og:description" content={description} />
          )}
          {url && <meta property="og:url" content={url} />}
          {imageUrl && <meta property="og:image" content={imageUrl} />}
          {imageUrl && <meta property="og:image:url" content={imageUrl} />}
          {imageUrl && (
            <meta property="og:image:secure_url" content={imageUrl} />
          )}
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:alt" content="thumbnail" />
          {/* End Facebook tags */}

          {/* Twitter tags */}
          {name && <meta name="twitter:creator" content={name} />}
          {type && <meta name="twitter:card" content={type} />}
          {title && <meta name="twitter:title" content={title} />}
          {description && (
            <meta name="twitter:description" content={description} />
          )}
          {imageUrl && <meta name="twitter:image" content={imageUrl} />}
          {/* End Twitter tags */}

          {/* Canonical URL */}
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        </Helmet>
      </HelmetProvider>
    </React.Fragment>
  );
}
