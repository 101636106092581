//
//  index.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import Router from "./views/Router";

import { Provider } from "react-redux";
import store, { persistor } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import { hydrate, render, hydrateRoot } from "react-dom";

// import LanguageWrapper from './components/LanguageWrapper';

// are these neccessary ??? probably? yes. yes they are
import "./scripts";
import "./styles";
import "./assets/css/webflow.css";
import "./assets/css/normalize.css";
import "./assets/css/lsae.webflow.css";

import Loading from "./components/Loading";
import Exchange from "./components/Exchange";
import { debug, verbosity } from "./global";

debug && console.log("Starting the application...");

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

function App() {
  console.log("Rendering the App component...");

  return (
    <>
      <Exchange />
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          {/* <LanguageWrapper> */}
          <Router />
          {/* </LanguageWrapper> */}
        </PersistGate>
      </Provider>
    </>
  );
}

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
  console.log("hydrating...");
} else {
  // render(<App />, rootElement);
  console.log("unable to hydrate. rendering...");
  root.render(<App />);
}

// Function to report performance metrics to Firebase
const reportToFirebase = ({ id, name, value }) => {
  // Report the performance metric to Firebase
  firebase.database().ref(`performance_metrics/${id}`).set({
    name,
    value,
  });
};

// Call reportWebVitals and pass the reporting function
// reportWebVitals(reportToFirebase);
reportWebVitals();
// console.log("reportWebVitals called.");
