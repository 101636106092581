//
//  components/Exchange.js
//  LSAE
//
//  Created by Marcel on 2024-01-18.
//  Copyright © 2024 Marcel. All rights reserved.
//

import { useEffect } from "react";

// TODO
// - [ ] if we fail to update after 24 hrs due to API restrictions we should
// still purge the storage

const Exchange = () => {
  useEffect(() => {
    console.log("Exchange component mounted");

    const apiKey = "d8deb5ccd237f050683c4d9c";
    const lastExchangeTimestamp = localStorage.getItem("lastExchangeTimestamp");
    const localExchangeData = localStorage.getItem("usdPrice");
    const currentTime = new Date().getTime();
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    // Check if 24 hours have passed since the last fetch
    if (
      !lastExchangeTimestamp ||
      currentTime - lastExchangeTimestamp > twentyFourHours
    ) {
      console.log("Fetching exchange rates...");

      // Fetch exchange rates
      // fetch(`https://v6.exchangerate-api.com/v6/${apiKey}/latest/USD`)
      fetch(`https://api.exchangerate-api.com/v4/latest/USD`)
        .then((response) => response.json())
        .then((data) => {
          console.log("Exchange rates fetched successfully:", data.rates);

          const exchangeRate = data.rates.TRY;

          // Update state with the exchange rate if needed
          // setUsdPrice(exchangeRate);

          // Store USD price in both local storage and local storage
          localStorage.setItem("usdPrice", JSON.stringify(exchangeRate));
          localStorage.setItem("usdPrice", JSON.stringify(exchangeRate));

          console.log("USD price stored in local storage:", exchangeRate);

          // Update the last fetch timestamp in localStorage
          localStorage.setItem("lastExchangeTimestamp", currentTime);
          console.log(
            "Last exchange timestamp updated in localStorage:",
            currentTime
          );
        })
        .catch((error) =>
          console.error("Error fetching exchange rates:", error)
        );
    } else if (!localExchangeData) {
      // If 24 hours have not passed, check local storage for data
      console.log("Fetching exchange rates from local storage...");

      // Fetch exchange rates from local storage
      const exchangeRate = JSON.parse(localExchangeData);

      // Update state with the exchange rate if needed
      // setUsdPrice(exchangeRate);

      console.log("USD price retrieved from local storage:", exchangeRate);
    } else {
      console.log(
        "No need to fetch exchange rates, 24 hours have not passed since the last fetch. Rate:",
        localExchangeData
      );
    }
  }, []); // Run this effect only once when the component mounts
};

export default Exchange;
