/* eslint-disable */

//
//  views/OrderConfirmationView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Announcement from "../components/Announcement";
import { Navigation } from "../components/Navigation";
// import Marquee from "../components/Marquee";
import Footer from "../components/Footer";
import { formatCurrency } from "../localisation/locale";
import axios from "axios";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { removeItem, resetCart } from "../state/reducers/cartSlice";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const retrievalPoint =
  "https://us-central1-lsae-86346.cloudfunctions.net/pay/retrieve";

const orderPoint = "https://us-central1-lsae-86346.cloudfunctions.net/order";

const OrderConfirmationView = () => {
  const [orderData, setOrderData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(""); // State variable for payment status
  const [token, setToken] = useState(""); // Assuming you have a way to get the token
  const dispatch = useDispatch();
  const Products = useSelector(
    (state) => state.dynamicData.data?.Products || []
  );
  const user = useSelector((state) => state.basket.user);
  const shippingAddress = useSelector((state) => state.basket.shippingAddress);
  const billingAddress = useSelector((state) => state.basket.billingAddress);
  const VAT = 0.2;
  const navigate = useNavigate();
  const [usdPrice, setUsdPrice] = useState(null);

  const findProductByName = (name) => {
    console.log("Searching for product with name:", name);
    const product =
      Products.find((product) => product.name.data === name) || [];
    if (!product) {
      console.log("Product not found for name:", name);
    }
    console.log("Product found:", product);
    return product;
  };

  useEffect(() => {
    const token =
      new URLSearchParams(window.location.search).get("token") ||
      localStorage.getItem("token");
    console.log(
      "Token found in query parameters:",
      new URLSearchParams(window.location.search).get("token")
    );
    console.log(
      "Token found in local parameters:",
      localStorage.getItem("token")
    );
    setToken(token);

    if (token && window.location.pathname === "/order-confirmation") {
      navigate(`/order-confirmation?token=${token}`);
      // localStorage.removeItem("token");
      // dispatch(resetCart());
    }
    if (!token && window.location.pathname !== "/order-confirmation") {
      navigate("/");
    }

    const handleWebhook = async () => {
      try {
        console.log("Checking payment status...");
        const response = await axios.post(retrievalPoint, {
          token: token,
        });

        console.log("Webhook Response:", response.data);

        if (response.data.status && response.data.status === "success") {
          console.log("Payment successful.");
          setPaymentStatus("SUCCESS");
          navigate(`/order-confirmation?token=${token}`);
          console.log("Resetting cart...");
          dispatch(resetCart());
          localStorage.removeItem("token");
        } else {
          console.log("Payment failed or other status.");
          setPaymentStatus("FAILED");
        }
      } catch (error) {
        console.error("Error handling webhook:", error);
      }
    };

    if (token) {
      handleWebhook();
    }

    const storedUsdPrice = localStorage.getItem("usdPrice");
    console.log("Stored USD Price:", storedUsdPrice);
    setUsdPrice(storedUsdPrice ? JSON.parse(storedUsdPrice) : null);

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    window.scrollTo(0, 0);

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });

    const fetchOrderData = async () => {
      const token =
        new URLSearchParams(window.location.search).get("token") ||
        localStorage.getItem("token");
      console.log("Token found in query parameters:", token);

      try {
        console.log("Sending request to fetch order data...");
        const response = await axios.post(orderPoint, { token });
        console.log("Received response:", response);
        setOrderData(response.data.data);
        console.log("Order data set:", orderData);
        localStorage.removeItem("token");
        dispatch(resetCart());
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.code === "ECONNABORTED") {
          console.log("Request timeout:", error.message);
        } else {
          console.error("Error fetching order data:", error);
        }
      }
    };

    if (token) {
      fetchOrderData();
    }
  }, [token]); // Empty dependency array to mimic componentDidMount

  useEffect(() => {
    if (paymentStatus === "SUCCESS") {
      console.log("Stopping further status checks due to success status.");
    }
    paymentStatus && localStorage.removeItem("token");
  }, [paymentStatus]);

  return (
    <span>
      {orderData ? (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);

          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }

          @media print {
            .print-visible {
              display: block !important;
            }
            body * {
              display: none !important;
            }
          }
        `,
            }}
          />
          <span className="af-view">
            <div>
              <Announcement />
              <Navigation />
              <div
                data-node-type="commerce-order-confirmation-wrapper"
                data-wf-order-query
                data-wf-page-link-href-prefix
                className="w-commerce-commerceorderconfirmationcontainer af-class-section af-class-order-confirmation-section print-visible"
              >
                <div className="w-commerce-commercelayoutcontainer af-class-order-confirmation-container w-container">
                  <div className="w-commerce-commercelayoutmain">
                    <div className="w-commerce-commercecheckoutcustomerinfosummarywrapper">
                      <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                        <h4 className="af-class-checkout-heading">
                          Customer Information
                        </h4>
                      </div>
                      <fieldset className="w-commerce-commercecheckoutblockcontent">
                        <div className="w-commerce-commercecheckoutrow">
                          <div className="w-commerce-commercecheckoutcolumn">
                            <div className="w-commerce-commercecheckoutsummaryitem">
                              <label className="w-commerce-commercecheckoutsummarylabel">
                                Email
                              </label>
                              <div>
                                {orderData.buyer.email ?? orderData.buyer.email}
                              </div>
                            </div>
                          </div>
                          {/* Shipping Address */}
                          <div className="w-commerce-commercecheckoutcolumn">
                            <div className="w-commerce-commercecheckoutsummaryitem">
                              <label className="w-commerce-commercecheckoutsummarylabel">
                                Shipping Address
                              </label>
                              <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                                <div className="w-commerce-commercecheckoutsummarytextspacingondiv">
                                  {orderData.shippingAddress.contactName && (
                                    <>
                                      {orderData.shippingAddress.contactName
                                        .charAt(0)
                                        .toUpperCase() +
                                        orderData.shippingAddress.contactName
                                          .slice(1)
                                          .toLowerCase()}
                                      ,
                                      <br />
                                    </>
                                  )}
                                  {orderData.shippingAddress.address && (
                                    <>
                                      {orderData.shippingAddress.address
                                        .charAt(0)
                                        .toUpperCase() +
                                        orderData.shippingAddress.address
                                          .slice(1)
                                          .toLowerCase()}
                                      ,
                                      <br />
                                    </>
                                  )}
                                  {orderData.shippingAddress.city && (
                                    <>
                                      {orderData.shippingAddress.city
                                        .charAt(0)
                                        .toUpperCase() +
                                        orderData.shippingAddress.city
                                          .slice(1)
                                          .toLowerCase()}
                                      ,
                                      <br />
                                    </>
                                  )}
                                  {orderData.shippingAddress.state && (
                                    <>
                                      {orderData.shippingAddress.state
                                        .charAt(0)
                                        .toUpperCase() +
                                        orderData.shippingAddress.state
                                          .slice(1)
                                          .toLowerCase()}
                                      ,
                                      <br />
                                    </>
                                  )}
                                  {orderData.shippingAddress.zipCode && (
                                    <>
                                      {orderData.shippingAddress.zipCode.toUpperCase()}
                                      ,
                                      <br />
                                    </>
                                  )}
                                  {orderData.shippingAddress.country && (
                                    <>
                                      {orderData.shippingAddress.country
                                        .charAt(0)
                                        .toUpperCase() +
                                        orderData.shippingAddress.country
                                          .slice(1)
                                          .toLowerCase()}
                                      <br />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="w-commerce-commercecheckoutorderitemswrapper">
                      <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                        <h4 className="af-class-checkout-heading">
                          Items in Order
                        </h4>
                      </div>
                      <fieldset className="w-commerce-commercecheckoutblockcontent">
                        <div
                          role="list"
                          className="w-commerce-commercecheckoutorderitemslist"
                          data-wf-collection="database.commerceOrder.userItems"
                          data-wf-template-id="wf-template-619d8e8b85c2248289c36b48000000000051"
                        >
                          {orderData.basketItems &&
                            orderData.basketItems?.map((data, idx) => (
                              <div
                                key={idx}
                                role="listitem"
                                class="w-commerce-commercecheckoutorderitem"
                              >
                                <img
                                  alt={`basket item ${idx}: ${data.name}, Qty: ${data.quantity}`}
                                  src={
                                    findProductByName(data.name).thumbnail.data
                                      .url
                                  }
                                  class="w-commerce-commercecartitemimage"
                                />
                                <div class="w-commerce-commercecheckoutorderitemdescriptionwrapper">
                                  <div class="w-commerce-commerceboldtextblock">
                                    {data.name}
                                  </div>
                                  {/* <div class="w-commerce-commercecheckoutorderitemquantitywrapper">
                                <div>Quantity: </div>
                                <div>{data.quantity}</div>
                              </div> */}
                                  <ul class="w-commerce-commercecheckoutorderitemoptionlist"></ul>
                                </div>
                                <div>
                                  {formatCurrency(data.price / usdPrice)}
                                </div>
                              </div>
                            ))}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="w-commerce-commercelayoutsidebar">
                    <div className="w-commerce-commercecheckoutordersummarywrapper">
                      <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                        <h4 className="af-class-checkout-heading">
                          Order Summary
                        </h4>
                      </div>
                      <fieldset className="w-commerce-commercecheckoutblockcontent">
                        <div className="w-commerce-commercecheckoutsummarylineitem">
                          <div>Subtotal</div>
                          <div>
                            {formatCurrency(
                              (orderData.price - VAT * orderData.price) /
                                usdPrice
                            )}
                          </div>
                        </div>
                        <div className="w-commerce-commercecheckoutsummarylineitem">
                          <div>VAT</div>
                          <div>
                            {formatCurrency((orderData.price * VAT) / usdPrice)}
                          </div>
                        </div>
                        <div className="w-commerce-commercecheckoutordersummaryextraitemslist">
                          <div className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                            <div />
                            <div />
                          </div>
                        </div>
                        <div className="w-commerce-commercecheckoutsummarylineitem">
                          <div>Total</div>
                          <div className="w-commerce-commercecheckoutsummarytotal">
                            {formatCurrency(orderData.price / usdPrice)}
                          </div>
                        </div>
                        {paymentStatus && (
                          <div className="w-commerce-commercecheckoutsummarylineitem">
                            <div>Payment Status</div>
                            <div
                              style={{
                                fontWeight: "bold",
                                color:
                                  paymentStatus === "FAILED" ? "red" : "green",
                              }}
                            >
                              {paymentStatus}
                            </div>
                          </div>
                        )}
                      </fieldset>
                      <fieldset className="w-commerce-commercecheckoutblockcontent" style={{color: 'grey', fontSize: '9pt'}}>
                        <div >
                          If you believe this is a mistake, please reload this
                          page again. It might take a few minutes to recieve a
                          confirmation.{" "}
                        </div>
                        <div>If the problem persists, please get in touch.</div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </span>
        </>
      ) : (
        <Loading />
      )}
    </span>
  );
};

export default OrderConfirmationView;

/* eslint-enable */
