//
//  styles/index.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

const styles = [
  {
    type: "href",
    body: "/css/normalize.css",
  },
  {
    type: "href",
    body: "/css/webflow.css",
  },
  {
    type: "href",
    body: "/css/lsae.webflow.css",
  },
  {
    type: "sheet",
    body:
      ".af-view{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased}.af-view input,.af-view select,.af-view textarea{-webkit-appearance:none}",
  },
  {
    type: "sheet",
    body:
      '@media (max-width:991px) and (min-width:768px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="6516a443-e082-ba54-bf5e-2ac8d24e68cd"]{height:0}}@media (max-width:767px) and (min-width:480px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="6516a443-e082-ba54-bf5e-2ac8d24e68cd"]{height:0}}@media (max-width:479px){.af-view.w-mod-js:not(.w-mod-ix) [data-w-id="6516a443-e082-ba54-bf5e-2ac8d24e68cd"]{height:0}}',
  },
];

const loadingStyles = styles.map((style) => {
  let styleEl;
  let loading;

  if (style.type === "href") {
    styleEl = document.createElement("link");

    loading = new Promise((resolve, reject) => {
      styleEl.onload = resolve;
      styleEl.onerror = reject;
    });

    styleEl.rel = "stylesheet";
    styleEl.type = "text/css";
    styleEl.href = style.body;
  } else {
    styleEl = document.createElement("style");
    styleEl.type = "text/css";
    styleEl.innerHTML = style.body;

    loading = Promise.resolve();
  }

  document.head.appendChild(styleEl);

  return loading;
});

export default Promise.all(loadingStyles).then(() => {
  const styleSheets = Array.from(document.styleSheets).filter((styleSheet) => {
    return (
      styleSheet.href &&
      styles.some((style) => {
        return style.type === "href" && styleSheet.href.match(style.body);
      })
    );
  });
  styleSheets.forEach((styleSheet) => {
    Array.from(styleSheet.rules).forEach((rule) => {
      if (rule.selectorText) {
        rule.selectorText = rule.selectorText
          .replace(/\.([\w_-]+)/g, ".af-class-$1")
          .replace(
            /\[class(.?)="( ?)([^"]+)( ?)"\]/g,
            '[class$1="$2af-class-$3$4"]'
          )
          .replace(/([^\s][^,]*)(\s*,?)/g, ".af-view $1$2")
          .replace(/\.af-view html/g, ".af-view")
          .replace(/\.af-view body/g, ".af-view")
          .replace(/af-class-w-/g, "w-")
          .replace(/af-class-anima-/g, "anima-")
          .replace(
            /af-class-([\w_-]+)an-animation([\w_-]+)/g,
            "$1an-animation$2"
          );
      }
    });
  });
});
