//
//  views/SearchView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Navigation } from "../components/Navigation";

import Footer from "../components/Footer";
import Announcement from "../components/Announcement";
import Loading from "../components/Loading";
import SEO from "../components/SEO";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

// TODO:
// - [x] check the value of search when pages loads and redirect to / if it's null.
// this is the case for when we go back to the page.

const SearchView = () => {
  // Extract parameters from the URL
  const { id } = useParams();
  const searchQuery = id;

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  // State to manage search-related data
  const [searchResults, setSearchResults] = useState(null);

  // Navigation hook for programmatic navigation
  const navigate = useNavigate();

  // Ref to track the mount state of the component
  const isMounted = useRef(true);

  // Redux selector to get dynamic data
  const Products = useSelector(
    (state) => state.dynamicData.data?.Products || []
  );

  // State to hold the user's search query
  const [userSearchQuery, setSearchQuery] = useState("");

  // Handle search form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search/${userSearchQuery}`);
  };

  useEffect(() => {
    // Component mounted
    isMounted.current = true;

    if (!searchQuery || searchQuery.trim() === "") {
      console.log("No search query provided, redirecting to /");
      navigate("/");
      return;
    }

    // DEBUG: Log the search query
    //  console.log("Search query:", searchQuery);

    // Sanitize the search query
    const sanitisedQuery = searchQuery
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toLowerCase();

    // Filter products based on the search query
    const results = Products.filter((product) => {
      const fieldsToSearch = [
        "category",
        "details",
        "description",
        "line",
        "name",
      ];
      for (const field of fieldsToSearch) {
        const fieldValue = product[field]?.data;
        if (fieldValue && fieldValue.toLowerCase().includes(sanitisedQuery)) {
          return true;
        }
      }
      return false;
    });

    // Set search results and navigate to the search page
    setSearchResults(results);
    navigate(`/search/${searchQuery}`);

    // Set data attributes for Webflow
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    // Load external scripts sequentially
    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, [searchQuery, navigate, Products]);

  // DEBUG: Log results
  //  console.log("searchResults:", searchResults);

  return (
    <div>
      <SEO
        title="L'SAE Studio | Search"
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="search"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);

          body {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <div className="af-view">
        {searchResults !== null ? ( // Check for null instead of truthy value
          <>
            <div>
              <Announcement />
              <Navigation />
              <form
                onSubmit={handleSubmit} // Attach the onSubmit handler
                id="w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf40d-f6abf40a"
                className="af-class-nav-search-form af-class-nav-search-mobile af-class-search-body w-form"
              >
                <img alt="" src="/images/icon-search.svg" loading="eager" />
                <input
                  type="search"
                  className="af-class-search-form-input w-input"
                  maxLength={256}
                  name="query"
                  placeholder="Search here"
                  id="search-2"
                  required
                  value={userSearchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
                <input
                  type="submit"
                  className="af-class-hide-button w-button"
                  value={userSearchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
              </form>
              <div className="af-class-section af-class-no-bottom-padding">
                <div className="af-class-main-container">
                  <h1 className="af-class-medium-heading af-class-no-bottom-margin">
                    Search Results
                  </h1>
                </div>
              </div>
              <div className="af-class-section">
                {searchResults.length === 0 ? (
                  <div className="af-class-main-container">
                    <div
                      className="w-dyn-empty"
                      style={{ textAlign: "center" }}
                    >
                      <div>No items found.</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="af-class-main-container">
                      <div className="af-class-search-results-grid af-class-search-result-items">
                        {searchResults.map((result, index) => (
                          <Link
                            onClick={handleScroll}
                            to={`/product/${result.slug?.data}`}
                            className="af-class-search-result-link"
                            key={index}
                          >
                            <div className="af-class-search-result-item">
                              {result.name?.data}
                              <p
                                className="af-class-small-text"
                                style={{ fontWeight: "normal" }}
                              >
                                {result.description?.data &&
                                  (() => {
                                    const description = result.description.data;
                                    const firstPeriodIndex = description.indexOf(
                                      "."
                                    );
                                    const truncatedDescription =
                                      firstPeriodIndex !== -1
                                        ? description.substring(
                                            0,
                                            firstPeriodIndex + 1
                                          )
                                        : description;
                                    // Remove <br/> tags from truncatedDescription
                                    const cleanedDescription = truncatedDescription.replace(
                                      /<br\/>/g,
                                      ""
                                    );
                                    return cleanedDescription;
                                  })()}
                              </p>
                              <img
                                alt=""
                                src={result.thumbnail?.data.url}
                                loading="lazy"
                                className="af-class-search-results-image"
                              />
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Footer />
            </div>
          </>
        ) : (
          // Display loading spinner while data is being fetched
          <Loading />
        )}
      </div>
    </div>
  );
};

// Export the SearchView component
export default SearchView;
