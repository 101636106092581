//
//  state/reducers/basketSlice.js
//  LSAE
//
//  Created by Marcel on 2024-02-04.
//  Copyright © 2024 Marcel. All rights reserved.
//

import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  basket: {
    id: "",
    token: "",
  },
  user: {
    name: "",
    phoneNumber: "",
    email: "",
  },
  shippingAddress: {
    fullName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  },
  billingAddress: {
    fullName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    VAT: "",
    VATOffice: "",
  },
};

// Create a slice
const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
    setBasket: (state, action) => {
      state.basket = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    // Reset specific elements
    resetUser: (state) => {
      state.user = initialState.user;
    },
    resetShippingAddress: (state) => {
      state.shippingAddress = initialState.shippingAddress;
    },
    resetBillingAddress: (state) => {
      state.billingAddress = initialState.billingAddress;
    },
    // Reset the entire basket
    resetBasket: (state) => {
      state.user = initialState.user;
      state.basket = initialState.basket;
      state.shippingAddress = initialState.shippingAddress;
      state.billingAddress = initialState.billingAddress;
    },
  },
});

// Export actions and reducer
export const {
  setUser,
  setBasket,
  setShippingAddress,
  setBillingAddress,
  resetUser,
  resetShippingAddress,
  resetBillingAddress,
  resetBasket,
} = basketSlice.actions;

export default basketSlice.reducer;
