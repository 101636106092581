//
//  components/Footer.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/opacity.css";

// TODO:
// - [x] feed submission feedback

const Footer = () => {
  const Categories = useSelector(
    (state) => state.dynamicData.data?.Categories || []
  );
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState("");
  const location = useLocation(); // Get the current page's URL

  const handleSubscription = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setSubscriptionError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-lsae-86346.cloudfunctions.net/feed",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            subscribed: "true",
          }),
        }
      );
      console.log("res", response);

      if (response.ok) {
        setEmail("");
        setSubscribed(true);
        setSubscriptionError("");
        showSuccessMessage(); // Show success message
        hideForm(); // Hide the form on success
      } else {
        const responseBody = await response.json();
        console.error("Subscription error:", responseBody);
        setSubscriptionError(
          responseBody.error ||
            "Oops! Something went wrong while submitting the form."
        );
        showErrorMessage(); // Show error message
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setSubscriptionError(
        "Oops! Something went wrong while submitting the form."
      );
      showErrorMessage(); // Show error message
    }
  };

  function isValidEmail(email) {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  }

  const hideForm = () => {
    const subscribeForm = document.querySelector(".af-class-subscribe-form");
    if (subscribeForm) {
      subscribeForm.style.display = "none";
    }
  };

  const showSuccessMessage = () => {
    const successMessage = document.querySelector(".af-class-form-success");
    successMessage.style.display = "block";

    const errorMessage = document.querySelector(".af-class-form-error");
    errorMessage.style.display = "none";
  };

  const showErrorMessage = () => {
    const errorMessage = document.querySelector(".af-class-form-error");
    errorMessage.style.display = "block";

    const successMessage = document.querySelector(".af-class-form-success");
    successMessage.style.display = "none";
  };

  useEffect(() => {
    const emailLink = document.querySelector(
      ".af-class-contact-links.af-class-in-footer a:first-child"
    );
    const phoneLink = document.querySelector(
      ".af-class-contact-links.af-class-in-footer a:last-child"
    );

    if (emailLink && phoneLink) {
      emailLink.textContent = atob(encodedEmail);
      emailLink.href = "mailto:" + atob(encodedEmail);

      phoneLink.textContent = atob(encodedPhone);
      phoneLink.href = "tel:" + atob(encodedPhone);
    }
  }, []);

  // Encode email and phone number
  const encodedEmail = btoa("info@lsaestudio.com");
  const encodedPhone = btoa("+90 (212) 871 7516");

  return (
    <div className="af-class-footer">
      <div className="af-class-main-container">
        <div className="af-class-footer-subscribe-section">
          <h3 className="af-class-no-bottom-margin">
            Subscribe for events and new product announcements.
          </h3>
          <div id="w-node-_3ee0633f-a568-72e0-ab7b-589be9d960dd-e9d960d8">
            <div className="af-class-form-block w-form">
              <form
                onSubmit={handleSubscription}
                id="wf-form-Newsletter-Form"
                name="wf-form-Newsletter-Form"
                data-name="Newsletter Form"
                method="POST"
                className="af-class-subscribe-form"
                data-wf-page-id="64fa27262323abc6734c7867"
                data-wf-element-id="3ee0633f-a568-72e0-ab7b-589be9d960df"
              >
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="af-class-form-input af-class-subscribe-form-input w-input"
                  maxLength={256}
                  name="email"
                  data-name="Email"
                  placeholder="Email Address"
                  id="email"
                  required
                />
                <input
                  type="submit"
                  defaultValue="Subscribe"
                  data-wait="Please wait..."
                  className="af-class-button w-button"
                />
              </form>
              <div className="af-class-form-success w-form-done">
                <div>Thanks for subscribing!</div>
              </div>
              <div className="af-class-form-error">
                <div>{subscriptionError}</div>
              </div>
            </div>
            <div className="af-class-fine-print-text af-class-form-hint-text">
              We will never share your email with third parties.
            </div>
          </div>
        </div>
      </div>
      <div className="af-class-footer-details-section">
        <div className="af-class-main-container">
          <div className="w-layout-grid af-class-footer-grid">
            <div
              id="w-node-_3ee0633f-a568-72e0-ab7b-589be9d960ed-e9d960d8"
              className="af-class-footer-contact-details"
            >
              <a
                href="https://goo.gl/maps/PqyejwrgqrYfVL3e8"
                className="af-class-footer-logo-link w-inline-block"
              >
                <LazyLoadImage
                  src="/images/L_SAE-Logo-04.svg"
                  effect="opacity"
                  className="af-class-footer-logo"
                />
              </a>
              <div>Cihangir, Pürtelaş Sk. 3/A, 34433 Beyoğlu/İstanbul</div>
              <div className="af-class-contact-links af-class-in-footer">
                <a href="#" className="af-class-small-text" />
                <a href="#" className="af-class-small-text" />
              </div>
            </div>
            <div>
              <div className="af-class-uppercase-text af-class-links-grid-title">
                shop
              </div>
              <div className="af-class-links-grid">
                <a href="/shop" className="af-class-list-link">
                  All
                </a>
                {Categories &&
                  Categories?.map((data, idx) => (
                    <div key={idx} role="listitem" className="w-dyn-item">
                      <a
                        href={`/category/${data.slug?.data.toLowerCase()}`}
                        className="af-class-list-link"
                        style={{
                          fontWeight:
                            location.pathname ===
                            `/category/${data.slug?.data.toLowerCase()}`
                              ? "700"
                              : "500",
                        }}
                      >
                        {data.name?.data}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className="af-class-uppercase-text af-class-links-grid-title">
                Company
              </div>
              <div className="af-class-links-grid">
                <a href="/about" className="af-class-list-link">
                  About
                </a>
                <a href="/journal" className="af-class-list-link-journal">
                  Journal
                </a>
                <a href="/contact" className="af-class-list-link">
                  Contact
                </a>
                <a href="/policies" className="af-class-list-link">
                  Policies & Agreements
                </a>
              </div>
            </div>
          </div>
          <div className="af-class-footer-fine-print">
            <div className="af-class-fine-print-text">
              <a
                href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                target="_blank"
                rel="noreferrer"
              >
                © All rights reserved 2023 L'SAE Studio
              </a>
            </div>
            <div className="af-class-social-links">
              <a
                href="https://www.instagram.com/lsaestudio/"
                target="_blank"
                rel="noreferrer"
                className="af-class-social-link-ie w-inline-block"
              >
                <img alt="" src="/images/icon-Instagram.svg" loading="lazy" />
              </a>
              <a
                href="https://www.pinterest.com/lsaestudio/"
                target="_blank"
                rel="noreferrer"
                className="af-class-social-link-ie w-inline-block"
              >
                <img alt="" src="/images/icon-Pinterest.svg" loading="lazy" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
