//
//  localisation/locale.js
//  LSAE
//
//  Created by Marcel on 2023-09-24.
//  Copyright © 2023 Marcel. All rights reserved.
//

// localisation/locale.js
import tr from "./data/tr.json";
import en from "./data/en.json";

const defaultLocale = "en";

// Function to format a number
export function formatNumber(number, locale = defaultLocale) {
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  };

  return new Intl.NumberFormat(locale, options).format(number);
}

// Function to format a currency
export function formatCurrency(amount, locale = defaultLocale) {
  const currencySymbol = getCurrencySymbol(locale);
  const formattedAmount = formatNumber(amount, locale);
  return `${currencySymbol}${formattedAmount}`;
}

// Function to get the currency symbol based on locale
function getCurrencySymbol(locale) {
  return locale === "tr" ? tr.currency : en.currency;
}

// Function to format a date
export function formatDate(date, format = "short", locale = defaultLocale) {
  const dateFormat = getDateFormat(locale, format);
  return new Intl.DateTimeFormat(locale, { dateStyle: dateFormat }).format(date);
}

// Function to get the date format based on locale
function getDateFormat(locale, format) {
  return locale === "tr" ? tr.dateFormats[format] : en.dateFormats[format];
}
