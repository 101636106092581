//
//  state/reducers/preferencesSlice.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import { createSlice } from "@reduxjs/toolkit";

const LOCALE_EN = "en";
const LOCALE_TR = "tr";

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: {
    locale: null,
  },
  reducers: {
    setPreferences: (state, action) => {
      const newLocale = action.payload;

      if (
        typeof newLocale === "string" &&
        (newLocale === LOCALE_EN || newLocale === LOCALE_TR)
      ) {
        console.log("Setting new locale:", newLocale);
        state.locale = newLocale;
      } else {
        console.error("Invalid locale:", newLocale);
      }
    },
  },
});

export const { setPreferences } = preferencesSlice.actions;
export default preferencesSlice.reducer;

// Log when the file is imported
console.log("preferencesSlice imported");
