//
//  views/NotFoundView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect } from "react";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const NotFoundView = () => {
  useEffect(() => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []); // Empty dependency array to mimic componentDidMount

  return (
    <span>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <div className="af-class-utility-page-wrap">
            <div className="af-class-utility-page-content w-form">
              <h2>404 - Page Not Found</h2>
              <div>
                The page you are looking for doesn't exist or may have been
                moved.
              </div>
              <div className="af-class-button-row af-class-justify-content-center">
                <a href="/" className="af-class-button w-inline-block">
                  <div>Back to Home</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};

export default NotFoundView;
