//
//  views/Router/index.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//


import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import {
  // UnauthorizedView,
  NotFoundView,
  AboutView,
  CheckoutView,
  ContactView,
  // DetailBrandsView,
  DetailCategoryView,
  // DetailJournalArticleView,
  DetailProductView,
  // DetailSkuView,
  IndexView,
  // JournalView,
  OrderConfirmationView,
  // PaypalCheckoutView,
  PoliciesView,
  SearchView,
  ShopView,
} from "..";
import paths from "./paths";
import { useDynamicData } from "../../hooks/UseDynamicData";
import RSVPView from "../RSVPView";

// TODO: does commenting these out break things? yes. wait... no it doesn't.
// import '../../assets/css/webflow.css';
// import '../../assets/css/normalize.css';
// import '../../assets/css/lsae.webflow.css';

// import '../../styles/index'

const RouterComponent = () => {
   console.log("RouterComponent rendering...");
  const { loading, error } = useDynamicData();
   console.log("useDynamicData hook executed.");

  // Fetch dynamic data (Projects, Teams, etc.) using a custom hook
  useEffect(() => {
     console.log("useEffect in RouterComponent running...");
    // You can access loading and error here if needed
  }, [loading, error]); // Include any dependencies as needed

  return (
    <BrowserRouter forceRefresh={true}>
      {/* <Navigation />  */}
      <Routes>
        <Route exact path={paths.ROOT} element={<IndexView />} />
        <Route exact path={paths.INDEX} element={<IndexView />} />
        <Route exact path={paths.PRODUCTS} element={<ShopView />} />
        <Route exact path={paths.ABOUT} element={<AboutView />} />
        <Route path={paths.PRODUCT} element={<DetailProductView />} />
        {/* Render each project detail page */}
        {/* {productData &&
          productData.map((page, index) => (
            <Route
              key={index}
              path={paths.PRODUCT}
              element={<DetailProductView/>}
              exact
            />
          ))} */}
        <Route path={paths.CATEGORY} element={<DetailCategoryView />} />
        <Route exact path={paths.CONTACT} element={<ContactView />} />
        <Route exact path={paths.CHECKOUT} element={<CheckoutView />} />
        {/* <Route path={paths.BRANDS_DETAIL} element={<DetailBrandsView />} /> */}
        {/* <Route path={paths.JOURNAL_ARTICLE_DETAIL} element={<DetailJournalArticleView />} /> */}
        {/* <Route path={paths.SKU_DETAIL} element={<DetailSkuView />} /> */}
        {/* <Route path={paths.JOURNAL} element={<JournalView />} /> */}
        <Route
          path={paths.ORDER_CONFIRMATION}
          element={<OrderConfirmationView />}
        />
        {/* <Route path={paths.PAYPAL_CHECKOUT} element={<PaypalCheckoutView />} /> */}
        <Route exact path={paths.REFUND_POLICY} element={<PoliciesView />} />
        <Route path={paths.SEARCH} element={<SearchView />} />

        {/* Add a catch-all route for NotFoundView */}
        <Route path="*" element={<NotFoundView />} />
        <Route path={paths.RSVP} element={<RSVPView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
