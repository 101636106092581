//
//  views/RSVPView.js
//  LSAE
//
//  Created by Marcel on 2024-05-02.
//  Copyright © 2024 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SEO from "../components/SEO";
import axios from "axios";

const EVENT_ID = "MAY2024";
const CLOUD_FUNCTION_BASE_URL =
  "https://us-central1-lsae-86346.cloudfunctions.net/rsvp";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const RSVPButton = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  padding: "10px 20px",
  margin: "0px 5px 0px 5px",
  fontSize: "16px",
  "&.primary": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&.secondary": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  "&.green": {
    // New green variant
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  "&.red": {
    // New red variant
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  "&.maybe": {
    // New maybe variant
    backgroundColor: "gray",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgray",
    },
  },
}));

const RSVPView = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [attendeeHandle, setAttendeeHandle] = useState("");
  const [ip, setIp] = useState("");
  const [ipFetchError, setIpFetchError] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading status

  useEffect(() => {
    // Fetch user IP when the component mounts
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json"); // Changed to HTTPS
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error("Error fetching IP:", error);
        setIpFetchError(true);
      }
    };

    fetchIp();
  }, []);

  const handleRSVP = async (attendance) => {
    try {
      setLoading(true); // Set loading to true when submitting
      // Send RSVP data to backend function
      await axios.post(`${CLOUD_FUNCTION_BASE_URL}/${EVENT_ID}`, {
        attendeeHandle,
        attendance,
        ip,
      });
      console.log(attendeeHandle, attendance, ip);

      // Reset form fields
      setAttendeeHandle("");
      setLoading(false); // Set loading to false after successful submission

      alert("RSVP submitted successfully");
    } catch (error) {
      console.error("Error submitting RSVP:", error);
      setLoading(false); // Set loading to false if submission fails
      alert("Something went wrong. Please let us know.");
    }
  };

  useEffect(() => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });

    // Check if device width is less than or equal to 600px (typical mobile width)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    // Call your RSVP handling function here
    // For example: handleRSVP(true) or handleRSVP(false)
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <span>
        <SEO
          title="L'SAE Studio | RSVP"
          description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
          name="L'SAE Studio"
          type="rsvp"
          imageUrl="https://lsaestudio.com/images/L_SAE-Logo-033x-100-p-1600.jpg"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);
          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              overflow: hidden; /* Disable scrolling */
              margin: 0; /* Remove default margin */
              height: 100vh; /* Make container fill the screen */
              display: flex; /* Use flexbox for layout */
              flex-direction: column; /* Stack children vertically */
              justify-content: center; /* Center content vertically */
              align-items: center; /* Center content horizontally */
              background-color: #0C0E03; /* Set background color */
              position: relative; /* Establish positioning context */
          }
          .content {
              display: flex;
              flex-direction: column;
              align-items: center; /* Center horizontally */
              flex-grow: 1; /* Allow content to expand */
              padding: 20px; /* Added padding */
              line-height: 1.5; /* Increased line height */
          }
          .left-image,
          .right-image {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
              height: 50%; /* Maintain aspect ratio */
              z-index: -1; /* Send images to the back */
          }
          .left-image {
              left: 0;
          }
          .right-image {
              right: 0;
          }
          .text {
              color: white;
              font-size: 24px;
              text-align: center;
              margin: 10px 0; /* Adjust margin */
          }
          .address {
              color: white;
              font-size: 16px;
              text-align: center;
              position: absolute;
              bottom: 10px;
              margin-bottom: 10px; /* Add margin to bottom */
          }
          .input-field {
              margin-bottom: 20px;
              display: flex;
              justify-content: center;
          }
          .footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            color: white;
          }
          .fixed-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            z-index: 9999; /* Ensure it's above other content */
            padding: 10px 0; /* Adjust padding as needed */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
          }
          .button-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .button-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-gap: 10px;
          }
          
          `,
          }}
        />
        <div className="content">
          {isMobile ? (
            <img src="/images/Rhino.png" alt="" className="right-image" />
          ) : (
            <>
              <img
                src="/images/Bull.png"
                alt="Left Image"
                className="left-image"
              />
              <img
                src="/images/Rhino.png"
                alt="Right Image"
                className="right-image"
              />
            </>
          )}
          <div className="fixed-content">
            <div className="text" style={{ fontSize: "11px" }}>
              Reserved exclusively for those who have received PR deliveries.
            </div>
          </div>
          <img
            src="https://lsaestudio.com/images/L_SAE-Logo-04.svg"
            className="af-class-image" // Changed class to className
            alt="L'SAE Studio Logo"
            style={{
              maxHeight: "200px",
              width: "450px",
              marginTop: "-100px",
              objectFit: "cover",
              filter: "invert(100%)",
            }}
          />
          <div className="input-field">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 0, width: "100%", minWidth: "190px" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleFormSubmit}
            >
              <TextField
                id="attendeeHandle"
                label="First and last name"
                variant="outlined"
                fullWidth
                required
                value={attendeeHandle}
                onChange={(e) => setAttendeeHandle(e.target.value)}
              />
            </Box>
          </div>
          <div className="button-container">
            <div className={` ${isMobile ? "button-grid" : ""}`}>
              <RSVPButton
                className="green"
                onClick={() => handleRSVP(true)}
                disabled={loading} // Disable button while loading
              >
                {loading ? "Submitting..." : "Attending"}
              </RSVPButton>
              <RSVPButton
                className="maybe"
                onClick={() => handleRSVP(null)} // Maybe button click handler
                disabled={loading} // Disable button while loading
              >
                {loading ? "Submitting..." : "Maybe"}
              </RSVPButton>
              <RSVPButton
                className="red"
                onClick={() => handleRSVP(false)}
                disabled={loading} // Disable button while loading
              >
                {loading ? "Submitting..." : "Can't Attend"}
              </RSVPButton>
            </div>
          </div>
          <div className="footer">
            <div className="text" style={{ fontSize: "16px" }}>
              Saturday, May 11th 15:00 - 17:00
            </div>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href="https://maps.app.goo.gl/GxjNHVAA9XjcAR8r5"
              target="_blank"
              rel="noopener noreferrer"
            >
              CİHANGİR PÜTELAŞ SK. 3A BEYOĞLU İSTANBUL
            </a>
          </div>
          {ipFetchError && <div>Error fetching IP address</div>}
        </div>
      </span>
    </ThemeProvider>
  );
};

export default RSVPView;
