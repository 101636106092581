//
//  views/Router/paths.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

// Router/paths.js 

const paths = {
  ROOT: '/',
  HOME: '/',
  ABOUT: '/about',
  PRODUCTS: '/shop',
  PRODUCT: '/product/:id',
  CATEGORY: '/category/:id',
  CONTACT: '/contact',
  UNAUTHORIZED: '/unauthorized',
  NOT_FOUND: '/not-found',
  CHECKOUT: '/checkout',
  BRANDS_DETAIL: '/brands/:id',
  // CATEGORY_DETAIL: '/category/:id',
  JOURNAL_ARTICLE_DETAIL: '/journal/:id',
  PRODUCT_DETAIL: '/product/:id',
  SKU_DETAIL: '/sku/:id',
  INDEX: '/index',
  JOURNAL: '/journal',
  ORDER_CONFIRMATION: '/order-confirmation',
  PAYPAL_CHECKOUT: '/paypal-checkout',
  REFUND_POLICY: '/policies',
  SEARCH: '/search/:id',
  LANDING: '/landing',
  RSVP: '/rsvp'
};

export default paths;