// state/store.js

import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import dynamicDataSlice from './reducers/dynamicDataSlice';
import preferencesSlice from './reducers/preferencesSlice';
import cartSlice from './reducers/cartSlice';
import basketSlice from './reducers/basketSlice';

console.log('store.js - Before configureStore');

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const cartPersistantReducer = persistReducer(persistConfig, cartSlice);
const dataPersistantReducer = persistReducer(persistConfig, dynamicDataSlice);
const basketPersistantReducer = persistReducer(persistConfig, basketSlice); 

export const store = configureStore({
  reducer: {
    dynamicData: dataPersistantReducer,
    preferences: preferencesSlice,
    cart: cartPersistantReducer,
    basket: basketPersistantReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

console.log('Initial store state:', store.getState());

// // Load data from local storage
// const localData = JSON.parse(localStorage.getItem('dynamicData')) || {};

// // Dispatch setDynamicData action to update the Redux store
// store.dispatch(setDynamicData(localData));

// console.log('Updated store state after loading local data:', store.getState());

console.log('store.js - After configureStore');

export let persistor = persistStore(store);
export default store;
