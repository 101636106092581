//
//  views/PoliciesView.js
//  LSAE
//
//  Created by Marcel on 2024-01-23.
//  Copyright © 2024 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";

import { Navigation } from "../components/Navigation";

import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Collapsible from "@edonec/collapsible";
// import "@edonec/collapsible/build/index.css";
// import "@edonec/collapsible/build/icons.css";
import "../assets/css/collapsible.css";
import SEO from "../components/SEO";

// TODO:
// - [ ] replace collapsible
// - [ ]
// - [ ]

const PoliciesView = () => {
  // Encode email and phone number
  const encodedEmail = btoa("info@lsaestudio.com");
  const encodedPhone = btoa("+90 (212) 871 7516");

  const [visible, setVisible] = useState(false);

  const scripts = [
    {
      src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
      async: true,
    },
    {
      src: "/js/webflow.js",
      async: false,
    },
  ];

  const loadScript = (src, async) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = async;

      script.onload = resolve;
      script.onerror = reject;

      document.head.appendChild(script);
    });
  };

  const loadScriptsSequentially = async (scripts) => {
    for (let i = 0; i < scripts.length; i++) {
      await loadScript(scripts[i].src, scripts[i].async);
    }
  };

  useEffect(() => {
    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });

    // Decode email and phone numbers when component is loaded
    const emailLink = document.querySelector(".encodedEmail");
    const phoneLink = document.querySelector(".encodedPhone");

    if (emailLink && phoneLink) {
      emailLink.textContent = atob(encodedEmail); // Decode email from base64
      emailLink.href = "mailto:" + atob(encodedEmail);

      phoneLink.textContent = atob(encodedPhone); // Decode phone number from base64
      phoneLink.href = "tel:" + atob(encodedPhone);
    }

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";
  }, [encodedEmail, encodedPhone]);

  return (
    <span>
      <SEO
        title="L'SAE Studio | Policies"
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="policies"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea {
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <Announcement />
          <Navigation />
          <div className="af-class-section">
            <div
              className="af-class-main-container"
              style={{ maxWidth: "60vw" }}
            >
              <div className="af-class-text-center">
                <h1 className="af-class-no-bottom-margin">
                  Policies & Agreements
                </h1>
              </div>
              <br />
              <br />
              <Collapsible header="Refund &amp; Cancellation Policy -  İptal ve İade Politikası">
                <div className="af-class-article-container">
                  <div className="af-class-text-center">
                    <div className="af-class-article-date af-class-increased-margin">
                      Updated 12th of December, 2023
                    </div>
                    <h1 className="af-class-no-bottom-margin">Refund Policy</h1>
                  </div>
                  <div className="af-class-article-body w-richtext">
                    <p>
                      At L'SAE Studio, we are committed to providing our valued
                      customers with high-quality art and interior products that
                      exceed your expectations. We understand that occasionally,
                      you may need to request a refund. Please take a moment to
                      review our refund policy outlined below to ensure a smooth
                      and fair refund process.
                    </p>

                    <h3>1. Eligibility for Refund:</h3>

                    <p>We offer refunds for the following situations:</p>

                    <ul>
                      <li>
                        <strong>Product Defect or Damage:</strong> If your
                        product arrives damaged or has a manufacturing defect,
                        we will gladly provide a full refund or a replacement
                        product at no additional cost. Please provide clear
                        photographs of the damaged or defective item within 7
                        days of receiving the product to initiate the refund
                        process.
                      </li>
                      <li>
                        <strong>Incorrect Item Received:</strong> If you receive
                        an incorrect item due to an error on our part, we will
                        promptly replace the item or provide a full refund upon
                        return of the incorrect product.
                      </li>
                    </ul>

                    <h3>2. Refund Request Process:</h3>

                    <p>To request a refund, please follow these steps:</p>

                    <ul>
                      <li>
                        <strong>Contact Us:</strong> Send an email to&nbsp;
                        <a href={`mailto:${atob(encodedEmail)}`}>
                          {atob(encodedEmail)}
                        </a>
                        within a week of receiving your product, clearly stating
                        the reason for the refund request, and providing
                        relevant supporting information (such as photos of the
                        damaged item or a description of the incorrect item
                        received).
                      </li>

                      <li>
                        <strong>Return Authorization:</strong> If your request
                        is approved, our customer support team will provide you
                        with a Return Authorization Number (RAN) and
                        instructions for returning the product. Please do not
                        return any items without obtaining a RAN.
                      </li>
                      <li>
                        <strong>Return the Product:</strong> Carefully pack and
                        ship the product back to us within one week of receiving
                        your RAN. Ensure that the product is in its original
                        condition and packaging, including all accessories and
                        documentation.
                      </li>
                    </ul>

                    <h3>3. Refund Processing:</h3>

                    <p>
                      Once we receive and inspect the returned product, we will
                      process your refund within 14 business days. The refund
                      will be issued in the original payment method used for the
                      purchase.
                    </p>
                    <p>
                      If you are eligible for a replacement item, we will ship
                      it to you at no additional cost as soon as possible.
                    </p>

                    <h3>4. Non-Refundable Items:</h3>

                    <p>
                      Please note that the following items are non-refundable:
                    </p>

                    <ul>
                      <li>
                        Custom-made or personalized products, as they are
                        crafted specifically for you.
                      </li>
                      <li>Items damaged due to mishandling or misuse.</li>
                    </ul>

                    <h3>5. Cancellations:</h3>

                    <p>
                      You may cancel your order within 24 hours of placing it
                      for a full refund. After this period, cancellations may
                      not be possible if the item has already been shipped.
                      Please contact us immediately if you wish to cancel your
                      order.
                    </p>

                    <h3>6. Contact Information:</h3>

                    <p>
                      If you have any questions, concerns, or need to initiate a
                      refund request, please contact our customer support team
                      at&nbsp;
                      <a href={`mailto:${atob(encodedEmail)}`}>
                        {atob(encodedEmail)}
                      </a>
                      or call us at&nbsp;
                      <a href={`tel:${atob(encodedPhone)}`}>
                        {atob(encodedPhone)}
                      </a>
                      .
                    </p>

                    <p>
                      L'SAE Studio reserves the right to update or modify this
                      refund policy at any time. Any changes will be posted on
                      our website and will apply to all purchases made after the
                      policy update.
                    </p>

                    <p>
                      Thank you for choosing L'SAE Studio. We appreciate your
                      trust in our products and look forward to serving you with
                      exceptional art and interior designs.
                    </p>

                    <p>Sincerely,</p>
                  </div>
                </div>
              </Collapsible>
              <Collapsible header="Privacy Policy - Gi̇zli̇li̇k Poli̇ti̇kası">
                <div
                  className="af-class-article-container"
                  style={{ maxWidth: "60vw" }}
                >
                  <div className="af-class-text-center">
                    <div className="af-class-article-date af-class-increased-margin">
                      Updated 20th of February, 2024
                    </div>
                    <h1 className="af-class-no-bottom-margin">
                      Gi̇zli̇li̇k ve Güvenli̇k Poli̇ti̇kasi
                    </h1>
                  </div>
                  <div className="af-class-article-body w-richtext">
                    <p>
                      Fiziki ve online mağazamızda verilen tüm servisler, ürün
                      satışları ve benzeri
                      <i>Cihangir Mahallesi Pürtelaş Sk. No:3A Beyoğlu</i> /
                      İstanbul adresinde kayıtlı Solo Mimarlık ünvanlı firmamıza
                      aittir ve firmamız tarafından işletilir.
                    </p>
                    <p>
                      Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir.
                      Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde
                      toplandığı, bu verilerin nasıl ve ne şekilde korunduğu
                      belirtilmiştir. 
                    </p>
                    <p>
                      Üyelik veya Mağazamız üzerindeki çeşitli form ve
                      anketlerin doldurulması suretiyle üyelerin kendileriyle
                      ilgili bir takım kişisel bilgileri (isim-soy isim, firma
                      bilgileri, telefon, adres veya e-posta adresleri gibi)
                      Mağazamız tarafından işin doğası gereği toplanmaktadır. 
                    </p>
                    <p>
                      Firmamız bazı dönemlerde müşterilerine ve üyelerine
                      kampanya bilgileri, yeni ürünler hakkında bilgiler,
                      promosyon teklifleri gönderebilir. Üyelerimiz bu gibi
                      bilgileri alıp almama konusunda her türlü seçimi üye
                      olurken yapabilir, sonrasında üye girişi yaptıktan sonra
                      hesap bilgileri bölümünden bu seçimi değiştirilebilir ya
                      da kendisine gelen bilgilendirme iletisindeki linkle
                      bildirim yapabilir. 
                    </p>
                    <p>
                      Mağazamız üzerinden veya eposta ile gerçekleştirilen onay
                      sürecinde, üyelerimiz tarafından mağazamıza elektronik
                      ortamdan iletilen kişisel bilgiler, Üyelerimiz ile
                      yaptığımız "Kullanıcı Sözleşmesi" ile belirlenen amaçlar
                      ve kapsam dışında üçüncü kişilere açıklanmayacaktır.
                    </p>
                    <p>
                      Sistemle ilgili sorunların tanımlanması ve verilen hizmet
                      ile ilgili çıkabilecek sorunların veya uyuşmazlıkların
                      hızla çözülmesi için, Firmamız, üyelerinin IP adresini
                      kaydetmekte ve bunu kullanmaktadır. IP adresleri,
                      kullanıcıları genel bir şekilde tanımlamak ve kapsamlı
                      demografik bilgi toplamak amacıyla da kullanılabilir.
                    </p>
                    <p>
                      Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve
                      kapsam dışında da, talep edilen bilgileri kendisi veya
                      işbirliği içinde olduğu kişiler tarafından doğrudan
                      pazarlama yapmak amacıyla kullanabilir.  Kişisel bilgiler,
                      gerektiğinde kullanıcıyla temas kurmak için de
                      kullanılabilir. Firmamız tarafından talep edilen bilgiler
                      veya kullanıcı tarafından sağlanan bilgiler veya Mağazamız
                      üzerinden yapılan işlemlerle ilgili bilgiler; Firmamız ve
                      işbirliği içinde olduğu kişiler tarafından, "Üyelik
                      Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da,
                      üyelerimizin kimliği ifşa edilmeden çeşitli istatistiksel
                      değerlendirmeler, veri tabanı oluşturma ve pazar
                      araştırmalarında kullanılabilir.
                    </p>
                    <p>
                      Firmamız, gizli bilgileri kesinlikle özel ve gizli
                      tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve
                      gizliliğin sağlanması ve sürdürülmesi, gizli bilginin
                      tamamının veya herhangi bir kısmının kamu alanına
                      girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye
                      ifşasını önlemek için gerekli tüm tedbirleri almayı ve
                      gerekli özeni göstermeyi taahhüt etmektedir.
                    </p>
                    <h3>Kredi Kartı Güvenliği</h3>
                    <p>
                      Firmamız, alışveriş sitelerimizden alışveriş yapan kredi
                      kartı sahiplerinin güvenliğini ilk planda tutmaktadır.
                      Kredi kartı bilgileriniz hiçbir şekilde sistemimizde
                      saklanmamaktadır.
                    </p>

                    <p>
                      İşlemler sürecine girdiğinizde güvenli bir sitede
                      olduğunuzu anlamak için dikkat etmeniz gereken iki şey
                      vardır. Bunlardan biri tarayıcınızın en alt satırında
                      bulunan bir anahtar ya da kilit simgesidir. Bu güvenli bir
                      internet sayfasında olduğunuzu gösterir ve her türlü
                      bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak
                      satış işlemleri sürecine bağlı olarak ve verdiğiniz
                      talimat istikametinde kullanılır. Alışveriş sırasında
                      kullanılan kredi kartı ile ilgili bilgiler alışveriş
                      sitelerimizden bağımsız olarak 128 bit SSL (Secure Sockets
                      Layer) protokolü ile şifrelenip sorgulanmak üzere ilgili
                      bankaya ulaştırılır. Kartın kullanılabilirliği onaylandığı
                      takdirde alışverişe devam edilir. Kartla ilgili hiçbir
                      bilgi tarafımızdan görüntülenemediğinden ve
                      kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda
                      bu bilgileri ele geçirmesi engellenmiş olur.
                    </p>
                    <p>
                      Online olarak kredi kartı ile verilen siparişlerin
                      ödeme/fatura/teslimat adresi bilgilerinin güvenilirliği
                      firmamız tarafından Kredi Kartları Dolandırıcılığı'na
                      karşı denetlenmektedir. Bu yüzden, alışveriş
                      sitelerimizden ilk defa sipariş veren müşterilerin
                      siparişlerinin tedarik ve teslimat aşamasına gelebilmesi
                      için öncelikle finansal ve adres/telefon bilgilerinin
                      doğruluğunun onaylanması gereklidir. Bu bilgilerin
                      kontrolü için gerekirse kredi kartı sahibi müşteri ile
                      veya ilgili banka ile irtibata geçilmektedir.
                    </p>
                    <p>
                      Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir
                      ve siz değiştirebilirsiniz. Üye giriş bilgilerinizi
                      güvenli koruduğunuz takdirde başkalarının sizinle ilgili
                      bilgilere ulaşması ve bunları değiştirmesi mümkün
                      değildir. Bu amaçla, üyelik işlemleri sırasında 128 bit
                      SSL güvenlik alanı içinde hareket edilir. Bu sistem
                      kırılması mümkün olmayan bir uluslararası bir şifreleme
                      standardıdır.
                    </p>
                    <p>
                      Bilgi hattı veya müşteri hizmetleri servisi bulunan ve
                      açık adres ve telefon bilgilerinin belirtildiği İnternet
                      alışveriş siteleri günümüzde daha fazla tercih
                      edilmektedir. Bu sayede aklınıza takılan bütün konular
                      hakkında detaylı bilgi alabilir, online alışveriş hizmeti
                      sağlayan firmanın güvenirliği konusunda daha sağlıklı
                      bilgi edinebilirsiniz. 
                    </p>
                    <p>
                      <b>Not</b>: İnternet alışveriş sitelerinde firmanın açık
                      adresinin ve telefonun yer almasına dikkat edilmesini
                      tavsiye ediyoruz. Alışveriş yapacaksanız alışverişinizi
                      yapmadan ürünü aldığınız mağazanın bütün telefon / adres
                      bilgilerini not edin. Eğer güvenmiyorsanız alışverişten
                      önce telefon ederek teyit edin. Firmamıza ait tüm online
                      alışveriş sitelerimizde firmamıza dair tüm bilgiler ve
                      firma yeri belirtilmiştir.
                    </p>

                    <h3>Mai̇l Order Kredi̇ Kart Bi̇lgi̇leri̇ Güvenli̇ği̇</h3>
                    <p>
                      Kredi kartı mail-order yöntemi ile bize göndereceğiniz
                      kimlik ve kredi kart bilgileriniz firmamız tarafından
                      gizlilik prensibine göre saklanacaktır. Bu bilgiler olası
                      banka ile oluşabilecek kredi kartından para çekim
                      itirazlarına karşı 60 gün süre ile bekletilip daha
                      sonrasında imha edilmektedir. Sipariş ettiğiniz ürünlerin
                      bedeli karşılığında bize göndereceğiniz tarafınızdan
                      onaylı mail-order formu bedeli dışında herhangi bir
                      bedelin kartınızdan çekilmesi halinde doğal olarak bankaya
                      itiraz edebilir ve bu tutarın ödenmesini
                      engelleyebileceğiniz için bir risk oluşturmamaktadır. 
                    </p>

                    <h3>Üçüncü Taraf Web Siteleri ve Uygulamalar</h3>
                    <p>
                      Mağazamız,  web sitesi dahilinde başka sitelere link
                      verebilir. Firmamız, bu linkler vasıtasıyla erişilen
                      sitelerin gizlilik uygulamaları ve içeriklerine yönelik
                      herhangi bir sorumluluk taşımamaktadır. Firmamıza ait
                      sitede yayınlanan reklamlar, reklamcılık yapan iş
                      ortaklarımız aracılığı ile kullanıcılarımıza dağıtılır. İş
                      bu sözleşmedeki Gizlilik Politikası Prensipleri, sadece
                      Mağazamızın kullanımına ilişkindir, üçüncü taraf web
                      sitelerini kapsamaz. 
                    </p>

                    <h3>İstisnai Haller</h3>
                    <p>
                      Aşağıda belirtilen sınırlı hallerde Firmamız, işbu
                      "Gizlilik Politikası" hükümleri dışında kullanıcılara ait
                      bilgileri üçüncü kişilere açıklayabilir. Bu durumlar
                      sınırlı sayıda olmak üzere;
                    </p>
                    <ul>
                      <li>
                        Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili
                        hukuki otorite tarafından çıkarılan ve yürürlülükte olan
                        hukuk kurallarının getirdiği zorunluluklara uymak;
                      </li>
                      <li>
                        Mağazamızın kullanıcılarla akdettiği "Üyelik
                        Sözleşmesi"'nin ve diğer sözleşmelerin gereklerini
                        yerine getirmek ve bunları uygulamaya koymak amacıyla;
                      </li>
                      <li>
                        Yetkili idari ve adli otorite tarafından usulüne göre
                        yürütülen bir araştırma veya soruşturmanın yürütümü
                        amacıyla kullanıcılarla ilgili bilgi talep edilmesi;
                      </li>
                      <li>
                        Kullanıcıların hakları veya güvenliklerini korumak için
                        bilgi vermenin gerekli olduğu hallerdir. 
                      </li>
                    </ul>

                    <h3>E-Posta Güvenliği</h3>
                    <p>
                      Mağazamızın Müşteri Hizmetleri’ne, herhangi bir
                      siparişinizle ilgili olarak göndereceğiniz e-postalarda,
                      asla kredi kartı numaranızı veya şifrelerinizi yazmayınız.
                      E-postalarda yer alan bilgiler üçüncü şahıslar tarafından
                      görülebilir. Firmamız e-postalarınızdan aktarılan
                      bilgilerin güvenliğini hiçbir koşulda garanti edemez.
                    </p>

                    <h3>Tarayıcı Çerezlerı</h3>

                    <p>
                      Firmamız, mağazamızı ziyaret eden kullanıcılar ve
                      kullanıcıların web sitesini kullanımı hakkındaki bilgileri
                      teknik bir iletişim dosyası (Çerez-Cookie) kullanarak elde
                      edebilir. Bahsi geçen teknik iletişim dosyaları, ana
                      bellekte saklanmak üzere bir internet sitesinin
                      kullanıcının tarayıcısına (browser) gönderdiği küçük metin
                      dosyalarıdır. Teknik iletişim dosyası site hakkında durum
                      ve tercihleri saklayarak İnternet'in kullanımını
                      kolaylaştırır.
                    </p>

                    <p>
                      Teknik iletişim dosyası,  siteyi kaç kişinin ziyaret
                      ettiğini, bir kişinin siteyi hangi amaçla, kaç kez ziyaret
                      ettiğini ve ne kadar sitede kaldıkları hakkında
                      istatistiksel bilgileri elde etmeye ve kullanıcılar için
                      özel tasarlanmış kullanıcı sayfalarından  dinamik olarak
                      reklam ve içerik üretilmesine yardımcı olur. Teknik
                      iletişim dosyası, ana bellekte veya e-postanızdan veri
                      veya başkaca herhangi bir kişisel bilgi almak için
                      tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik
                      iletişim dosyasını kabul eder biçimde tasarlanmıştır ancak
                      kullanıcılar dilerse teknik iletişim dosyasının gelmemesi
                      veya teknik iletişim dosyasının gönderildiğinde uyarı
                      verilmesini sağlayacak biçimde ayarları değiştirebilirler.
                    </p>

                    <p>
                      Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği
                      zaman sitede yayınlamak veya kullanıcılara elektronik
                      posta göndermek veya sitesinde yayınlamak suretiyle
                      değiştirebilir. Gizlilik Politikası hükümleri değiştiği
                      takdirde, yayınlandığı tarihte yürürlük kazanır.
                    </p>

                    <p>
                      Gizlilik politikamız ile ilgili her türlü soru ve
                      önerileriniz için&nbsp;
                      <a href={`mailto:${atob(encodedEmail)}`}>
                        {atob(encodedEmail)}
                      </a>
                      &nbsp;veya&nbsp;
                      <a href="mailto:info@mimarliksolo.com">
                        info@mimarliksolo.com
                      </a>
                      adresine email gönderebilirsiniz. Firmamız’a ait aşağıdaki
                      iletişim bilgilerinden ulaşabilirsiniz.
                    </p>

                    <p>
                      <b>Firma Ünvanı</b>: Solo Mimarlık
                      <br />
                      <b>Adres</b>: Cihangir Mahallesi Pürtelaş Sk. No:3A
                      Beyoğlu / İstanbul
                      <br />
                      <b>Eposta</b>: info@mimarliksolo.com
                      <br />
                      <b>Tel</b>: +90 212 871 75 16
                    </p>
                  </div>
                </div>
              </Collapsible>
              <Collapsible header="Distance Sale Agreement - Mesafeli̇ Satiş Sözleşmesi̇">
                <div
                  className="af-class-article-container"
                  style={{ maxWidth: "60vw" }}
                >
                  <div className="af-class-text-center">
                    <div className="af-class-article-date af-class-increased-margin">
                      Updated 20th of February, 2024
                    </div>
                    <h1 className="af-class-no-bottom-margin">
                      Mesafeli̇ Satiş Sözleşmesi
                    </h1>
                  </div>
                  <div className="af-class-article-body w-richtext">
                    <div class="post-item-inside">
                      <div class="post-item-content">
                        <p align="justify">
                          <span>1.TARAFLAR</span>
                        </p>
                        <p align="justify">
                          İşbu Sözleşme aşağıdaki taraflar arasında aşağıda
                          belirtilen hüküm ve şartlar çerçevesinde
                          imzalanmıştır.
                        </p>
                        <ol type="A">
                          <li>
                            <p>
                              ‘ALICI’ ; (sözleşmede bundan sonra "ALICI" olarak
                              anılacaktır)
                            </p>
                          </li>
                        </ol>
                        <p>
                          AD- SOYAD:
                          <br />
                          ADRES:
                        </p>
                        <ol type="A" start="2">
                          <li>
                            <p>
                              ‘SATICI’ ; (sözleşmede bundan sonra "SATICI"
                              olarak anılacaktır)
                            </p>
                          </li>
                        </ol>
                        <p>
                          AD- SOYAD:
                          <br />
                          ADRES:
                        </p>
                        <p align="justify">
                          İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu
                          siparişi onayladığı takdirde sipariş konusu bedeli ve
                          varsa kargo ücreti, vergi gibi belirtilen ek ücretleri
                          ödeme yükümlülüğü altına gireceğini ve bu konuda
                          bilgilendirildiğini peşinen kabul eder.
                        </p>
                        <p align="justify">
                          <span>2.TANIMLAR</span>
                        </p>
                        <p align="justify">
                          İşbu sözleşmenin uygulanmasında ve yorumlanmasında
                          aşağıda yazılı terimler karşılarındaki yazılı
                          açıklamaları ifade edeceklerdir.
                        </p>
                        <p align="justify">
                          BAKAN : Gümrük ve Ticaret Bakanı’nı,
                        </p>
                        <p align="justify">
                          BAKANLIK : Gümrük ve Ticaret Bakanlığı’nı,
                        </p>
                        <p align="justify">
                          KANUN : 6502 sayılı Tüketicinin Korunması Hakkında
                          Kanun’u,
                        </p>
                        <p align="justify">
                          YÖNETMELİK : Mesafeli Sözleşmeler Yönetmeliği’ni
                          (RG:27.11.2014/29188)
                        </p>
                        <p align="justify">
                          HİZMET : Bir ücret veya menfaat karşılığında yapılan
                          ya da yapılması taahhüt edilen mal sağlama dışındaki
                          her türlü tüketici işleminin konusunu ,
                        </p>
                        <p align="justify">
                          SATICI : Ticari veya mesleki faaliyetleri kapsamında
                          tüketiciye mal sunan veya mal sunan adına veya
                          hesabına hareket eden şirketi,
                        </p>
                        <p align="justify">
                          ALICI : Bir mal veya hizmeti ticari veya mesleki
                          olmayan amaçlarla edinen, kullanan veya yararlanan
                          gerçek ya da tüzel kişiyi,
                        </p>
                        <p align="justify">
                          SİTE : SATICI’ya ait internet sitesini,
                        </p>
                        <p align="justify">
                          SİPARİŞ VEREN: Bir mal veya hizmeti SATICI’ya ait
                          internet sitesi üzerinden talep eden gerçek ya da
                          tüzel kişiyi,
                        </p>
                        <p align="justify">TARAFLAR : SATICI ve ALICI’yı,</p>
                        <p align="justify">
                          SÖZLEŞME : SATICI ve ALICI arasında akdedilen işbu
                          sözleşmeyi,
                        </p>
                        <p align="justify">
                          MAL : Alışverişe konu olan taşınır eşyayı ve
                          elektronik ortamda kullanılmak üzere hazırlanan
                          yazılım, ses, görüntü ve benzeri gayri maddi malları
                          ifade eder.
                        </p>
                        <p align="justify">
                          <span>3.KONU</span>
                        </p>
                        <p align="justify">
                          İşbu Sözleşme, ALICI’nın, SATICI’ya ait internet
                          sitesi üzerinden elektronik ortamda siparişini verdiği
                          aşağıda nitelikleri ve satış fiyatı belirtilen ürünün
                          satışı ve teslimi ile ilgili olarak 6502 sayılı
                          Tüketicinin Korunması Hakkında Kanun ve Mesafeli
                          Sözleşmelere Dair Yönetmelik hükümleri gereğince
                          tarafların hak ve yükümlülüklerini düzenler.
                        </p>
                        <p align="justify">
                          Listelenen ve sitede ilan edilen fiyatlar satış
                          fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme
                          yapılana ve değiştirilene kadar geçerlidir. Süreli
                          olarak ilan edilen fiyatlar ise belirtilen süre sonuna
                          kadar geçerlidir.
                        </p>
                        <p align="justify">
                          <span>4. SATICI BİLGİLERİ</span>
                        </p>
                        <p align="justify">
                          Ünvanı
                          <br />
                          Adres
                          <br />
                          Telefon
                          <br />
                          Faks
                          <br />
                          Eposta
                        </p>
                        <p align="justify">
                          <span>5. ALICI BİLGİLERİ</span>
                        </p>
                        <p>
                          Teslim edilecek kişi
                          <br />
                          Teslimat Adresi
                          <br />
                          Telefon
                          <br />
                          Faks
                          <span>
                            <br />
                          </span>
                          Eposta/kullanıcı adı
                        </p>
                        <p align="justify">
                          <span>6. SİPARİŞ VEREN KİŞİ BİLGİLERİ</span>
                        </p>
                        <p align="justify">Ad/Soyad/Unvan</p>
                        <p align="justify">
                          Adres
                          <br />
                          Telefon
                          <br />
                          Faks
                          <br />
                          Eposta/kullanıcı adı
                        </p>
                        <p align="justify">
                          <span>7. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ</span>
                        </p>
                        <p align="justify">
                          <span>1.</span>&nbsp;Malın /Ürün/Ürünlerin/ Hizmetin
                          temel özelliklerini (türü, miktarı, marka/modeli,
                          rengi, adedi) SATICI’ya ait internet sitesinde
                          yayınlanmaktadır. Satıcı tarafından kampanya
                          düzenlenmiş ise ilgili ürünün temel özelliklerini
                          kampanya süresince inceleyebilirsiniz. Kampanya
                          tarihine kadar geçerlidir.
                        </p>
                        <p align="justify">
                          <span>7.2.</span>&nbsp;Listelenen ve sitede ilan
                          edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar
                          ve vaatler güncelleme yapılana ve değiştirilene kadar
                          geçerlidir. Süreli olarak ilan edilen fiyatlar ise
                          belirtilen süre sonuna kadar geçerlidir.
                        </p>
                        <p align="justify">
                          <span>7.3.</span>&nbsp;Sözleşme konusu mal ya da
                          hizmetin tüm vergiler dâhil satış fiyatı aşağıda
                          gösterilmiştir.
                        </p>
                        <p align="justify">
                          <br />
                        </p>
                        <table
                          width="100%"
                          cellpadding="0"
                          cellspacing="1"
                          bgcolor="#ffffff"
                        >
                          <tbody>
                            <tr>
                              <td width="52%" bgcolor="#ffffff">
                                <p align="justify">Ürün Açıklaması</p>
                              </td>
                              <td width="8%" bgcolor="#ffffff">
                                <p align="justify">Adet</p>
                              </td>
                              <td width="20%" bgcolor="#ffffff">
                                <p align="justify">Birim Fiyatı</p>
                              </td>
                              <td colspan="2" width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  Ara Toplam
                                  <br />
                                  (KDV Dahil)
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td width="52%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                              <td width="8%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                              <td width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                              <td colspan="2" width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td width="52%" bgcolor="#ffffff">
                                <p align="justify">Kargo Tutarı</p>
                              </td>
                              <td width="8%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                              <td width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                              <td colspan="2" width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" width="80%" bgcolor="#ffffff">
                                <p align="justify">
                                  <span>Toplam :</span>
                                </p>
                              </td>
                              <td width="20%" bgcolor="#ffffff">
                                <p align="justify">
                                  <br />
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p align="justify">
                          <br />
                        </p>
                        <p align="justify">Ödeme Şekli ve Planı</p>
                        <p align="justify">Teslimat Adresi</p>
                        <p align="justify">Teslim Edilecek kişi</p>
                        <p align="justify">Fatura Adresi</p>
                        <p align="justify">Sipariş Tarihi</p>
                        <p align="justify">Teslimat tarihi</p>
                        <p align="justify">Teslim şekli</p>
                        <p align="justify">
                          <br />
                        </p>
                        <p align="justify">
                          <span>7.4.</span>&nbsp; Ürün sevkiyat masrafı olan
                          kargo ücreti ALICI tarafından ödenecektir.
                        </p>
                        <p align="justify">
                          8<span>. FATURA BİLGİLERİ</span>
                        </p>
                        <p align="justify">Ad/Soyad/Unvan</p>
                        <p align="justify">
                          Adres
                          <br />
                          Telefon
                          <br />
                          Faks
                          <br />
                          Eposta/kullanıcı adı
                          <br />
                          Fatura teslim :Fatura sipariş teslimatı sırasında
                          fatura adresine sipariş ile birlikte&nbsp;
                          <br />
                          teslim edilecektir.
                        </p>
                        <p align="justify">
                          <span>9. GENEL HÜKÜMLER</span>
                        </p>
                        <p align="justify">
                          <span>9.1.</span>&nbsp;ALICI, SATICI’ya ait internet
                          sitesinde sözleşme konusu ürünün temel nitelikleri,
                          satış fiyatı ve ödeme şekli ile teslimata ilişkin ön
                          bilgileri okuyup, bilgi sahibi olduğunu, elektronik
                          ortamda gerekli teyidi verdiğini kabul, beyan ve
                          taahhüt eder. ALICI’nın; Ön Bilgilendirmeyi elektronik
                          ortamda teyit etmesi, mesafeli satış sözleşmesinin
                          kurulmasından evvel, SATICI tarafından ALICI' ya
                          verilmesi gereken adresi, siparişi verilen ürünlere
                          ait temel özellikleri, ürünlerin vergiler dâhil
                          fiyatını, ödeme ve teslimat bilgilerini de doğru ve
                          eksiksiz olarak edindiğini kabul, beyan ve taahhüt
                          eder.
                        </p>
                        <p align="justify">
                          <span>9.2.</span>&nbsp;Sözleşme konusu her bir ürün,
                          30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın
                          yerleşim yeri uzaklığına bağlı olarak internet
                          sitesindeki ön bilgiler kısmında belirtilen süre
                          zarfında ALICI veya ALICI’nın gösterdiği adresteki
                          kişi ve/veya kuruluşa teslim edilir. Bu süre içinde
                          ürünün ALICI’ya teslim edilememesi durumunda,
                          ALICI’nın sözleşmeyi feshetme hakkı saklıdır.
                        </p>
                        <p align="justify">
                          <span>9.3.</span>&nbsp;SATICI, Sözleşme konusu ürünü
                          eksiksiz, siparişte belirtilen niteliklere uygun ve
                          varsa garanti belgeleri, kullanım kılavuzları işin
                          gereği olan bilgi ve belgeler ile teslim etmeyi, her
                          türlü ayıptan arî olarak yasal mevzuat gereklerine
                          göre sağlam, standartlara uygun bir şekilde işi
                          doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi,
                          hizmet kalitesini koruyup yükseltmeyi, işin ifası
                          sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat
                          ve öngörü ile hareket etmeyi kabul, beyan ve taahhüt
                          eder.
                        </p>
                        <p align="justify">
                          <span>9.4.</span>&nbsp;SATICI, sözleşmeden doğan ifa
                          yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek
                          ve açıkça onayını almak suretiyle eşit kalite ve
                          fiyatta farklı bir ürün tedarik edebilir.
                        </p>
                        <p align="justify">
                          <span>9.5.</span>&nbsp;
                          <span>
                            SATICI, sipariş konusu ürün veya hizmetin yerine
                            getirilmesinin imkânsızlaşması halinde sözleşme
                            konusu yükümlülüklerini yerine getiremezse, bu
                            durumu, öğrendiği tarihten itibaren 3 gün içinde
                            yazılı olarak tüketiciye bildireceğini, 14 günlük
                            süre içinde toplam bedeli ALICI’ya iade edeceğini
                            kabul, beyan ve taahhüt eder.&nbsp;
                          </span>
                        </p>
                        <p align="justify">
                          <span>9.6.</span>&nbsp;ALICI, Sözleşme konusu ürünün
                          teslimatı için işbu Sözleşme’yi elektronik ortamda
                          teyit edeceğini, herhangi bir nedenle sözleşme konusu
                          ürün bedelinin ödenmemesi ve/veya banka kayıtlarında
                          iptal edilmesi halinde, SATICI’nın sözleşme konusu
                          ürünü teslim yükümlülüğünün sona ereceğini kabul,
                          beyan ve taahhüt eder.
                        </p>
                        <p align="justify">
                          <span>9.7.</span>&nbsp;ALICI, Sözleşme konusu ürünün
                          ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya
                          kuruluşa tesliminden sonra ALICI'ya ait kredi kartının
                          yetkisiz kişilerce haksız kullanılması sonucunda
                          sözleşme konusu ürün bedelinin ilgili banka veya
                          finans kuruluşu tarafından SATICI'ya ödenmemesi
                          halinde, ALICI Sözleşme konusu ürünü 3 gün içerisinde
                          nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya
                          iade edeceğini kabul, beyan ve taahhüt eder.
                        </p>
                        <p align="justify">
                          <span>9.8.</span>&nbsp;SATICI, tarafların iradesi
                          dışında gelişen, önceden öngörülemeyen ve tarafların
                          borçlarını yerine getirmesini engelleyici ve/veya
                          geciktirici hallerin oluşması gibi mücbir sebepler
                          halleri nedeni ile sözleşme konusu ürünü süresi içinde
                          teslim edemez ise, durumu ALICI'ya bildireceğini
                          kabul, beyan ve taahhüt eder. ALICI da siparişin iptal
                          edilmesini, sözleşme konusu ürünün varsa emsali ile
                          değiştirilmesini ve/veya teslimat süresinin
                          engelleyici durumun ortadan kalkmasına kadar
                          ertelenmesini SATICI’dan talep etme hakkını haizdir.
                          ALICI tarafından siparişin iptal edilmesi halinde
                          ALICI’nın nakit ile yaptığı ödemelerde, ürün tutarı 14
                          gün içinde kendisine nakden ve defaten ödenir.
                          ALICI’nın kredi kartı ile yaptığı ödemelerde ise, ürün
                          tutarı, siparişin ALICI tarafından iptal edilmesinden
                          sonra 14 gün içerisinde ilgili bankaya iade edilir.
                          ALICI, SATICI tarafından kredi kartına iade edilen
                          tutarın banka tarafından ALICI hesabına yansıtılmasına
                          ilişkin ortalama sürecin 2 ile 3 haftayı
                          bulabileceğini, bu tutarın bankaya iadesinden sonra
                          ALICI’nın hesaplarına yansıması halinin tamamen banka
                          işlem süreci ile ilgili olduğundan, ALICI, olası
                          gecikmeler için SATICI’yı sorumlu tutamayacağını
                          kabul, beyan ve taahhüt eder.
                        </p>
                        <p align="justify">
                          <span>9.9.</span>&nbsp;SATICININ, ALICI tarafından
                          siteye kayıt formunda belirtilen veya daha sonra
                          kendisi tarafından güncellenen adresi, e-posta adresi,
                          sabit ve mobil telefon hatları ve diğer iletişim
                          bilgileri üzerinden mektup, e-posta, SMS, telefon
                          görüşmesi ve diğer yollarla iletişim, pazarlama,
                          bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı
                          bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle
                          SATICI’nın kendisine yönelik yukarıda belirtilen
                          iletişim faaliyetlerinde bulunabileceğini kabul ve
                          beyan etmektedir.
                        </p>
                        <p align="justify">
                          <span>9.10.</span>&nbsp;ALICI, sözleşme konusu
                          mal/hizmeti teslim almadan önce muayene edecek; ezik,
                          kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
                          mal/hizmeti kargo şirketinden teslim almayacaktır.
                          Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu
                          kabul edilecektir. Teslimden sonra mal/hizmetin özenle
                          korunması borcu, ALICI’ya aittir. Cayma hakkı
                          kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura
                          iade edilmelidir.
                        </p>
                        <p align="justify">
                          <span>9.11.</span>&nbsp;ALICI ile sipariş esnasında
                          kullanılan kredi kartı hamilinin aynı kişi olmaması
                          veya ürünün ALICI’ya tesliminden evvel, siparişte
                          kullanılan kredi kartına ilişkin güvenlik açığı tespit
                          edilmesi halinde, SATICI, kredi kartı hamiline ilişkin
                          kimlik ve iletişim bilgilerini, siparişte kullanılan
                          kredi kartının bir önceki aya ait ekstresini yahut
                          kart hamilinin bankasından kredi kartının kendisine
                          ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan
                          talep edebilir. ALICI’nın talebe konu bilgi/belgeleri
                          temin etmesine kadar geçecek sürede sipariş
                          dondurulacak olup, mezkur taleplerin 24 saat
                          içerisinde karşılanmaması halinde ise SATICI, siparişi
                          iptal etme hakkını haizdir.
                        </p>
                        <p align="justify">
                          <span>9.12.</span>&nbsp;ALICI, SATICI’ya ait internet
                          sitesine üye olurken verdiği kişisel ve diğer sair
                          bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu
                          bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm
                          zararları, SATICI’nın ilk bildirimi üzerine derhal,
                          nakden ve defaten tazmin edeceğini beyan ve taahhüt
                          eder.
                        </p>
                        <p align="justify">
                          <span>9.13.</span>&nbsp;ALICI, SATICI’ya ait internet
                          sitesini kullanırken yasal mevzuat hükümlerine riayet
                          etmeyi ve bunları ihlal etmemeyi baştan kabul ve
                          taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve
                          cezai yükümlülükler tamamen ve münhasıran ALICI’yı
                          bağlayacaktır.
                        </p>
                        <p align="justify">
                          <span>9.14.</span>&nbsp;ALICI, SATICI’ya ait internet
                          sitesini hiçbir şekilde kamu düzenini bozucu, genel
                          ahlaka aykırı, başkalarını rahatsız ve taciz edici
                          şekilde, yasalara aykırı bir amaç için, başkalarının
                          maddi ve manevi haklarına tecavüz edecek şekilde
                          kullanamaz. Ayrıca, üye başkalarının hizmetleri
                          kullanmasını önleyici veya zorlaştırıcı faaliyet
                          (spam, virus, truva atı, vb.) işlemlerde bulunamaz.
                        </p>
                        <p align="justify">
                          <span>9.15.</span>&nbsp;SATICI’ya ait internet
                          sitesinin üzerinden, SATICI’nın kendi kontrolünde
                          olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu
                          ve/veya işlettiği başka web sitelerine ve/veya başka
                          içeriklere link verilebilir. Bu linkler ALICI’ya
                          yönlenme kolaylığı sağlamak amacıyla konmuş olup
                          herhangi bir web sitesini veya o siteyi işleten kişiyi
                          desteklememekte ve Link verilen web sitesinin içerdiği
                          bilgilere yönelik herhangi bir garanti niteliği
                          taşımamaktadır.
                        </p>
                        <p align="justify">
                          <span>9.16.</span>&nbsp;İşbu sözleşme içerisinde
                          sayılan maddelerden bir ya da birkaçını ihlal eden üye
                          işbu ihlal nedeniyle cezai ve hukuki olarak şahsen
                          sorumlu olup, SATICI’yı bu ihlallerin hukuki ve cezai
                          sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal
                          nedeniyle, olayın hukuk alanına intikal ettirilmesi
                          halinde, SATICI’nın üyeye karşı üyelik sözleşmesine
                          uyulmamasından dolayı tazminat talebinde bulunma hakkı
                          saklıdır.
                        </p>
                        <p align="justify">
                          <span>10. CAYMA HAKKI</span>
                        </p>
                        <p align="justify">
                          <span>10.1.</span>&nbsp;ALICI; mesafeli sözleşmenin
                          mal satışına ilişkin olması durumunda, ürünün
                          kendisine veya gösterdiği adresteki kişi/kuruluşa
                          teslim tarihinden itibaren 14 (on dört) gün
                          içerisinde, SATICI’ya bildirmek şartıyla hiçbir hukuki
                          ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe
                          göstermeksizin malı reddederek sözleşmeden cayma
                          hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli
                          sözleşmelerde ise, bu süre sözleşmenin imzalandığı
                          tarihten itibaren başlar. Cayma hakkı süresi sona
                          ermeden önce, tüketicinin onayı ile hizmetin ifasına
                          başlanan hizmet sözleşmelerinde cayma hakkı
                          kullanılamaz. Cayma hakkının kullanımından kaynaklanan
                          masraflar SATICI’ ya aittir.&nbsp;ALICI, iş bu
                          sözleşmeyi kabul etmekle, cayma hakkı konusunda
                          bilgilendirildiğini peşinen kabul eder.
                        </p>
                        <p align="justify">
                          <span>10.2.</span>&nbsp;Cayma hakkının kullanılması
                          için 14 (ondört) günlük süre içinde SATICI' ya iadeli
                          taahhütlü posta, faks veya eposta ile yazılı
                          bildirimde bulunulması ve ürünün işbu sözleşmede
                          düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler"
                          hükümleri çerçevesinde kullanılmamış olması şarttır.
                          Bu hakkın kullanılması halinde,&nbsp;
                        </p>
                        <p align="justify">
                          <span>a)</span>&nbsp;3. kişiye veya ALICI’ ya teslim
                          edilen ürünün faturası, (İade edilmek istenen ürünün
                          faturası kurumsal ise, iade ederken kurumun düzenlemiş
                          olduğu iade faturası ile birlikte gönderilmesi
                          gerekmektedir. Faturası kurumlar adına düzenlenen
                          sipariş iadeleri İADE FATURASI kesilmediği takdirde
                          tamamlanamayacaktır.)
                        </p>
                        <p align="justify">
                          <span>b)</span>&nbsp;İade formu,
                        </p>
                        <p align="justify">
                          <span>c)</span>&nbsp;İade edilecek ürünlerin kutusu,
                          ambalajı, varsa standart aksesuarları ile birlikte
                          eksiksiz ve hasarsız olarak teslim edilmesi
                          gerekmektedir.
                        </p>
                        <p align="justify">
                          <span>d)</span>&nbsp;SATICI, cayma bildiriminin
                          kendisine ulaşmasından itibaren en geç 10 günlük süre
                          içerisinde toplam bedeli ve ALICI’yı borç altına sokan
                          belgeleri ALICI’ ya iade etmek ve 20 günlük süre
                          içerisinde malı iade almakla yükümlüdür.
                        </p>
                        <p align="justify">
                          <span>e)</span>&nbsp;ALICI’ nın kusurundan kaynaklanan
                          bir nedenle malın değerinde bir azalma olursa veya
                          iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın
                          zararlarını tazmin etmekle yükümlüdür. Ancak cayma
                          hakkı süresi içinde malın veya ürünün usulüne uygun
                          kullanılması sebebiyle meydana gelen değişiklik ve
                          bozulmalardan ALICI sorumlu değildir.&nbsp;
                        </p>
                        <p align="justify">
                          <span>f)</span>&nbsp;Cayma hakkının kullanılması
                          nedeniyle SATICI tarafından düzenlenen kampanya limit
                          tutarının altına düşülmesi halinde kampanya kapsamında
                          faydalanılan indirim miktarı iptal edilir.
                        </p>
                        <p align="justify">
                          <span>11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</span>
                        </p>
                        <p align="justify">
                          ALICI’nın isteği veya açıkça kişisel ihtiyaçları
                          doğrultusunda hazırlanan ve geri gönderilmeye müsait
                          olmayan, iç giyim alt parçaları, mayo ve bikini
                          altları, makyaj malzemeleri, tek kullanımlık ürünler,
                          çabuk bozulma tehlikesi olan veya son kullanma tarihi
                          geçme ihtimali olan mallar, ALICI’ya teslim
                          edilmesinin ardından ALICI tarafından ambalajı
                          açıldığı takdirde iade edilmesi sağlık ve hijyen
                          açısından uygun olmayan ürünler, teslim edildikten
                          sonra başka ürünlerle karışan vedoğası gereği
                          ayrıştırılması mümkün olmayan ürünler, Abonelik
                          sözleşmesi kapsamında sağlananlar dışında, gazete ve
                          dergi gibi süreli yayınlara ilişkin mallar, Elektronik
                          ortamda anında ifa edilen hizmetler veya tüketiciye
                          anında teslim edilen&nbsp;gayrimaddi&nbsp;mallar,ile
                          ses veya görüntü kayıtlarının, kitap, dijital içerik,
                          yazılım programlarının, veri kaydedebilme ve veri
                          depolama cihazlarının, bilgisayar sarf malzemelerinin,
                          ambalajının ALICI tarafından açılmış olması halinde
                          iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma
                          hakkı süresi sona ermeden önce, tüketicinin onayı ile
                          ifasına başlanan hizmetlere ilişkin cayma hakkının
                          kullanılması daYönetmelik gereği mümkün değildir.
                        </p>
                        <p align="justify">
                          Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri,
                          mayo, bikini, kitap, kopyalanabilir yazılım ve
                          programlar, DVD, VCD, CD ve kasetler ile kırtasiye
                          sarf malzemeleri (toner, kartuş, şerit vb.) iade
                          edilebilmesi için ambalajlarının açılmamış,
                          denenmemiş, bozulmamış ve kullanılmamış olmaları
                          gerekir.
                        </p>
                        <p align="justify">
                          <br />
                          <br />
                        </p>
                        <p align="justify">
                          <br />
                          <br />
                        </p>
                        <p align="justify">
                          <span>12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</span>
                        </p>
                        <p align="justify">
                          ALICI, ödeme işlemlerini kredi kartı ile yaptığı
                          durumda temerrüde düştüğü takdirde, kart sahibi banka
                          ile arasındaki kredi kartı sözleşmesi çerçevesinde
                          faiz ödeyeceğini ve bankaya karşı sorumlu olacağını
                          kabul, beyan ve taahhüt eder. Bu durumda ilgili banka
                          hukuki yollara başvurabilir; doğacak masrafları ve
                          vekâlet ücretini ALICI’dan talep edebilir ve her
                          koşulda ALICI’nın borcundan dolayı temerrüde düşmesi
                          halinde, ALICI, borcun gecikmeli ifasından dolayı
                          SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini
                          kabul, beyan ve taahhüt eder
                        </p>
                        <p align="justify">
                          <span>13. YETKİLİ MAHKEME</span>
                        </p>
                        <p align="justify">
                          <span>
                            İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve
                            itirazlar,
                          </span>
                          aşağıdaki kanunda belirtilen parasal sınırlar
                          dâhilinde tüketicinin yerleşim yerinin bulunduğu veya
                          tüketici işleminin yapıldığı yerdeki tüketici
                          sorunları hakem heyetine veya tüketici mahkemesine
                          yapılacaktır. Parasal sınıra ilişkin bilgiler
                          aşağıdadır:&nbsp;
                        </p>
                        <p align="justify">
                          28/05/2014 tarihinden itibaren geçerli olmak üzere:
                        </p>
                        <p align="justify">
                          <span>a)&nbsp;</span>6502 sayılı Tüketicinin Korunması
                          Hakkında Kanun’un 68. Maddesi gereği değeri 2.000,00
                          (ikibin) TL’nin altında olan uyuşmazlıklarda ilçe
                          tüketici hakem heyetlerine,
                        </p>
                        <p align="justify">
                          b) Değeri 3.000,00(üçbin)TL’ nin altında bulunan
                          uyuşmazlıklarda il tüketici hakem heyetlerine,
                        </p>
                        <p align="justify">
                          c) Büyükşehir statüsünde bulunan illerde ise değeri
                          2.000,00 (ikibin) TL ile 3.000,00(üçbin)TL’ arasındaki
                          uyuşmazlıklarda il tüketici hakem heyetlerine başvuru
                          yapılmaktadır.
                          <br />
                          İşbu Sözleşme ticari amaçlarla yapılmaktadır.
                        </p>
                        <p align="justify">
                          <span>14. YÜRÜRLÜK</span>
                        </p>
                        <p align="justify">
                          ALICI, Site üzerinden verdiği siparişe ait ödemeyi
                          gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını
                          kabul etmiş sayılır. SATICI, siparişin gerçekleşmesi
                          öncesinde işbu sözleşmenin sitede ALICI tarafından
                          okunup kabul edildiğine dair onay alacak şekilde
                          gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür.
                        </p>
                        <p align="justify">SATICI:</p>
                        <p align="justify">ALICI:</p>
                        <p align="justify">TARİH:</p>
                        <p align="justify">
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapsible>
              <div className="af-class-fine-print-text">
                <br />
                <br />
                <p>
                  By making a purchase from L'SAE Studio, you agree to the
                  following terms and conditions:
                </p>

                <ul>
                  <li>
                    All product prices are subject to change without notice.
                  </li>
                  <li>
                    Colours of products may vary slightly due to differences in
                    monitor calibration.
                  </li>
                  <li>
                    Custom-made or personalized products may have longer lead
                    times.
                  </li>
                  <li>
                    L'SAE Studio is not responsible for delays in shipping
                    caused by factors beyond our control.
                  </li>
                  <li>
                    Any personal information provided will be handled in
                    accordance with our privacy policy.
                  </li>
                  <li>
                    L'SAE Studio reserves the right to refuse service to anyone
                    for any reason.
                  </li>
                  <li>
                    Product availability is not guaranteed, and items may be
                    discontinued without notice.
                  </li>
                </ul>

                <p>
                  For any questions or concerns regarding these terms and
                  conditions, please contact our customer support team at&nbsp;
                  <a href={`mailto:${atob(encodedEmail)}`}>
                    {atob(encodedEmail)}
                  </a>
                  &nbsp;or call us at&nbsp;
                  <a href={`tel:${atob(encodedPhone)}`}>{atob(encodedPhone)}</a>
                  .
                </p>

                <p>
                  We appreciate your business and look forward to serving your
                  art and interior product needs.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default PoliciesView;
