//
//  views/CheckoutView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { Navigation } from "../components/Navigation";
import { formatCurrency } from "../localisation/locale";
import { generateBasketId, resetCart } from "../state/reducers/cartSlice";

import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import basketSlice, {
  setBasket,
  setBillingAddress,
  setShippingAddress,
  setUser,
} from "../state/reducers/basketSlice";
// import Validate from "../components/Validate";
import Loading from "../components/Loading";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

// const retrievalPoint = "http://127.0.0.1:5001/lsae-86346/us-central1/pay/retrieve";
// const initPoint = "http://127.0.0.1:5001/lsae-86346/us-central1/pay/initialise";

// PRODUCTION ENDPOINTS
const initPoint =
  "https://us-central1-lsae-86346.cloudfunctions.net/pay/initialise";
const retrievalPoint =
  "https://us-central1-lsae-86346.cloudfunctions.net/pay/retrieve";
const cppPoint = "https://cpp.iyzipay.com/";

// SANDBOX ENDPOINTS
// const initPoint = "https://us-central1-lsae-86346.cloudfunctions.net/pay/sandbox-initialise";
// const retrievalPoint = "https://us-central1-lsae-86346.cloudfunctions.net/pay/sandbox-retrieve";
// const cppPoint = "https://sandbox-cpp.iyzipay.com/";

// TODO:
// Everything...
// - [x] handle if a user vists the page with no items in basket
// - [x] store user data
// - [x] generate basketid
// - [x] store token
// - [ ] firestore
// - [ ] ips
// - [ ] billing and shipping address states, validation; kinda works.. ?
// - [ ]
// - [ ]
// - [ ]

const CheckoutView = () => {
  const basketId = useSelector((state) => state.cart.basketId);
  const basketToken = useSelector((state) => state.basket.token);
  const cartItems = useSelector((state) => state.cart.products);
  // const shippingPrice = useSelector((state) => state.cart.shipping)
  // ? useSelector((state) => state.cart.shipping)
  // : 1;

  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showEmptyState, setShowEmptyState] = useState(false);

  // const [shippingMethods, setShippingMethods] = useState([
  //   { id: 'ShipEntegra', name: 'Standard Shipping', price: shippingPrice },
  // ]);

  // // Fetch Shipping Methods (replace with your actual data fetching)
  // useEffect(() => {
  //   // Example fetch - replace with your API call
  //   fetch("/api/shipping-methods")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data.length === 0) {
  //         setShowEmptyState(true);
  //       } else {
  //         setShippingMethods(data);
  //         // Pre-select first method if available
  //         setSelectedMethod(data[0].id); // Assuming each method has an 'id'
  //       }
  //     });
  // }, []);

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Assuming you have access to the cart state
  const { products } = useSelector((state) => state.cart);

  const MINIMUM_SHIPPING_THRESHOLD = 3;
  const FREE_SHIPPING_THRESHOLD = 50;
  // const CATEGORY_DISCOUNT = { Stopper: true }; // Add other categories as needed

  // Calculate shipping price (with category discounts)
  let shippingPrice = products.reduce((total, product) => {
    if (cartItems.some((item) => item.title.includes("Stopper"))) {
      return 3; 
    } else if (
      product.shipping >= MINIMUM_SHIPPING_THRESHOLD &&
      total < FREE_SHIPPING_THRESHOLD
    ) {
      return total + product.shipping; // Standard shipping for other items
    } else {
      return total;
    }
  }, 0);

  // Apply free shipping if total price is over the threshold
  const cartTotal = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity; // Multiply by quantity and accumulate
  }, 0);

  console.log("Cart total:", cartTotal);

  // Redefine shipping price based on the condition
  shippingPrice = cartTotal >= FREE_SHIPPING_THRESHOLD ? 0 : shippingPrice;

  console.log("Final shipping price:", shippingPrice);

  // const shippingPrice =
  //   cartItems && cartItems.some((item) => item.title.includes("Stopper"))
  //     ? 3
  //     : 0;

  // Initialize a state variables to track changes
  const [usdPrice, setUsdPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState(null);
  const [iyiCheckoutURL, setiyiCheckoutURL] = useState(null);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [policyAgreement, setPolicyAgreement] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstname: "",
    surname: "",
    gsm: "",
    shippingStreetAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingZipCode: "",
    shippingCountry: "",
    billingFullName: "",
    billingStreetAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    billingCountry: "",
    billingVAT: "",
    billingVATOffice: "",
  });

  function fullName(firstName, lastName) {
    return `${firstName} ${lastName}`;
  }

  // lol this doesn't make any sense. if they run javascript they run javascript..
  // TODO: find a better way?
  // Encode email and phone number
  const allErrors = Object.values(errors).filter((error) => error !== "");
  const encodedEmail = btoa("info@lsaestudio.com");
  const encodedPhone = btoa("+90 (212) 871 7516");
  const VAT = 0.2;
  const dispatch = useDispatch();
  const disabledPayments = false;

  // validate form data
  // const validateField = (fieldName, value) => {
  //   switch (fieldName) {
  //     case "email":
  //       return Validate.isNotEmpty(value) && Validate.isValidEmail(value)
  //         ? ""
  //         : "Invalid email address";
  //     case "firstname":
  //       return Validate.isNotEmpty(value) && Validate.isValidName(value)
  //         ? ""
  //         : "Invalid first name";
  //     case "surname":
  //       return Validate.isNotEmpty(value) && Validate.isValidName(value)
  //         ? ""
  //         : "Invalid surname";
  //     case "shippingStreetAddress":
  //       return Validate.isNotEmpty(value) && Validate.isValidAddress(value)
  //         ? ""
  //         : "Invalid shipping address";
  //     case "gsm":
  //       return Validate.isNotEmpty(value) && Validate.isValidPhoneNumber(value)
  //         ? ""
  //         : "Invalid phone number";
  //     case "shippingCity":
  //       return Validate.isNotEmpty(value) && Validate.isValidCity(value)
  //         ? ""
  //         : "Invalid city";
  //     case "state":
  //       return Validate.isValidState(value) ? "" : "Invalid state";
  //     case "zipCode":
  //       return Validate.isValidZipCode(value) ? "" : "Invalid zip code";
  //     // case "shippingCountry":
  //     //   return Validate.isValidCountry(value) ? "" : "Invalid country";
  //     case "vatNumber":
  //       return Validate.isValidVAT(value) ? "" : "Invalid VAT number";
  //     case "vatOffice":
  //       return Validate.isValidVATOffice(value) ? "" : "Invalid VAT office";
  //     // Add cases for other fields as needed
  //     // case "policyAgreement":
  //     //   return policyAgreement
  //     //     ? ""
  //     //     : "Please agree to the terms and conditions";
  //     default:
  //       // shhh..
  //       return "";
  //       return Validate.isValidGeneric(value) ? "" : "Invalid input";
  //   }
  // };

  const [isLoading, setIsLoading] = useState(false);
  const handlePlaceOrder = async () => {
    if (!policyAgreement) {
      alert("Please agree to the terms and conditions.");
      return;
    }

    // for (const fieldName in formData) {
    //   if (formData.hasOwnProperty(fieldName)) {
    //     const value = formData[fieldName];
    //     const errorMessage = validateField(fieldName, value);

    //     // If there is an error message, store it in the errors object
    //     if (errorMessage) {
    //       errors[fieldName] = errorMessage;
    //     }
    //   }
    // }

    // Check for any errors
    if (allErrors.length > 0) {
      // Handle errors (e.g., display error messages)
      console.log("Validation errors:", allErrors);
      alert(`Error(s) found: ${allErrors.join(", ")}`);
      console.log("returning");
      return;
    } else {
      // Proceed with placing the order
      console.log("No issues detected. Proceeding...");

      const basketItems = cartItems.map((item) => ({
        id: item.id,
        name: item.title,
        category1: item.category,
        itemType: "PHYSICAL",
        // price: parseFloat(item.price).toFixed(2),
        price: `${
          Math.round((usdPrice * item.price * item.quantity) / 100) * 100
        }`,
      }));

      console.log("basketItems:", basketItems);
      if (isSubmitting) return; // Prevent double clicks

      setIsSubmitting(true); // Start processing

      try {
        const payload = {
          paymentGroup: "PHYSICAL",
          paymentChannel: "WEB",
          conversationId: "12345",
          basketId: basketId,
          basketItems,
          buyer: {
            id: "buyer_id",
            name: formData.firstname,
            surname: formData.surname,
            identityNumber: "000000000000",
            email: formData.email,
            gsmNumber: formData.gsm,
            registrationAddress: formData.shippingStreetAddress,
            city: formData.shippingCity,
            zipCode: formData.shippingZipCode,
            country: "Turkey",
          },
          price: `${Math.round((usdPrice * Subtotal()) / 100) * 100}`,
          paidPrice: `${
            Math.round(
              (usdPrice * Subtotal() + usdPrice * shippingPrice) / 100
            ) * 100
          }`,
          shippingAddress: {
            contactName: fullName(formData.firstname, formData.surname),
            address: formData.shippingStreetAddress,
            zipCode: formData.shippingZipCode,
            city: formData.shippingCity,
            country: "Turkey",
          },
          ...(billingSameAsShipping
            ? {
                billingAddress: {
                  contactName: fullName(formData.firstname, formData.surname),
                  address: formData.shippingStreetAddress,
                  zipCode: formData.shippingZipCode,
                  city: formData.shippingCity,
                  country: "Turkey",
                },
              }
            : {
                billingAddress: {
                  contactName: fullName(formData.firstname, formData.surname),
                  address: formData.billingStreetAddress,
                  city: formData.billingCity,
                  zipCode: formData.billingZipCode,
                  country: "Turkey",
                },
              }),
        };

        console.log("payload", payload);

        const response = await axios.post(initPoint, payload);

        console.log("response", response);

        if (response.data.status && response.data.status === "failure") {
          const errorMessage =
            response.data.errorMessage || "Unknown error occurred";
          alert(
            `Error in processing the information you provided: ${errorMessage}`
          );
          return;
        }

        const paymentPageUrl = response.data.paymentPageUrl;
        const url = new URL(paymentPageUrl);
        const token = url.searchParams.get("token");
        localStorage.setItem("token", token);

        setiyiCheckoutURL(`https://ode.iyzico.com/?${token}`);
        // console.log("iyiCheckoutURL:", iyiCheckoutURL);

        // setToken(token);

        // console.log("URI Token:", token);
        // console.log(
        //   "iyiCheckoutURL:",
        //   `https://cpp.iyzipay.com/?token=${token}`
        // );

        // openNewWindow(`https://cpp.iyzipay.com/?token=${token}`); // Call the openNewWindow function after handling the place order
        setToken(token);

        console.log("URI Token:", token);
        console.log("iyiCheckoutURL:", `${cppPoint}?token=${token}`);

        // Redirect to the URL in the same window
        window.location.href = `${cppPoint}?token=${token}`;
        // navigate(`https://cpp.iyzipay.com/?token=${token}`);
      } catch (error) {
        console.error("Error making POST request:", error);
        // Handle error, show an alert or perform any other necessary actions
        alert(
          "An error occurred while processing your request. Please try again.",
          error
        );
      } finally {
        setIsSubmitting(false); // Finish processing
      }
    }
  };

  const openNewWindow = (url) => {
    if (!url) {
      // If token is not set, do nothing
      return;
    }

    const existingWindow = window.open("", "self");

    if (existingWindow) {
      existingWindow.location.href = url;
    } else {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        // For iOS devices, simply navigate to the URL in the same window
        window.location.href = url;
        // window.location.assign(url);
      } else {
        const windowFeatures =
          "width=600,height=1200,scrollbars=yes,resizable=yes";
        const newWindow = window.open(url, "self", windowFeatures);

        // const newWindow = myService.getUrl();
        // const windowReference = window.open();
        // windowReference.location = url;
        if (!newWindow) {
          console.error(
            "Unable to open the new window. Please check your browser settings."
          );
        }
      }
    }
  };

  // Function to update form data based on input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // const errorMessage = validateField(name, value);
    // setErrors({
    //   ...errors,
    //   [name]: errorMessage,
    // });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Functions to handle the toggle changes
  const handleBillingToggle = () => {
    setBillingSameAsShipping(!billingSameAsShipping);
    setIsChecked(!isChecked);

    // If billingSameAsShipping is true, update billing address with shipping address
    if (billingSameAsShipping) {
      setFormData((prevData) => ({
        ...prevData,
        billingFullName: fullName(prevData.firstname, prevData.surname),
        billingStreetAddress: prevData.shippingStreetAddress,
        billingCity: prevData.shippingCity,
        billingState: prevData.shippingState,
        billingZipCode: prevData.shippingZipCode,
        billingCountry: prevData.shippingCountry,
        billingVAT: prevData.billingVAT,
        billingVATOffice: prevData.billingVATOffice,
      }));
    } else {
      // If billingSameAsShipping is false, clear billing address
      setFormData((prevData) => ({
        ...prevData,
        billingFullName: "",
        billingStreetAddress: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
        billingCountry: "",
        billingVAT: "",
        billingVATOffice: "",
      }));
    }
  };

  // Function to handle the policy agreement checkbox changes
  const handlePolicyAgreementToggle = () => {
    setPolicyAgreement(!policyAgreement);
    // console.log("user agreed to policies");
  };

  const Subtotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * item.quantity; // Multiply by quantity
    });
    return total.toFixed(3); // Format to two decimal places
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Check if there are no items in the basket
    if (cartItems.length === 0) {
      console.log("nothing to see here.");
      // Redirect to the index page
      navigate("/");
    }

    setShippingMethods([
      {
        id: "ShipEntegra",
        name: "ShipEntegra Standard",
        price: `${
          shippingPrice === 0
            ? "FREE"
            : formatCurrency(
                Math.round(((usdPrice || 1) * shippingPrice) / 100) * 100,
                "tr"
              )
        }`,
      },
    ]);

    // Retrieve USD price from session storage
    const storedUsdPrice = localStorage.getItem("usdPrice");
    console.log("Stored USD Price:", storedUsdPrice);

    // Update state with the stored USD price
    setUsdPrice(storedUsdPrice ? JSON.parse(storedUsdPrice) : null);
    // setUsdPrice(storedUsdPrice ? <Exchange ask={1} from="TRY" to="USD"/> : null);

    // If basketId is null, dispatch generateBasketId action
    if (basketId === null) {
      dispatch(generateBasketId());
    }

    // Check if there's a token in local storage
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      // setToken(storedToken);
      // You may want to dispatch an action here or perform any other necessary actions
      // window.location.href = '/order-confirmation?token=' + storedToken;
      // navigate(`/order-confirmation?token=${storedToken}`);
      // dispatch(resetCart());
      // localStorage.removeItem("token");
    }

    // if (token) {
    //   <Loading />;
    //   console.log("Found token:", token, "Checking payment status...");

    //   const checkPaymentStatus = async () => {
    //     try {
    //       const response = await axios.post(retrievalPoint, {
    //         token: token,
    //       });

    //       console.log("Payment Status Response:", response);

    //       if (response.data.status && response.data.status === "success") {
    //         // Redirect to the payment confirmation page
    //         navigate(`/order-confirmation?token=${token}`);

    //         // Trigger action after the user is redirected
    //         console.log("User has been redirected, tidying up...");
    //         // localStorage.removeItem("token");
    //         dispatch(resetCart());
    //       }
    //     } catch (error) {
    //       console.error("Error checking payment status:", error);
    //       alert("An error occured. Please try again. Error:", error);
    //     }
    //   };

    //   const intervalId = setInterval(checkPaymentStatus, 10000);

    //   return () => clearInterval(intervalId);
    // }

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, [cartItems, navigate, token, iyiCheckoutURL]);

  return (
    <span>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <Announcement />
          <Navigation />
          <div className="af-class-section">
            <div className="af-class-main-container">
              <div
                data-node-type="commerce-checkout-form-container"
                data-wf-checkout-query
                data-wf-page-link-href-prefix
                className="w-commerce-commercecheckoutformcontainer af-class-checkout-form"
              >
                <div className="w-commerce-commercelayoutmain">
                  {disabledPayments ? (
                    <form
                      data-node-type="commerce-checkout-customer-info-wrapper"
                      className="w-commerce-commercecheckoutcustomerinfowrapper"
                    >
                      <div className="w-commerce-commercecheckoutblockheader af-class-block-header">
                        <h4 className="af-class-checkout-heading">Notice</h4>
                      </div>
                      <fieldset className="w-commerce-commercecheckoutblockcontent">
                        <p>
                          We apologize for any inconvenience, but we want to
                          inform you that our checkout system is currently
                          undergoing maintenance and is temporarily disabled. To
                          place an order or make a purchase, please don't
                          hesitate to reach out to our dedicated customer
                          support team. You can contact us via email at{" "}
                          <a href={`mailto:${atob(encodedEmail)}`}>
                            {atob(encodedEmail)}
                          </a>{" "}
                          or call us at{" "}
                          <a href={`tel:${atob(encodedPhone)}`}>
                            {atob(encodedPhone)}
                          </a>{" "}
                          . We appreciate your patience and understanding as we
                          work diligently to enhance your shopping experience.
                          Thank you for choosing L'SAE Studio, and we look
                          forward to assisting you with your order.
                        </p>
                      </fieldset>
                    </form>
                  ) : (
                    <div>
                      <form
                        data-node-type="commerce-checkout-customer-info-wrapper"
                        className="w-commerce-commercecheckoutcustomerinfowrapper"
                      >
                        <div className="w-commerce-commercecheckoutblockheader af-class-block-header">
                          <h4 className="af-class-checkout-heading">
                            Customer Info
                          </h4>
                          <div className="af-class-fine-print-text">
                            * Required
                          </div>
                        </div>
                        <fieldset className="w-commerce-commercecheckoutblockcontent">
                          {/* Email Input */}
                          <label
                            name="email"
                            htmlFor="email"
                            className="w-commerce-commercecheckoutlabel"
                          >
                            Email *
                          </label>
                          <input
                            type="text"
                            style={{
                              backgroundColor: errors.email ? "#ffe6e6" : "",
                              border: errors.email ? "1px solid #ff0000" : "",
                            }}
                            className="w-commerce-commercecheckoutemailinput"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                          <br />
                          {/* GSM Input */}
                          <label
                            htmlFor="gsm"
                            className="w-commerce-commercecheckoutlabel"
                          >
                            Phone Number *
                          </label>
                          <input
                            type="text"
                            style={{
                              backgroundColor: errors.gsm ? "#ffe6e6" : "",
                              border: errors.gsm ? "1px solid #ff0000" : "",
                            }}
                            className="w-commerce-commercecheckoutemailinput"
                            name="gsm"
                            value={formData.gsm}
                            onChange={handleInputChange}
                            required
                          />
                        </fieldset>
                      </form>
                      <form
                        data-node-type="commerce-checkout-shipping-address-wrapper"
                        className="w-commerce-commercecheckoutshippingaddresswrapper"
                      >
                        <div className="w-commerce-commercecheckoutblockheader af-class-block-header">
                          <h4 className="af-class-checkout-heading">
                            Shipping Address
                          </h4>
                          <div className="af-class-fine-print-text">
                            * Required
                          </div>
                        </div>

                        <fieldset className="w-commerce-commercecheckoutblockcontent">
                          <label className="w-commerce-commercecheckoutlabel">
                            Notice: Currently we only ship to addresses within
                            Turkey. If you are outside of Turkey, please{" "}
                            <a href="mailto:info@lsaestudio.com">contact us</a>{" "}
                            for a tailored checkout link.
                          </label>
                          <br />
                          {/* First Name Input */}
                          <div className="w-commerce-commercecheckoutrow">
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor="firstname"
                                className="w-commerce-commercecheckoutlabel"
                              >
                                First Name *
                              </label>
                              <input
                                type="text"
                                style={{
                                  backgroundColor: errors.firstname
                                    ? "#ffe6e6"
                                    : "",
                                  border: errors.firstname
                                    ? "1px solid #ff0000"
                                    : "",
                                }}
                                className="w-commerce-commercecheckoutshippingfullname"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            {/* Surname Input */}
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor="surname"
                                className="w-commerce-commercecheckoutlabel"
                              >
                                Last Name *
                              </label>
                              <input
                                type="text"
                                style={{
                                  backgroundColor: errors.surname
                                    ? "#ffe6e6"
                                    : "",
                                  border: errors.surname
                                    ? "1px solid #ff0000"
                                    : "",
                                }}
                                className="w-commerce-commercecheckoutshippingfullname"
                                name="surname"
                                value={formData.surname}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          {/* Street Address Input */}
                          <label
                            htmlFor="shippingStreetAddress"
                            className="w-commerce-commercecheckoutlabel"
                          >
                            Street Address *
                          </label>
                          <input
                            type="text"
                            style={{
                              backgroundColor: errors.shippingStreetAddress
                                ? "#ffe6e6"
                                : "",
                              border: errors.shippingStreetAddress
                                ? "1px solid #ff0000"
                                : "",
                            }}
                            className="w-commerce-commercecheckoutshippingstreetaddress"
                            name="shippingStreetAddress"
                            value={formData.shippingStreetAddress}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="w-commerce-commercecheckoutrow">
                            <div className="w-commerce-commercecheckoutcolumn">
                              {/* City Input */}
                              <label
                                htmlFor="shippingCity"
                                className="w-commerce-commercecheckoutlabel"
                              >
                                City *
                              </label>
                              <input
                                type="text"
                                style={{
                                  backgroundColor: errors.shippingCity
                                    ? "#ffe6e6"
                                    : "",
                                  border: errors.shippingCity
                                    ? "1px solid #ff0000"
                                    : "",
                                }}
                                className="w-commerce-commercecheckoutshippingcity"
                                name="shippingCity"
                                value={formData.shippingCity}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            {/* State/Province Input */}
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor="shippingState"
                                className="w-commerce-commercecheckoutlabel"
                              >
                                State/Province
                              </label>
                              <input
                                type="text"
                                style={{
                                  backgroundColor: errors.shippingState
                                    ? "#ffe6e6"
                                    : "",
                                  border: errors.shippingState
                                    ? "1px solid #ff0000"
                                    : "",
                                }}
                                className="w-commerce-commercecheckoutshippingstateprovince"
                                name="shippingState"
                                onChange={handleInputChange}
                                value={formData.shippingState}
                              />
                            </div>
                            {/* Zip/Postal Code Input */}
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor="shippingZipCode"
                                className="w-commerce-commercecheckoutlabel"
                              >
                                Zip/Postal Code
                              </label>
                              <input
                                name="shippingZipCode"
                                type="text"
                                style={{
                                  backgroundColor: errors.shippingZipCode
                                    ? "#ffe6e6"
                                    : "",
                                  border: errors.shippingZipCode
                                    ? "1px solid #ff0000"
                                    : "",
                                }}
                                data-node-type="commerce-checkout-shipping-zip-field"
                                className="w-commerce-commercecheckoutshippingzippostalcode"
                                // name="shippingZipCode"
                                onChange={handleInputChange}
                                value={formData.shippingZipCode}
                              />
                            </div>
                          </div>
                          {/* Country Input */}
                          <label
                            htmlFor="shippingCountry"
                            className="w-commerce-commercecheckoutlabel"
                          >
                            Country *
                          </label>
                          <select
                            className="w-commerce-commercecheckoutshippingcountryselector"
                            // name="shippingCountry"
                            // id="shippingCountry"
                            // onChange={handleInputChange}
                            // value={formData.shippingCountry}
                          >
                            <option value="TR">Turkey</option>
                          </select>
                          {/* <input
                            type="text"
                            style={{
                              backgroundColor: errors.shippingStreetAddress
                                ? "#ffe6e6"
                                : "",
                              border: errors.shippingStreetAddress
                                ? "1px solid #ff0000"
                                : "",
                            }}
                            className="w-commerce-commercecheckoutbillingcity"
                            name="shippingCountry"
                            id="shippingCountry"
                            onChange={handleInputChange}
                            value={formData.shippingCountry}
                            required
                          /> */}
                          <br />

                          <div className="w-commerce-commercecheckoutrow">
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor
                                className="w-commerce-commercecheckoutlabel"
                              >
                                VAT no / TC Kimlik / Vergi No
                              </label>
                              <input
                                type="text"
                                className="w-commerce-commercecheckoutbillingcity"
                                name="billingVAT"
                                value={formData.billingVAT}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="w-commerce-commercecheckoutcolumn">
                              <label
                                htmlFor
                                className="w-commerce-commercecheckoutlabel"
                              >
                                Vergi Dairesi / Tax Office
                              </label>
                              <input
                                type="text"
                                className="w-commerce-commercecheckoutbillingstateprovince"
                                name="billingVATOffice"
                                value={formData.billingVATOffice}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <label
                            className={`w-checkbox selectable-field ${
                              isChecked ? "w--redirected-checked" : ""
                            }`}
                          >
                            <div
                              className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                                isChecked ? "w--redirected-checked" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                id="checkbox-2"
                                name="checkbox-2"
                                data-name="Checkbox 2"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                                checked={isChecked}
                                onChange={handleBillingToggle}
                              />
                            </div>
                            <div className="w-commerce-commercecheckoutbillingaddresstogglewrapper">
                              <label htmlFor="billing-address-toggle">
                                Billing address same as shipping
                                <input
                                  type="checkbox"
                                  id="billing-address-toggle"
                                  checked={billingSameAsShipping}
                                  onChange={handleBillingToggle}
                                />
                              </label>
                            </div>
                          </label>
                        </fieldset>
                      </form>
                      {billingSameAsShipping ? null : (
                        <form
                          data-node-type="commerce-checkout-billing-address-wrapper"
                          className="w-commerce-commercecheckoutbillingaddresswrapper"
                        >
                          <div className="w-commerce-commercecheckoutblockheader af-class-block-header">
                            <h4 className="af-class-checkout-heading">
                              Billing Address
                            </h4>
                            <div className="af-class-fine-print-text">
                              * Required
                            </div>
                          </div>
                          <fieldset className="w-commerce-commercecheckoutblockcontent">
                            <label
                              htmlFor
                              className="w-commerce-commercecheckoutlabel"
                            >
                              Full Name *
                            </label>
                            <input
                              type="text"
                              className="w-commerce-commercecheckoutbillingfullname"
                              name="billingFullName"
                              value={formData.billingFullName}
                              onChange={handleInputChange}
                              required
                            />
                            <label
                              htmlFor
                              className="w-commerce-commercecheckoutlabel"
                            >
                              Street Address *
                            </label>
                            <input
                              type="text"
                              className="w-commerce-commercecheckoutbillingstreetaddress"
                              name="billingStreetAddress"
                              value={formData.billingStreetAddress}
                              onChange={handleInputChange}
                              required
                            />
                            <div className="w-commerce-commercecheckoutrow">
                              <div className="w-commerce-commercecheckoutcolumn">
                                <label
                                  htmlFor
                                  className="w-commerce-commercecheckoutlabel"
                                >
                                  City *
                                </label>
                                <input
                                  type="text"
                                  className="w-commerce-commercecheckoutbillingcity"
                                  name="billingCity"
                                  value={formData.billingCity}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="w-commerce-commercecheckoutcolumn">
                                <label
                                  htmlFor
                                  className="w-commerce-commercecheckoutlabel"
                                >
                                  State/Province
                                </label>
                                <input
                                  type="text"
                                  className="w-commerce-commercecheckoutbillingstateprovince"
                                  name="billingState"
                                  value={formData.billingState}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="w-commerce-commercecheckoutcolumn">
                                <label
                                  htmlFor
                                  className="w-commerce-commercecheckoutlabel"
                                >
                                  Zip/Postal Code
                                </label>
                                <input
                                  type="text"
                                  data-node-type="commerce-checkout-billing-zip-field"
                                  className="w-commerce-commercecheckoutbillingzippostalcode"
                                  name="billingZipCode"
                                  value={formData.billingZipCode}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <label
                              htmlFor
                              className="w-commerce-commercecheckoutlabel"
                            >
                              Country *
                            </label>
                            <select
                              className="w-commerce-commercecheckoutshippingcountryselector"
                              name="billingCountry"
                              // value={formData.billingCountry}
                              // onChange={handleInputChange}
                            >
                              <option selected value="TR">
                                Turkey
                              </option>
                            </select>
                            <br />
                          </fieldset>
                        </form>
                      )}
                      <form className="w-commerce-commercecheckoutshippingmethodswrapper">
                        <div className="w-commerce-commercecheckoutblockheader af-class-block-header">
                          <h4 className="af-class-checkout-heading">
                            Shipping Method
                          </h4>
                        </div>
                        <fieldset>
                          {showEmptyState ? (
                            <div className="w-commerce-commercecheckoutshippingmethodsemptystate">
                              No shipping methods are available for the address
                              given.
                            </div>
                          ) : (
                            <div>
                              {shippingMethods.map((method, idx) => (
                                <label
                                  key={idx} // Use the index as the key
                                  className="w-commerce-commercecheckoutshippingmethoditem"
                                >
                                  {/* <input
                                    type="radio"
                                    required
                                    // defaultChecked={idx === 1}
                                    name="shipping-method-choice"
                                    value={method.id}
                                    checked={selectedMethod === method.id}
                                    onChange={handleMethodChange}
                                  /> */}
                                  <div className="w-commerce-commercecheckoutshippingmethoddescriptionblock">
                                    <div
                                      className="w-commerce-commerceboldtextblock"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>{method.name}</span>
                                      <span style={{ textAlign: "right" }}>
                                        {/* {method.price} */}
                                        {shippingPrice === 0
                                          ? "FREE"
                                          : formatCurrency(
                                              Math.round(
                                                ((usdPrice || 1) *
                                                  shippingPrice) /
                                                  100
                                              ) * 100,
                                              "tr"
                                            )}
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              ))}
                            </div>
                          )}
                        </fieldset>
                      </form>
                    </div>
                  )}
                  <div className="w-commerce-commercecheckoutorderitemswrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                      <h4 className="af-class-checkout-heading">
                        Items in Order
                      </h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div
                        role="list"
                        className="w-commerce-commercecheckoutorderitemslist"
                        data-wf-template-id="wf-template-619d8e8b85c224cbfbc36b42000000000086"
                      >
                        {cartItems &&
                          cartItems?.map((data, idx) => (
                            <div
                              key={idx}
                              role="listitem"
                              class="w-commerce-commercecheckoutorderitem"
                            >
                              <img
                                alt={`basket item ${idx}: ${data.title}, Qty: ${data.quantity}`}
                                src={data.img}
                                class="w-commerce-commercecartitemimage"
                              />
                              <div class="w-commerce-commercecheckoutorderitemdescriptionwrapper">
                                <div class="w-commerce-commerceboldtextblock">
                                  {data.title}
                                </div>
                                <div class="w-commerce-commercecheckoutorderitemquantitywrapper">
                                  <div>Quantity: </div>
                                  <div>{data.quantity}</div>
                                </div>
                                <ul class="w-commerce-commercecheckoutorderitemoptionlist"></ul>
                              </div>
                              <div>{formatCurrency(data.price)}</div>
                            </div>
                          ))}
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="w-commerce-commercelayoutsidebar af-class-checkout-sidebar">
                  <div className="w-commerce-commercecheckoutordersummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                      <h4 className="af-class-checkout-heading">
                        Order Summary
                      </h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>Subtotal</div>
                        <div>
                          {/* Always show the TRY price first, with a separator if usdPrice exists */}
                          {formatCurrency(
                            Math.round(
                              ((usdPrice || 1) *
                                (Subtotal() - VAT * Subtotal())) /
                                100
                            ) * 100,
                            "tr"
                          )}
                          {/* {usdPrice !== null &&
                            ` / ${formatCurrency(
                              Subtotal() - VAT * Subtotal()
                            )}`} */}
                        </div>
                      </div>
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>Shipping</div>
                        <div>
                          {shippingPrice === 0
                            ? "FREE"
                            : formatCurrency(
                                Math.round(
                                  ((usdPrice || 1) * shippingPrice) / 100
                                ) * 100,
                                "tr"
                              )}
                          {/* If usdPrice is not null and shipping isn't free, show original price with separator */}
                          {/* {usdPrice !== null &&
                            shippingPrice !== 0 &&
                            ` / ${formatCurrency(shippingPrice)}`} */}
                        </div>
                      </div>
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>VAT</div>
                        <div>
                          {formatCurrency(
                            Math.round(
                              ((usdPrice || 1) * (Subtotal() * VAT)) / 100
                            ) * 100,
                            "tr"
                          )}
                          {/* {usdPrice !== null &&
                            ` / ${formatCurrency(Subtotal() * VAT)}`} */}
                        </div>
                      </div>
                      <div className="w-commerce-commercecheckoutordersummaryextraitemslist">
                        <div className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                          <div />
                          <div />
                        </div>
                      </div>
                      <div className="w-commerce-commercecheckoutsummarylineitem">
                        <div>Total</div>
                        <div className="w-commerce-commercecheckoutsummarytotal">
                          {formatCurrency(
                            Math.round(
                              ((usdPrice || 1) *
                                (parseFloat(shippingPrice) +
                                  parseFloat(Subtotal()))) /
                                100
                            ) * 100,
                            "tr"
                          )}
                          {/* {usdPrice !== null &&
                            ` / ${formatCurrency(
                              parseFloat(shippingPrice) + parseFloat(Subtotal())
                            )}`} */}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="w-commerce-commercecheckoutordersummarywrapper">
                    <div className="w-commerce-commercecheckoutsummaryblockheader af-class-block-header">
                      <h4 className="af-class-checkout-heading">Voucher</h4>
                    </div>
                    <fieldset className="w-commerce-commercecheckoutblockcontent">
                      <input
                        type="text"
                        className="w-commerce-commercecheckoutemailinput"
                        name="voucher"
                        // required
                        placeholder="Voucher Code"
                      />
                      <br />
                      <a
                        href="#"
                        value="Apply"
                        data-node-type="commerce-checkout-place-order-button"
                        className={`w-commerce-commercecheckoutplaceorderbutton af-class-button af-class-bg-primary-light your-button`}
                        data-loading-text="Redirecting..."
                        onClick={async (e) => {
                          // handlePlaceOrder
                        }}
                      >
                        Apply
                      </a>
                    </fieldset>
                  </div>
                  <PolicyAgreementCheckbox
                    checked={policyAgreement}
                    onChange={handlePolicyAgreementToggle}
                  />
                  <a
                    href="#"
                    value="Proceed to Payment"
                    data-node-type="commerce-checkout-place-order-button"
                    className="w-commerce-commercecheckoutplaceorderbutton af-class-button af-class-bg-primary-dark"
                    data-loading-text="Redirecting..."
                    onClick={handlePlaceOrder}
                    disabled={isSubmitting} // Disable while processing
                    aria-busy={isSubmitting} // For accessibility
                  >
                    {isSubmitting ? "Redirecting..." : "Proceed to Payment"}
                  </a>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="/images/logo_band_colored@1X.png"
                      style={{ width: "80%", filter: "grayscale(100%)" }}
                    />
                  </div>
                  {/* Display all errors in one div */}
                  {allErrors.length > 0 && (
                    <div style={{ color: "#ff0000" }}>
                      <br />
                      <ul>
                        <text>Please correct the following errors:</text>
                        {allErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div
                    data-node-type="commerce-checkout-error-state"
                    style={{ display: "none" }}
                    className="w-commerce-commercecheckouterrorstate"
                  >
                    <div
                      aria-live="polite"
                      className="w-checkout-error-msg"
                      data-w-info-error="There was an error processing your customer info. Please try again, or contact us if you continue to have problems."
                      data-w-shipping-error="Sorry. We can't ship your order to the address provided."
                      data-w-billing-error="Your payment could not be completed with the payment information provided. Please make sure that your card and billing address information is correct, or try a different payment card, to complete this order. Contact us if you continue to have problems."
                      data-w-payment-error="There was an error processing your payment. Please try again, or contact us if you continue to have problems."
                      data-w-pricing-error="The prices of one or more items in your cart have changed. Please refresh this page and try again."
                      data-w-minimum-error="The order minimum was not met. Add more items to your cart to continue."
                      data-w-extras-error="A merchant setting has changed that impacts your cart. Please refresh and try again."
                      data-w-product-error="One or more of the products in your cart have been removed. Please refresh the page and try again."
                      data-w-invalid-discount-error="This discount is invalid."
                      data-w-expired-discount-error="This discount is no longer available."
                      data-w-usage-reached-discount-error="This discount is no longer available."
                      data-w-requirements-not-met-error="Your order does not meet the requirements for this discount."
                    >
                      There was an error processing your customer info. Please
                      try again, or contact us if you continue to have problems.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

const PolicyAgreementCheckbox = ({ checked, onChange }) => (
  <label className={`w-checkbox selectable-field`}>
    <div
      className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
        checked ? "w--redirected-checked" : ""
      }`}
    >
      <input
        type="checkbox"
        id="policy-agreement-checkbox"
        name="policy-agreement-checkbox"
        checked={checked}
        onChange={onChange}
        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
      />
    </div>
    <div className="w-commerce-commercecheckoutbillingaddresstogglewrapper">
      <label htmlFor="policy-agreement-checkbox">
        <span>I agree to</span>{" "}
        <a target="_blank" href="/policies">
          Terms & Conditions
        </a>
      </label>
    </div>
  </label>
);

export default CheckoutView;

/* eslint-enable */
