//
//  components/Navigation.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Cart from "./Cart";
import { closeCart, openCart } from "../state/reducers/cartSlice";

const scripts = [
  {
    loading: fetch("/js/jquery-3.5.1.min.dc5e7f18c8.js").then((body) =>
      body.text()
    ),
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};
const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

export function Navigation() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const location = useLocation(); // Get the current page's URL

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    // Navigate to the search page with the search query as a parameter
    navigate(`/search/${searchQuery}`);
  };

  // Toggle the cart open/close
  const handleToggleCart = () => {
    if (isCartOpen) {
      dispatch(closeCart()); // Close the cart if it's open
    } else {
      dispatch(openCart()); // Open the cart if it's closed
    }
  };

  const Categories = useSelector(
    (state) => state.dynamicData.data?.Categories || []
  );

  const cartItems = useSelector((state) => state.cart.products);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);

  // DEBUG: log locale
  // console.log('Navigation locale:', locale); // Add this line to log the current locale array

  const navigate = useNavigate();

  useEffect(() => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []); // Empty dependency array to mimic componentDidMount

  return (
    <span>
      <div className="af-class-nav-mobile">
        <div id="Top" className="af-class-top" />
        <div
          data-collapse="small"
          data-animation="default"
          data-duration={400}
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="af-class-navbar-hamburger w-nav"
        >
          <div className="af-class-main-container">
            <div className="af-class-navbar-top">
              <a
                href="/"
                aria-current="page"
                className={`w-inline-block ${
                  location.pathname === "/" ? "w--current" : ""
                }`} // Check if current page is the home page
              >
                <img
                  alt=""
                  src="/images/L_SAE-Logo-04.svg"
                  loading="eager"
                  className="af-class-image"
                  // style={{maxWidth: '380px',
                  //   height: '120px'}}
                />
              </a>
              <div
                id="w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf413-f6abf40a"
                className="af-class-navbar-right-contents"
              >
                <div
                  data-node-type="commerce-cart-wrapper"
                  data-open-product
                  data-wf-cart-type="rightSidebar"
                  data-wf-cart-query
                  data-wf-page-link-href-prefix
                  className="w-commerce-commercecartwrapper af-class-cart"
                >
                  <a
                    onClick={handleToggleCart}
                    href="#"
                    data-node-type="commerce-cart-open-link"
                    className="w-commerce-commercecartopenlink af-class-nav-cart-button w-inline-block"
                    role="button"
                    aria-haspopup="dialog"
                    aria-label="Open cart"
                  >
                    <img
                      alt=""
                      src="/images/icon-shopping-bag.svg"
                      loading="eager"
                    />
                    <div className="w-commerce-commercecartopenlinkcount af-class-cart-quantity">
                      {cartItems.length}
                    </div>
                  </a>
                </div>
                <div className="af-class-nav-button-menu af-class-bg-primary af-class-wide w-nav-button">
                  <img
                    alt=""
                    src="/images/hamburger.png"
                    loading="eager"
                    className="af-class-image-2"
                  />
                </div>
              </div>
              <form
                onSubmit={handleSubmit} // Attach the onSubmit handler
                id="w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf40d-f6abf40a"
                className="af-class-nav-search-form af-class-nav-search-mobile w-form"
              >
                <img alt="" src="/images/icon-search.svg" loading="eager" />
                <input
                  type="search"
                  className="af-class-search-form-input w-input"
                  maxLength={256}
                  name="query"
                  placeholder="Search here"
                  id="search-2"
                  required
                  value={searchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
                <input
                  type="submit"
                  className="af-class-hide-button w-button"
                  value={searchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
              </form>
            </div>
          </div>
          <nav role="navigation" className="af-class-nav-menu w-nav-menu">
            <div className="af-class-nav-menu-row">
              <Link
                onClick={handleScroll}
                to="/shop"
                className="af-class-nav-link"
              >
                All
              </Link>
              <div className="w-dyn-list">
                <div
                  role="list"
                  className="af-class-navbar-categories w-dyn-items"
                >
                  {Categories &&
                    Categories?.map((data, idx) => (
                      <div key={idx} role="listitem" className="w-dyn-item">
                        <Link
                          onClick={handleScroll}
                          to={`/category/${data.slug?.data.toLowerCase()}`}
                          className={`af-class-nav-link ${
                            location.pathname ===
                            `/category/${data.slug?.data.toLowerCase()}`
                              ? "w--current"
                              : ""
                          }`}
                        >
                          {data.name?.data}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
              <div className="af-class-nav-menu-divider" />
              <Link
                onClick={handleScroll}
                to="/about"
                className="af-class-nav-link"
              >
                About
              </Link>
              <Link
                onClick={handleScroll}
                to="/contact"
                className="af-class-nav-link"
              >
                Contact
              </Link>
            </div>
          </nav>
        </div>
      </div>
      <div className="af-class-div-block">
        <div className="af-class-nav-container">
          <div
            data-collapse="small"
            data-animation="default"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="af-class-navbar w-nav"
          >
            <div
              data-w-id="4083f8b5-73ff-ed19-fb59-25ba35d0ec78"
              className="af-class-nav-button-menu af-class-bg-primary af-class-wide w-nav-button"
            >
              <div className="af-class-text-block">Menu</div>
            </div>
            <nav role="navigation" className="w-nav-menu">
              <div className="af-class-nav-menu-row">
                <Link
                  onClick={handleScroll}
                  to="/shop"
                  className="af-class-nav-link"
                >
                  All
                </Link>
                <div className="w-dyn-list">
                  <div
                    role="list"
                    className="af-class-navbar-categories w-dyn-items"
                  >
                    {Categories &&
                      Categories?.map((data, idx) => (
                        <div key={idx} role="listitem" className="w-dyn-item">
                          <Link
                            onClick={handleScroll}
                            to={`/category/${data.slug?.data.toLowerCase()}`}
                            className="af-class-nav-link"
                          >
                            {data.name?.data}
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="af-class-nav-menu-divider" />
                <Link
                  onClick={handleScroll}
                  to="/about"
                  className="af-class-nav-link"
                >
                  About
                </Link>
                <Link
                  onClick={handleScroll}
                  to="/contact"
                  className="af-class-nav-link"
                >
                  Contact
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {isCartOpen && <Cart />}
    </span>
  );
}
