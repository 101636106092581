/* eslint-disable no-useless-escape */

//
//  views/IndexView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { AsyncImage } from "loadable-image";

import { Navigation } from "../components/Navigation";
import { formatCurrency } from "../localisation/locale";

// import Marquee from "../components/Marquee";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import SEO from "../components/SEO";

import "../styles/index";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

// TODO:
// - [x] featured; check bool
// - [x] just arrived; sort by date
// - [x] add checks for data and substitute them

// TODO: move this to database ..
// The higher the weight, the more likely it is to be chosen.
const collections = [
  {
    title: "The Arali Collection",
    image: "/images/paintings-hero.png",
    description:
      "Discover the latest in our Paintings Collection and elevate your style",
    link: "/category/paintings",
    weight: 2,
    action: "Start Browsing",
  },
  {
    title: "The Misnar Collection",
    image: "/images/hooks-hero.jpg",
    description:
      "Discover the latest in our Hooks Collection and elevate your style",
    link: "/category/hooks",
    weight: 2,
    action: "Start Browsing",
  },
  {
    title: "The Batu-Suvaie Collection",
    image: "/images/lights-hero.jpg",
    description:
      "Discover the latest in our Lights Collection and elevate your style",
    link: "/category/furniture",
    weight: 1,
    action: "Start Browsing",
  },
  {
    title: "The New Stoppers Collection",
    image: "/images/stoppers-hero.png",
    description:
      "Discover our latest Stoppers Collection and elevate your style",
    link: "/category/stoppers",
    weight: 3,
    action: "Order Now",
  },
  {
    title: "The New Stoppers Collection",
    image: "/images/1234.jpeg",
    description:
      "Discover our latest Stoppers Collection and elevate your style",
    link: "/category/stoppers",
    weight: 3,
    action: "Order Now",
  },
  {
    title: "The New Stoppers Collection",
    image: "/images/345.jpg",
    description:
      "Discover our latest Stoppers Collection and elevate your style",
    link: "/category/stoppers",
    weight: 3,
    action: "Order Now",
  },
  {
    title: "The New Stoppers Collection",
    image: "/images/456.jpg",
    description:
      "Discover our latest Stoppers Collection and elevate your style",
    link: "/category/stoppers",
    weight: 3,
    action: "Order Now",
  },
];

const IndexView = () => {
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [basketCounts, setBasketCounts] = useState({});

  useEffect(() => {
    const fetchBasketData = async () => {
      const storedData = localStorage.getItem('basketCounts');
      const storedTimestamp = localStorage.getItem('basketCountsTimestamp');

      if (
        storedData &&
        storedTimestamp &&
        Date.now() - storedTimestamp < 6 * 60 * 60 * 1000 // 6 hours in milliseconds
      ) {
        console.log("Using cached basket counts...");
        setBasketCounts(JSON.parse(storedData));
      } else {
        try {
          console.log("Fetching basket counts...");
          const res = await fetch("https://us-central1-lsae-86346.cloudfunctions.net/cart/counts");
          const data = await res.json();
          setBasketCounts(data);
          localStorage.setItem('basketCounts', JSON.stringify(data));
          localStorage.setItem('basketCountsTimestamp', Date.now());
        } catch (error) {
          console.error('Error fetching basket counts:', error);
          // Handle error (e.g., show a user-friendly message)
        }
      }
    };

    fetchBasketData();

  }, []);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    // Navigate to the search page with the search query as a parameter
    navigate(`/search/${searchQuery}`);
  };

  const navigate = useNavigate();

  const [currentCollection, setCurrentCollection] = useState({});
  const latestProducts = useSelector((state) => {
    // Filter products where the 'inventory' field is not '0'
    const products =
      state.dynamicData.data?.Products.filter(
        (product) => product.inventory?.data !== "0"
      ) || [];

    console.log("Filtered products:", products);

    // Calculate the difference between each product's date and today's date
    const today = new Date();
    const productsWithDateDifference = products.map((product) => {
      // Extract date value from Firestore timestamp object
      const productDate = new Date(
        product.lastUpdated._seconds * 1000 +
          product.lastUpdated._nanoseconds / 1000000
      );

      // console.log("Product lastUpdated:", product.lastUpdated);

      // Check if productDate is a valid date
      if (isNaN(productDate.getTime())) {
        // Handle invalid date
        console.error(`Invalid date for product: ${product.id}`);
        return { ...product, dateDifference: NaN };
      }

      const dateDifference = Math.floor(
        (today - productDate) / (1000 * 60 * 60 * 24)
      ); // Difference in days
      return { ...product, dateDifference };
    });

    // Sort products with dateDifference in ascending order (oldest to newest)
    productsWithDateDifference.sort(
      (a, b) => a.dateDifference - b.dateDifference
    );

    // Limit to the latest 4 products
    return productsWithDateDifference.slice(0, 8);
  });

  const featuredProducts = useSelector((state) => {
    const products =
      state.dynamicData.data?.Products.filter(
        (product) => product.inventory?.data !== "0"
      ) || [];

    // Calculate date differences for all products
    const today = new Date();
    const productsWithDateDifference = products.map((product) => {
      const productDate = new Date(
        product.lastUpdated._seconds * 1000 +
          product.lastUpdated._nanoseconds / 1000000
      );
      if (isNaN(productDate.getTime())) {
        console.error(`Invalid date for product: ${product.id}`);
        return { ...product, dateDifference: NaN };
      }
      const dateDifference = Math.floor(
        (today - productDate) / (1000 * 60 * 60 * 24)
      );
      return { ...product, dateDifference };
    });

    // Sort by date difference (oldest to newest)
    productsWithDateDifference.sort(
      (a, b) => a.dateDifference - b.dateDifference
    );

    // Filter for featured products within the sorted list
    const filteredFeaturedProducts = productsWithDateDifference.filter(
      (product) =>
        product.featured &&
        product.featured.data &&
        ["true", "yes"].includes(product.featured.data.toLowerCase())
    );

    // Return the first 3 latest featured products
    return filteredFeaturedProducts.slice(0, 3);
  });

  useEffect(() => {
    // Function to select a random collection
    const getRandomCollection = () => {
      // Calculate total weight of all collections
      const totalWeight = collections.reduce(
        (acc, collection) => acc + collection.weight,
        0
      );

      // Generate a random number between 0 and the total weight
      const randomWeight = Math.random() * totalWeight;

      // Loop through collections and find the one with a cumulative weight that exceeds the random weight
      let cumulativeWeight = 0;
      for (const collection of collections) {
        cumulativeWeight += collection.weight;
        if (randomWeight < cumulativeWeight) {
          return collection;
        }
      }
    };

    // Set the initial random collection when the component mounts
    setCurrentCollection(getRandomCollection());

    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []); // Empty dependency array to mimic componentDidMount

  return (
    <span>
      <SEO
        title="L'SAE Studio"
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="homepage"
        imageUrl="https://lsaestudio.com/images/L_SAE-Logo-033x-100-p-1600.jpg"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/lsae.webflow.css);

          @media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"6516a443-e082-ba54-bf5e-2ac8d24e68cd\"] {height:0px;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"6516a443-e082-ba54-bf5e-2ac8d24e68cd\"] {height:0px;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"6516a443-e082-ba54-bf5e-2ac8d24e68cd\"] {height:0px;}}


          body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
          }
          select, input, textarea { 
            -webkit-appearance: none;
          }
        `,
        }}
      />
      {latestProducts.length !== 0 ? (
        <>
          <span className="af-view">
            <div>
              <Announcement />
              <Navigation />
              <form
                onSubmit={handleSubmit} // Attach the onSubmit handler
                id="w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf40d-f6abf40a"
                className="af-class-nav-search-form af-class-nav-search-mobile af-class-search-body w-form"
              >
                <img alt="" src="/images/icon-search.svg" loading="eager" />
                <input
                  type="search"
                  className="af-class-search-form-input w-input"
                  maxLength={256}
                  name="query"
                  placeholder="Search here"
                  id="search-2"
                  required
                  value={searchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
                <input
                  type="submit"
                  className="af-class-hide-button w-button"
                  value={searchQuery} // Bind the input value to the state variable
                  onChange={(e) => setSearchQuery(e.target.value)} // Update the state on input change
                />
              </form>
              <div className="af-class-bg-primary">
                <div className="af-class-grid-halves-section">
                  <AsyncImage
                    className="af-class-grid-halves-image"
                    src={currentCollection.image}
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: 1 / 1,
                    }}
                    loader={<div style={{ background: "#EBEBEB" }} />}
                    error={<div style={{ background: "#eee" }} />}
                  />
                  <div className="af-class-section">
                    <div className="af-class-main-container">
                      <div className="af-class-container af-class-align-center">
                        <h1>{currentCollection.title}</h1>
                        <div className="af-class-large-text">
                          {currentCollection.description}
                        </div>
                        <div className="af-class-button-row af-class-justify-content-center">
                          <a
                            href={currentCollection.link}
                            className="af-class-button af-class-bg-white-faded w-inline-block"
                          >
                            <div>{currentCollection.action}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Marquee /> */}
              <div className="af-class-section">
                <div className="af-class-main-container">
                  <div className="w-layout-grid af-class-featured-products-grid">
                    <div className="af-class-featured-items-title">
                      <h3 className="af-class-large-heading af-class-featured-items-heading">
                        Featured
                        <br />
                        Items
                      </h3>
                      <div className="af-class-button-row af-class-featured-items-button-row">
                        <a
                          href="/shop"
                          className="af-class-button w-inline-block"
                        >
                          <div>Shop All</div>
                        </a>
                      </div>
                    </div>
                    <div className="w-dyn-list">
                      <div
                        role="list"
                        className="af-class-product-thirds-grid af-class-featured-products w-dyn-items"
                      >
                        {featuredProducts &&
                          featuredProducts?.map((data, idx) => (
                            <div
                              key={idx}
                              role="listitem"
                              className="w-dyn-item"
                            >
                              <div>
                                <Link
                                  onClick={handleScroll}
                                  to={`/product/${data.slug?.data.toLowerCase()}`}
                                >
                                  <AsyncImage
                                    src={data.thumbnail?.data.url}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      aspectRatio: 0.73 / 1,
                                    }}
                                    loader={
                                      <div style={{ background: "#EBEBEB" }} />
                                    }
                                    error={
                                      <div style={{ background: "#eee" }} />
                                    }
                                    className="af-class-product-thumbnail"
                                  />

                                  <div className="af-class-uppercase-text af-class-brand">
                                    {data.category?.data}
                                  </div>
                                  <h5 className="af-class-no-bottom-margin">
                                    {data.name?.data}
                                  </h5>

                                  <div className="af-class-price-container">
                                    <div>
                                      {basketCounts[data.id] > 1 && (
                                        <div
                                          className="basket-count"
                                          style={{ color: "#d00739" }}
                                        >
                                          <span className="breathing-dot"></span>
                                          {basketCounts[data.id] > 5
                                            ? "5+ people"
                                            : basketCounts[data.id] +
                                              " people"}{" "}
                                          have this in their cart
                                        </div>
                                      )}
                                      {data.inventory?.data <= 9 &&
                                        data.inventory?.data >= 2 && (
                                          <div className="basket-count">
                                            {/* <span className="breathing-dot"></span> */}
                                            Only {data.inventory?.data} left —
                                            order soon
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      {formatCurrency(data.price?.data)}
                                      &nbsp;&nbsp;
                                    </div>
                                    {data.oldPrice?.data ? (
                                      <div className="af-class-old-price">
                                        {" "}
                                        {formatCurrency(data.oldPrice.data)}
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                  <div />
                                  <div />
                                  {/* </a> */}
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section">
                <div className="af-class-main-container">
                  <h3 className="af-class-section-title">Just Arrived</h3>
                  <div className="w-dyn-list">
                    <div
                      role="list"
                      className="af-class-product-quarters-grid w-dyn-items"
                    >
                      {latestProducts &&
                        latestProducts?.map((data, idx) => (
                          <div key={idx} role="listitem" className="w-dyn-item">
                            <div>
                              <Link
                                onClick={handleScroll}
                                to={`/product/${data.slug?.data.toLowerCase()}`}
                                className="af-class-product-preview-link w-inline-block"
                              >
                                <div className="af-class-product-thumbnail-container">
                                  {data.inventory?.data === "0" ? (
                                    <div
                                      className="af-class-product-thumbnail af-class-black-and-white"
                                      style={{
                                        filter: "grayscale(80%)",
                                        position: "relative", // Make the container position relative
                                      }}
                                    >
                                      <AsyncImage
                                        src={data.thumbnail?.data.url}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          aspectRatio: 0.73 / 1,
                                        }}
                                        loader={
                                          <div
                                            style={{ background: "#EBEBEB" }}
                                          />
                                        }
                                        error={
                                          <div style={{ background: "#eee" }} />
                                        }
                                        className="af-class-product-thumbnail"
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          color: "#fff",
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        SOLD
                                      </div>
                                    </div>
                                  ) : (
                                    <AsyncImage
                                      src={data.thumbnail?.data.url}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        aspectRatio: 0.73 / 1,
                                      }}
                                      loader={
                                        <div
                                          style={{ background: "#EBEBEB" }}
                                        />
                                      }
                                      error={
                                        <div style={{ background: "#eee" }} />
                                      }
                                      className="af-class-product-thumbnail"
                                    />
                                  )}
                                </div>
                                <div className="af-class-uppercase-text af-class-brand">
                                  {data.category?.data}
                                </div>
                                <h5 className="af-class-no-bottom-margin">
                                  {data.name?.data}
                                </h5>
                                <div className="af-class-price-container">
                                  <div>
                                    {basketCounts[data.id] > 1 && (
                                      <div
                                        className="basket-count"
                                        style={{ color: "#d00739" }}
                                      >
                                        <span className="breathing-dot"></span>
                                        {basketCounts[data.id] > 5
                                          ? "5+ people"
                                          : basketCounts[data.id] +
                                            " people"}{" "}
                                        have this in their cart
                                      </div>
                                    )}
                                    {data.inventory?.data <= 9 &&
                                      data.inventory?.data >= 2 && (
                                        <div className="basket-count">
                                          {/* <span className="breathing-dot"></span> */}
                                          Only {data.inventory?.data} left —
                                          order soon
                                        </div>
                                      )}
                                  </div>
                                  <div>
                                    {formatCurrency(data.price?.data)}
                                    &nbsp;&nbsp;
                                  </div>
                                  {data.oldPrice?.data ? (
                                    <div className="af-class-old-price">
                                      {" "}
                                      {formatCurrency(data.oldPrice.data)}
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-grid af-class-home-square-image-left-grid">
                <AsyncImage
                  src="/images/DSC08599.jpg"
                  style={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: 1 / 1,
                  }}
                  loader={<div style={{ background: "#EBEBEB" }} />}
                  error={<div style={{ background: "#eee" }} />}
                />
                <div className="af-class-section-beside-image af-class-bg-primary">
                  <div className="af-class-container af-class-justify-content-between">
                    <h3>Custom Pieces, just for you!</h3>
                    <div>
                      <div>
                        Our dedicated team of talented designers is here to
                        transform your vision into a living masterpiece. From
                        concept to completion, we'll guide you through every
                        step of the design process, ensuring your space is a
                        true reflection of your unique style and aspirations.
                      </div>
                      <div className="af-class-button-row">
                        <a
                          href="/contact"
                          className="af-class-button af-class-bg-white-faded w-inline-block"
                        >
                          <div>Get In Touch</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </span>
        </>
      ) : (
        <Loading />
      )}
    </span>
  );
};

export default IndexView;
