//
//  state/reducers/cartSlice.js
//  LSAE
//
//  Created by Marcel on 2023-09-14.
//  Copyright © 2023 Marcel. All rights reserved.
//

import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
// import { useDispatch } from "react-redux";

// inside removeItem
// const dispatch = useDispatch();

const generateUUID = () => {
  return "basket-" + uuidv4();
};

// const backendBaseUrl = "http://127.0.0.1:5001/lsae-86346/us-central1/cart";
const backendBaseUrl = "https://us-central1-lsae-86346.cloudfunctions.net/cart";

// Function to throttle function calls
const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    isCartOpen: false,
    basketId: null,
    error: null, // To store API request error
  },
  reducers: {
    addToCart: throttle(async (state, action) => {
      const item = state.products.find((item) => item.id === action.payload.id);

      if (item) {
        item.quantity = action.payload.quantity; // Update the quantity directly
        if (item.quantity === 0) {
          // Handle removing the item
          state.products = state.products.filter(
            (item) => item.id !== action.payload.id
          );
          if (state.products.length === 0) {
            state.basketId = null;
          }
        }
      } else {
        state.products.push(action.payload);

        // Generate basketId if it's not already set (first item added)
        if (!state.basketId) {
          state.basketId = generateUUID();
          console.log("basket id:", state.basketId);
        }
      }

      // Set basketId for existing products if it's null
      state.products.forEach((product) => {
        if (!product.basketId) {
          product.basketId = state.basketId;
        }
      });

      // Send the request without await to avoid affecting the program flow
      try {
        await axios.post(`${backendBaseUrl}/add`, {
          products: state.products,
          basketId: state.basketId,
          timestamp: new Date().toISOString() 
        });
      } catch (error) {
        console.error("Error adding to database:", error);
        state.error = error.message; // Store error message in state
      }
    }, 1000), // Throttle to 1 request per second
    generateBasketId: (state) => {
      state.basketId = generateUUID();
      console.log("basket id:", state.basketId);
    },
    removeItem: (state, action) => {
      const productIdToRemove = action.payload;

      // Find the product in the cart (before filtering)
      // const productToRemove = state.products.find(item => item.id === productIdToRemove);

      // Filter out the product to remove
      state.products = state.products.filter(
        (item) => item.id !== productIdToRemove
      );

      // Only send a request to the backend if there are still items in the cart
      if (state.products.length > 0) {
        axios
          .post(`${backendBaseUrl}/remove`, {
            productId: productIdToRemove, // Send the product ID
            basketId: state.basketId,
          })
          .catch((error) => {
            console.error("Error removing item from cart:", error);
          });
      } else {
        // If the cart is empty, just log that the basket is destroyed and don't send a request
        // dispatch(resetCart());
        // 1. Save the basketId before resetting state
        const basketIdToDelete = state.basketId;

        // 2. Reset state
        state.products = [];
        state.basketId = null;
        console.log("destroyed basket.");

        // 3. Send delete request to backend ONLY if basketIdToDelete exists
        if (basketIdToDelete) {
          axios
            .delete(`${backendBaseUrl}/cart/${basketIdToDelete}`) // Use DELETE request
            .catch((error) => {
              console.error("Error resetting cart:", error);
              // Consider handling the error (e.g., display a message to the user)
            });
        }
        console.log("destroyed basket.");
      }
    },

    resetCart: (state) => {
      // 1. Save the basketId before resetting state
      const basketIdToDelete = state.basketId;

      // 2. Reset state
      state.products = [];
      state.basketId = null;
      console.log("destroyed basket.");

      // 3. Send delete request to backend ONLY if basketIdToDelete exists
      if (basketIdToDelete) {
        axios
          .delete(`${backendBaseUrl}/cart/${basketIdToDelete}`) // Use DELETE request
          .catch((error) => {
            console.error("Error resetting cart:", error);
            // Consider handling the error (e.g., display a message to the user)
          });
      }
    },

    openCart: (state) => {
      state.isCartOpen = true;
    },
    closeCart: (state) => {
      state.isCartOpen = false;
    },
  },
});

export const {
  addToCart,
  generateBasketId,
  removeItem,
  resetCart,
  openCart,
  closeCart,
} = cartSlice.actions;

export default cartSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";

// const generateUUID = () => {
//   return "basket-" + uuidv4();
// };

// const backendBaseUrl = "http://127.0.0.1:5001/lsae-86346/us-central1/cart";

// export const cartSlice = createSlice({
//   name: "cart",
//   initialState: {
//     products: [],
//     isCartOpen: false,
//     basketId: null,
//   },
//   reducers: {
//     addToCart: async (state, action) => {
//       const item = state.products.find((item) => item.id === action.payload.id);

//       if (item) {
//         if (item.quantity !== undefined) {
//           item.quantity += 1; // Increment quantity if item already exists
//         } else {
//           item.quantity = 1;
//         }
//       } else {
//         state.products.push(action.payload);
//         if (!state.basketId) {
//           state.basketId = generateUUID();
//           console.log("basket id:", state.basketId);
//         }
//       }

//       state.products.forEach((product) => {
//         if (!product.basketId) {
//           product.basketId = state.basketId;
//         }
//       });

//       // Call backend API to update cart in Firestore
//       try {
//         await axios.post(`${backendBaseUrl}/add`, {
//           products: state.products,
//           basketId: state.basketId,
//         });
//       } catch (error) {
//         console.error("Error adding to cart:", error);
//       }
//     },
//     removeItem: async (state, action) => {
//       state.products = state.products.filter(
//         (item) => item.id !== action.payload
//       );

//       if (state.products.length === 0) {
//         state.basketId = null;
//         console.log("destroyed basket.");
//       }

//       // Call backend API to update cart in Firestore
//       try {
//         await axios.post(`${backendBaseUrl}/remove`, {
//           products: state.products,
//           basketId: state.basketId,
//         });
//       } catch (error) {
//         console.error("Error removing item from cart:", error);
//       }
//     },
//     resetCart: (state) => {
//       state.products = [];
//       // Reset the basketId when the cart becomes empty
//       state.basketId = null;
//       console.log("destroyed basket.");
//     },
//     openCart: (state) => {
//       state.isCartOpen = true;
//     },
//     closeCart: (state) => {
//       state.isCartOpen = false;
//     },
//   },
// });

// export const {
//   addToCart,
//   generateBasketId,
//   removeItem,
//   resetCart,
//   openCart,
//   closeCart,
// } = cartSlice.actions;

// export default cartSlice.reducer;
