//
//  hooks/UseDynamicData.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicData } from "../state/reducers/dynamicDataSlice";
import deepDiff from "deep-diff";
import { resetCart } from "../state/reducers/cartSlice"; // Import resetCart action

// TODO:
// - [ ] update slug from name

export const useDynamicData = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const dynamicData = useSelector((state) => state.dynamicData.data);

  // function to generate a slug from the name
  const generateSlug = (name) => {
    return encodeURIComponent(name.toLowerCase().replace(/\s+/g, "-"));
  };

  const fetchDynamicData = async () => {
    try {
      console.log("Fetching dynamic data. bekle...");

      const response = await fetch(
        "https://us-central1-lsae-86346.cloudfunctions.net/fetchFirestore",
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok :/");
      }

      const remoteData = await response.json();
      const transformedRemoteData = remoteData.reduce((acc, obj) => {
        acc[obj.collection] = (acc[obj.collection] || []).concat(obj.items);
        return acc;
      }, {});

      const differences = deepDiff(dynamicData, transformedRemoteData);

      if (differences) {
        console.log("Differences found. Logging the diff:");
        console.log(differences);

        console.log("Updating local store...");

        // Flush the cart if differences are found
        // dispatch(resetCart());
        // console.log("Cart flushed.");

        dispatch(setDynamicData(transformedRemoteData));
      } else {
        console.log("Local copy is up to date. :)");
      }

      setLoading(false);
      setHasFetchedData(true);
      console.log("Dynamic data fetching complete.");
      console.log(dynamicData);
    } catch (error) {
      console.error("Error fetching dynamic data:", error);
      setError(
        "An error occurred while fetching data. Please try again later."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasFetchedData) {
      console.log("hasFetchedData?");
      fetchDynamicData()
        .then(() => setHasFetchedData(true))
        .catch((error) => {
          console.error("Error fetching dynamic data:", error);
          setError(
            "An error occurred while fetching data. Please try again later."
          );
          setLoading(false);
        });
    }
  }, [hasFetchedData]);

  return { loading, error };
};
