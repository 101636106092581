//
//  components/Cart.js
//  LSAE
//
//  Created by Marcel on 2023-09-14.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { addToCart, closeCart, removeItem } from "../state/reducers/cartSlice";
import { formatCurrency } from "../localisation/locale";

import "react-lazy-load-image-component/src/effects/opacity.css";

// TODO:
// - [x] close cart

const Cart = () => {
  const dispatch = useDispatch();
  const Products = useSelector((state) => state.cart.products);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen); // Get cart open/close state

  const handleQuantityChange = (event, productId) => {
    const newQuantity = parseInt(event.target.value, 10);

    if (newQuantity >= 1) {
      const updatedProduct = Products.find((item) => item.id === productId);
      if (updatedProduct) {
        dispatch(addToCart({ ...updatedProduct, quantity: newQuantity }));
      }
    }
  };

  useEffect(() => {
    // Add the CSS class to disable scrolling when the cart is open
    if (isCartOpen) {
      document.body.classList.add("disable-scroll");
    }
  }, [isCartOpen]);

  const handleCloseCart = () => {
    // Remove the CSS class to enable scrolling when the cart is closed
    document.body.classList.remove("disable-scroll");
    dispatch(closeCart());
  };

  const Subtotal = () => {
    let total = 0;
    Products.forEach((item) => {
      total += item.price * item.quantity; // Multiply by quantity
    });
    return total; // Format to two decimal places
    // return total.toFixed(2); // Format to two decimal places
  };

  return (
    <span>
      <div
        data-node-type="commerce-cart-container-wrapper"
        className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-rightSidebar"
      >
        <div
          data-node-type="commerce-cart-container"
          role="dialog"
          className="w-commerce-commercecartcontainer af-class-cart-container"
        >
          <div className="w-commerce-commercecartheader af-class-cart-header">
            <h4 className="w-commerce-commercecartheading">Your Cart</h4>
            <a
              onClick={handleCloseCart}
              href="#"
              data-node-type="commerce-cart-close-link"
              className="w-commerce-commercecartcloselink af-class-close-cart-button w-inline-block"
              role="button"
              aria-label="Close cart"
            >
              <img alt="" src="/images/icon-cross-large.svg" loading="lazy" />
            </a>
          </div>

          <div className="w-commerce-commercecartformwrapper">
            <div>
              {Products.length === 0 ? (
                <div className="w-commerce-commercecartemptystate">
                  No items found.
                </div>
              ) : (
                // Render cart items here
                <form
                  data-node-type="commerce-cart-form"
                  className="w-commerce-commercecartform"
                >
                  <div
                    className="w-commerce-commercecartlist af-class-cart-list"
                    data-wf-collection="database.commerceOrder.userItems"
                    data-wf-template-id="wf-template-dab1a8fd-38ef-ff9e-e737-a0bdf6abf424"
                  >
                    {Products &&
                      Products?.map((data, idx) => (
                        <div
                          key={idx}
                          className="w-commerce-commercecartitem cart-item"
                        >
                          <Link
                            onClick={handleCloseCart}
                            to={`/product/${data.slug}`}
                          >
                            <LazyLoadImage
                              src={data.img}
                              effect="opacity"
                              className="w-commerce-commercecartitemimage cart-item-image"
                            />
                          </Link>
                          <div className="w-commerce-commercecartiteminfo cart-item-details">
                            <div
                              data-wf-bindings=""
                              className="w-commerce-commercecartproductname"
                            >
                              <Link
                                onClick={handleCloseCart}
                                to={`/product/${data.slug}`}
                              >
                                {data.title}
                              </Link>
                            </div>
                            <div
                              data-wf-bindings=""
                              className="fine-print-text"
                            >
                              {formatCurrency(data.price)}&nbsp;
                            </div>
                            <a
                              onClick={() => dispatch(removeItem(data.id))}
                              href="#"
                              role=""
                              data-wf-bindings=""
                              className="w-inline-block"
                              data-wf-cart-action="remove-item"
                              data-commerce-sku-id="64fa27262323abc6734c791a"
                              aria-label="Remove item from cart"
                            >
                              <div
                                onClick={() => dispatch(removeItem(data.id))}
                                className="fine-print-text remove-link"
                              >
                                Remove
                              </div>
                            </a>
                          </div>
                          <input
                            type="number"
                            className="w-commerce-commercecartquantity"
                            required
                            pattern="^[0-9]+$"
                            inputmode="numeric"
                            name="quantity"
                            autocomplete="off"
                            defaultValue={data.quantity}
                            min="0"
                            onChange={(e) => handleQuantityChange(e, data.id)}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="w-commerce-commercecartfooter af-class-cart-footer">
                    <div
                      aria-live
                      aria-atomic="false"
                      className="w-commerce-commercecartlineitem"
                    >
                      <div>Subtotal</div>
                      <div className="w-commerce-commercecartordervalue">
                        {formatCurrency(Subtotal())}
                      </div>
                    </div>
                    <div>
                      <a
                        onClick={handleCloseCart}
                        href="/checkout"
                        value="Continue to Checkout"
                        data-node-type="cart-checkout-button"
                        className="w-commerce-commercecartcheckoutbutton af-class-button af-class-bg-primary-dark"
                        data-loading-text="Hang Tight..."
                      >
                        Continue to Checkout
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </div>

            <div
              aria-live
              style={{ display: "none" }}
              data-node-type="commerce-cart-error"
              className="w-commerce-commercecarterrorstate"
            >
              <div
                className="w-cart-error-msg"
                data-w-cart-quantity-error="Product is not available in this quantity."
                data-w-cart-general-error="Something went wrong when adding this item to the cart."
                data-w-cart-checkout-error="Checkout is disabled on this site."
                data-w-cart-cart_order_min-error="The order minimum was not met. Add more items to your cart to continue."
                data-w-cart-subscription_error-error="Before you purchase, please use your email invite to verify your address so we can send order updates."
              >
                Product is not available in this quantity.
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default Cart;
