//
//  views/DetailCategoryView.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//

import React, { useEffect, useState } from "react";
import { Navigation } from "../components/Navigation";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { formatCurrency } from "../localisation/locale";

import Footer from "../components/Footer";
import Announcement from "../components/Announcement";
import "react-lazy-load-image-component/src/effects/blur.css";
import SEO from "../components/SEO";
import Loading from "../components/Loading";
import { AsyncImage } from "loadable-image";

const scripts = [
  {
    src: "/js/jquery-3.5.1.min.dc5e7f18c8.js",
    async: true,
  },
  {
    src: "/js/webflow.js",
    async: false,
  },
];

const loadScript = (src, async) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
};

const loadScriptsSequentially = async (scripts) => {
  for (let i = 0; i < scripts.length; i++) {
    await loadScript(scripts[i].src, scripts[i].async);
  }
};

const categoryToLineMap = {
  hooks: "Misnar",
  lights: "Batu-Suvaie",
  furniture: "Batu-Suvaie",
  paintings: "Arali",
  stoppers: " ",
};

const DetailCategoryView = () => {
  const dynamicData = useSelector((state) => state.dynamicData.data || {});
  const { Categories, Products } = dynamicData;
  const { id } = useParams(); // Get the ID from the URL

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const currentCategory = Products
    ? Products.filter(
        (product) => product.category?.data.toLowerCase() === id.toLowerCase()
      )
    : [];

  // Sort currentCategory to move items with inventory 0 to the end
  currentCategory.sort((a, b) => (a.inventory?.data === "0" ? 1 : -1));

  const lineData = categoryToLineMap[id] || "N/A"; // Update lineData based on the category

  const isCategoryActive = (categorySlug) => {
    return categorySlug.toLowerCase() === id.toLowerCase();
  };
  const [basketCounts, setBasketCounts] = useState({});

  useEffect(() => {
    const fetchBasketData = async () => {
      const storedData = localStorage.getItem('basketCounts');
      const storedTimestamp = localStorage.getItem('basketCountsTimestamp');

      if (
        storedData &&
        storedTimestamp &&
        Date.now() - storedTimestamp < 6 * 60 * 60 * 1000 // 6 hours in milliseconds
      ) {
        console.log("Using cached basket counts...");
        setBasketCounts(JSON.parse(storedData));
      } else {
        try {
          console.log("Fetching basket counts...");
          const res = await fetch("https://us-central1-lsae-86346.cloudfunctions.net/cart/counts");
          const data = await res.json();
          setBasketCounts(data);
          localStorage.setItem('basketCounts', JSON.stringify(data));
          localStorage.setItem('basketCountsTimestamp', Date.now());
        } catch (error) {
          console.error('Error fetching basket counts:', error);
          // Handle error (e.g., show a user-friendly message)
        }
      }
    };

    fetchBasketData();


    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "64fa27262323abc6734c7867";
    htmlEl.dataset["wfSite"] = "64f0f6375a104df9440ed508";

    
        loadScriptsSequentially(scripts)
      .then(() => {
        console.log("All scripts loaded successfully");
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, []);

  return (
    <>
      <SEO
        title={`L'SAE Studio | ${id.charAt(0).toUpperCase() + id.slice(1)}`}
        description="Our dedicated team of talented designers is here to transform your vision into a living masterpiece. From concept to completion, we'll guide you through every step of the design process, ensuring your space is a true reflection of your unique style and aspirations."
        name="L'SAE Studio"
        type="shop"
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
            @import url(/css/normalize.css);
            @import url(/css/webflow.css);
            @import url(/css/lsae.webflow.css);

            body {
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
            }
            select, input, textarea { 
              -webkit-appearance: none;
            }
          `,
        }}
      />
      <div className="af-view">
        {currentCategory.length != 0 ? (
          <>
            <Announcement />
            <Navigation />
            <div
              className="af-class-section af-class-shop-title"
              style={{ paddingBottom: "0px" }}
            >
              <div className="af-class-main-container">
                <h1
                  className="af-class-medium-heading af-class-no-bottom-margin"
                  style={{ textTransform: "capitalize" }}
                >
                  {id}
                </h1>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-main-container">
                <div className="w-layout-grid af-class-sidebar-layout-grid">
                  <div className="af-class-sidebar">
                    <div>
                      <h4>By Category</h4>
                      <div className="af-class-links-grid">
                        <a href="/shop" className="af-class-list-link">
                          All
                        </a>
                        <div className="w-dyn-list">
                          <div
                            role="list"
                            className="af-class-links-grid w-dyn-items"
                          >
                            {Categories &&
                              Categories?.map((data, idx) => (
                                <div
                                  key={idx}
                                  role="listitem"
                                  className="w-dyn-item"
                                >
                                  <Link
                                    onClick={handleScroll}
                                    to={`/category/${data.slug?.data.toLowerCase()}`}
                                    className="af-class-list-link"
                                    style={{
                                      fontWeight: isCategoryActive(
                                        data.slug?.data.toLowerCase()
                                      )
                                        ? "700"
                                        : "500",
                                    }}
                                  >
                                    {data.name?.data}
                                  </Link>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-dyn-list">
                    <div
                      role="list"
                      className="af-class-product-thirds-grid w-dyn-items"
                    >
                      {currentCategory &&
                        currentCategory?.map((data, idx) => (
                          <div key={idx} role="listitem" className="w-dyn-item">
                            <div>
                              <Link
                                onClick={handleScroll}
                                to={`/product/${data.slug?.data.toLowerCase()}`}
                                className="af-class-product-preview-link w-inline-block"
                              >
                                <div className="af-class-product-thumbnail-container">
                                  {data.inventory?.data === "0" ? (
                                    <div
                                      className="af-class-product-thumbnail af-class-black-and-white"
                                      style={{
                                        filter: "grayscale(90%)",
                                        position: "relative", // Make the container position relative
                                      }}
                                    >
                                      <AsyncImage
                                        src={data.thumbnail?.data.url}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          aspectRatio: 0.73 / 1,
                                        }}
                                        loader={
                                          <div
                                            style={{ background: "#EBEBEB" }}
                                          />
                                        }
                                        error={
                                          <div style={{ background: "#eee" }} />
                                        }
                                        className="af-class-product-thumbnail"
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                                          color: "#fff",
                                          padding: "5px 10px",
                                          borderRadius: "5px",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        SOLD
                                      </div>
                                    </div>
                                  ) : (
                                    <AsyncImage
                                      src={data.thumbnail?.data.url}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        aspectRatio: 0.73 / 1,
                                      }}
                                      loader={
                                        <div
                                          style={{ background: "#EBEBEB" }}
                                        />
                                      }
                                      error={
                                        <div style={{ background: "#eee" }} />
                                      }
                                      className="af-class-product-thumbnail"
                                    />
                                  )}
                                </div>
                                <div className="af-class-uppercase-text af-class-brand">
                                  {data.line?.data &&
                                  data.line?.data.trim().length > 0
                                    ? data.line?.data
                                    : lineData}
                                </div>
                                <h5 className="af-class-no-bottom-margin">
                                  {data.name?.data}
                                </h5>
                                <div className="af-class-price-container">
                                    <div>
                                      {basketCounts[data.id] > 1 && (
                                        <div
                                          className="basket-count"
                                          style={{ color: "#d00739" }}
                                        >
                                          <span className="breathing-dot"></span>
                                          {basketCounts[data.id] > 5
                                            ? "5+ people"
                                            : basketCounts[data.id] +
                                              " people"}{" "}
                                          have this in their cart
                                        </div>
                                      )}
                                      {data.inventory?.data <= 9  && data.inventory?.data >= 2 && (
                                        <div className="basket-count">
                                          {/* <span className="breathing-dot"></span> */}
                                          Only {data.inventory?.data} left —
                                          order soon
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      {formatCurrency(data.price?.data)}
                                      &nbsp;&nbsp;
                                    </div>
                                    {data.oldPrice?.data ? (
                                      <div className="af-class-old-price">
                                        {" "}
                                        {formatCurrency(data.oldPrice.data)}
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default DetailCategoryView;
