//
//  components/Announcement.js
//  LSAE
//
//  Created by Marcel on 2023-09-08.
//  Copyright © 2023 Marcel. All rights reserved.
//


import React from 'react';

const display = "none";

const Announcement = () => {
  return (
    <div style={{ display: display }}>
      <div className="af-class-announcement-bar" >
        <div className="af-class-main-container">
          <div className="af-class-announcement-contents">
            <div className="af-class-announcement-text">Hi, this is an announcement! :) <a href="#" target='_blank' rel="noreferrer">Click me!</a>
            </div>
          </div>
        </div><img alt="" src="/images/icon-cross.svg" loading="lazy" className="af-class-announcement-cross" />
      </div>
    </div>
  );

};
export default Announcement;